import buildQuery from 'common/helpers/buildQuery';
import axios from 'public/helpers/MyAxios';
import Config from 'public/constants/Config';

const service = {
  fetchList: (search) => {
    const qs = buildQuery(search);
    return axios.get(`${Config.baseUrl}/staff/online-surveys?${qs}`);
  },
  fetchOne: (id, expand = '') => axios.get(`${Config.baseUrl}/staff/online-surveys/${id}${expand}`),
  create: data => axios.post(`${Config.baseUrl}/staff/online-surveys`, data),
  batchCreate: data => axios.post(`${Config.baseUrl}/staff/online-survey/batch-create`, data),
  update: (data, expand = '') => axios.put(`${Config.baseUrl}/staff/online-surveys/${data.id}${expand}`, data),
  deleteOne: id => axios.delete(`${Config.baseUrl}/staff/online-surveys/${id}`),
};

export default service;
