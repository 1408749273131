import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const BackButton = ({
  to = '',
  replace = false,
  color = 'inherit',
}) => {
  const history = useHistory();
  const handleClick = React.useCallback(() => {
    history.goBack();
  }, [history]);

  if (to !== '') {
    return (<IconButton component={Link} to={to} replace={replace} color={color}><ArrowBackIcon /></IconButton>);
  }
  return (<IconButton onClick={handleClick} color={color}><ArrowBackIcon /></IconButton>);
}

export default BackButton;
