import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import TextField from 'common/components/form/TextField';

import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';

import ToolBar from 'common/components/ToolBar';
import {
  FormCard,
  FormCardSection,
  FormCardSectionFieldRow,
  FormCardContent,
} from 'common/components/FormCard';
import DatetimePicker from 'common/components/form/DatetimePicker';
import UserDepartmentDropdown from 'public/components/form/UserDepartmentDropdown';

// const parseCode = v => ((v && v.code) ? v.code : v);
const validate = (values) => {
  const error = {};
  if (!values.name) {
    error.name = 'Required';
  }
  if (!values.start) {
    error.start = 'Required';
  }
  if (!values.end) {
    error.end = 'Required';
  }
  if (values.start && values.end && !values.start.isBefore(values.end)) {
    error.end = 'End must before Start';
  }
  if (!values.department_code) {
    error.department_code = 'Required';
  }
  return error;
};

const randomString = (m = 6) => {
  const r = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let s = '';
  for (let i = 0; i < m; i += 1) {
    s += r.charAt(Math.floor(Math.random() * r.length));
  }
  return s;
};

class SurveyForm extends Component { // eslint-disable-line
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    change: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    selectedClasses: PropTypes.arrayOf(PropTypes.shape({})),
    selectedQuestionnaire: PropTypes.shape({}),
  }

  static defaultProps = {
    selectedClasses: [],
    selectedQuestionnaire: null,
  }

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(values) {
    console.log(values);
    const { onSubmit, selectedClasses, selectedQuestionnaire } = this.props;
    const resp = await onSubmit(selectedClasses.map(c => ({
      ...values,
      class_id: c.id,
      questionnaire_id: selectedQuestionnaire.id,
      start: values.start.format('YYYY-MM-DD HH:mm:00'),
      end: values.end.format('YYYY-MM-DD HH:mm:00'),
      // passcode: values.passcode,
      // remarks: values.remarks,
      // name: values.name,
    })));
    return resp;
  }

  render() {
    const {
      handleSubmit, pristine, submitting, change,
      selectedClasses, selectedQuestionnaire,
    } = this.props;
    const precondition = selectedClasses.length && selectedQuestionnaire;
    const onSubmit = handleSubmit(this.onSubmit);
    return (
      <div>
        <ToolBar
          title="Evaluation Period"
        />
        <form onSubmit={onSubmit}>
          <FormCard>
            <FormCardSection>
              <FormCardSectionFieldRow title="Activities">
                {
                  selectedClasses.length ?
                    selectedClasses.map(c => (<Typography key={c.id}>{c.name}</Typography>))
                    :
                    <Typography color="error">Please select Activities at Step 1.</Typography>
                }
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Questionnare">
                {
                  selectedQuestionnaire ?
                    <Typography>{selectedQuestionnaire.name}</Typography>
                    :
                    <Typography color="error">Please select Questionnare at Step 2.</Typography>
                }
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Name">
                <Field
                  name="name"
                  component={TextField}
                  fullWidth
                />
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Department">
                <Field
                  name="department_code"
                  component={UserDepartmentDropdown}
                  placeholder="Select..."
                  fullWidth
                />
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Start">
                <Field
                  name="start"
                  component={DatetimePicker}
                  disablePast
                />
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="End">
                <Field
                  name="end"
                  component={DatetimePicker}
                  disablePast
                />
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Passcode">
                <div>
                  <Field
                    name="passcode"
                    component={TextField}
                    disabled
                  />
                  <Button variant="contained" color="primary" onClick={() => change('passcode', randomString())}>Generate</Button>
                  <Button onClick={() => change('passcode', null)}>Remove</Button>
                </div>
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Overall Course Evaluation">
                <Field
                  name="overall_cte"
                  component={TextField}
                  select
                  SelectProps={{ native: true }}
                  parse={v => (v ? Number(v) : null)}
                >
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </Field>
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Remarks">
                <Field
                  name="remarks"
                  component={TextField}
                  multiline
                />
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Status">
                <Field
                  name="published"
                  component={TextField}
                  select
                  SelectProps={{ native: true }}
                  parse={v => (v ? Number(v) : null)}
                >
                  <option value="0">Draft</option>
                  <option value="1">Published</option>
                </Field>
              </FormCardSectionFieldRow>
            </FormCardSection>
            <CardActions>
              <FormCardContent>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!precondition || pristine || submitting}
                >
                  SAVE
                </Button>
              </FormCardContent>
            </CardActions>
          </FormCard>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'OnlineSurvey_SurveyForm',
  validate,
})(SurveyForm);
