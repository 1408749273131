import React, { Component } from 'react';
import PropTypes from 'prop-types';
import pickBy from 'lodash/pickBy';
import querystring from 'querystring';
import fileDownload from 'js-file-download';

import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import LoadingFileDialog from 'common/components/download/LoadingFileDialog';
import axios from 'public/helpers/MyAxios';
import ReportFilterDialog from './ReportFilterDialog';

const buildQuery = (search, sendEmpty = false) => {
  // remove empty array
  let data = pickBy(
    search,
    // filter empty array as querystring have strange behaviour
    value => (Array.isArray(value) ? Boolean(value.length) : true),
  );
  if (!sendEmpty) {
    // remove empty input to shorten the params
    data = pickBy(
      data,
      // filter empty array as querystring have strange behaviour
      value => Boolean(value),
    );
  }
  return querystring.stringify(data);
};

const styles = () => ({
  menuVertical: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
});

class ReportButtonMenu extends Component {
  static propTypes = {
    surveyId: PropTypes.number,
    classes: PropTypes.shape({}).isRequired,
    activity: PropTypes.shape({}),
  }
  static defaultProps = {
    surveyId: null,
    activity: {},
  }

  state = {
    progess: null,
    loading: false,
    notiMessage: null,
    showFilter: null,
  }

  downloadFile(path, filename) {
    this.setState(
      { loading: true, notiMessage: null, showFilter: null },
      async () => {
        try {
          const response = await axios.get(axios.addApiUrl(path), {
            responseType: 'blob',
            onDownloadProgress: e => this.setState({ progess: { loaded: e.loaded, total: e.total } }),
          });
          fileDownload(response.data, filename);
          this.setState({ loading: false, progess: null });
        } catch (e) {
          this.setState({ loading: false, progess: null, notiMessage: `Error: ${e.message}` });
        }
      },
    );
  }

  render() {
    const { surveyId, activity, classes } = this.props;
    const { loading, progess, notiMessage, showFilter } = this.state;
    return (
      <div>
        {notiMessage ? <Typography color="error">{notiMessage}</Typography> : null}
        <MenuList className={classes.menuVertical}>
          <MenuItem onClick={() => this.downloadFile(`/coordinator/online-survey/open-answers?id=${surveyId}`, `${surveyId}_Comments.pdf`)}>Written comments from students</MenuItem>
          <MenuItem onClick={() => this.downloadFile(`/coordinator/report/overall?id=${surveyId}`, `${surveyId}_Report.xlsx`)}>Overall Report</MenuItem>
          {/* <MenuItem
            onClick={() => this.setState({
              showFilter: {
                path: '/coordinator/report/overall',
                name: 'Report',
                title: 'Report use other specific reference group',
              },
            })}
          >
            Report use other specific reference group
          </MenuItem> */}
        </MenuList>
        <LoadingFileDialog open={loading} progress={progess} />
        <ReportFilterDialog
          open={Boolean(showFilter)}
          onClose={() => this.setState({ showFilter: null })}
          title={(showFilter ? showFilter.title : '')}
          activity={activity}
          onSubmit={async (values) => {
            const queryString = buildQuery({ id: surveyId, ...values });
            this.downloadFile(`${showFilter.path}?${queryString}`, `${surveyId}_Report.xlsx`);
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ReportButtonMenu);
