import { connect } from 'react-redux';
import actions from 'public/redux/Activities/actions';
// import studentActions from 'public/redux/Activities/studentActions';
import ActivityView from './ActivityView';

const mapStateToProps = ({ ActivityForm }) => ActivityForm;

const mapDispatchToProps = {
  fetchOne: actions.fetchOne,
  // fetchStudents: studentActions.searchFormSubmit,
  clearOne: actions.clearOne,
};

// connect with redux
export default connect(mapStateToProps, mapDispatchToProps)(ActivityView);
