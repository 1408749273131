import { connect } from 'react-redux';
import actions from 'public/redux/Activities/studentActions';
import StudentCreateForm from './StudentCreateForm';

const mapStateToProps = ({ ActivityForm }) => ActivityForm;
const mapDispatchToProps = {
  create: actions.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentCreateForm);
