import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TextField from 'common/components/form/TextField';


const mapStateToProps = ({ account }) => {
  if (account?.user?.staff?.staffCoordDepartments) {
    return { departments: account?.user?.staff?.staffCoordDepartments.map(v => v.department_code) };
  }
  return { departments: [] };
};

const CoordDepartmentDropdown = ({
  placeholder,
  SelectProps,
  allowEmpty,
  ...props
}) => {
  const { departments } = useSelector(mapStateToProps);
  return (
    <TextField
      select
      SelectProps={{
        ...SelectProps,
        native: true,
      }}
      {...props}
    >
      {placeholder ? (<option value="" disabled={!allowEmpty}>{placeholder}</option>) : null}
      {
        departments.map(code => (<option key={code} value={code}>{code}</option>))
      }
    </TextField>
  )
};
CoordDepartmentDropdown.propTypes = {
  SelectProps: PropTypes.shape({}),
  placeholder: PropTypes.string,
  allowEmpty: PropTypes.bool,
};
CoordDepartmentDropdown.defaultProps = {
  SelectProps: null,
  placeholder: null,
  allowEmpty: false,
};

export default CoordDepartmentDropdown;
