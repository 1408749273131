import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextField from 'common/components/form/TextField';

const UserDepartmentDropdown = ({
  departments,
  placeholder,
  SelectProps,
  allowEmpty,
  ...props
}) =>
(
  <TextField
    select
    SelectProps={{
      ...SelectProps,
      native: true,
      // displayEmpty: Boolean(placeholder),
    }}
    {...props}
  >
    {placeholder ? (<option value="" disabled={!allowEmpty}>{placeholder}</option>) : null}
    {
      departments.map(code => (<option key={code} value={code}>{code}</option>))
    }
  </TextField>
);
UserDepartmentDropdown.propTypes = {
  SelectProps: PropTypes.shape({}),
  placeholder: PropTypes.string,
  departments: PropTypes.arrayOf(PropTypes.string).isRequired,
  allowEmpty: PropTypes.bool,
};
UserDepartmentDropdown.defaultProps = {
  SelectProps: null,
  placeholder: null,
  allowEmpty: false,
};

const mapStateToProps = ({ account }) => {
  if (account.user && account.user.staff && account.user.staff.staffDepartments) {
    return { departments: account.user.staff.staffDepartments.map(v => v.department_code) };
  }
  return { departments: [] };
};
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserDepartmentDropdown);
