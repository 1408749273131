import buildQuery from 'common/helpers/buildQuery';
import axios from 'public/helpers/MyAxios';
import Config from 'public/constants/Config';

const service = {
  fetchList: (search) => {
    const qs = buildQuery(search);
    return axios.get(`${Config.baseUrl}/staff/online-survey/student-list?${qs}`);
  },
};

export default service;
