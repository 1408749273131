import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Routes from './Routes';

const styles = () => ({
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  main: {
    flex: '1 1 auto',
    display: 'flex',
  },
});

class MainApp extends Component { // eslint-disable-line
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.appFrame}>
        <main className={classes.main}>
          <Routes />
        </main >
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(MainApp));
