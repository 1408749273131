import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import TextField from 'common/components/form/TextField';
import { withStyles } from '@material-ui/core/styles';
import SearchFormBase from 'common/components/SearchFormBase';

const styles = theme => ({
  field: {
    marginRight: theme.spacing(2),
    maxWidth: 150,
    width: 150,
    minWidth: 100,
  },
  row: {
    flex: 3,
  },
});

const EMPTY_FORM = {
  id: '',
  overall_cte: '1',
  department_code: '',
  name: '',
  year: '',
  questionnaire_name: '',
  course_code: '',
  activity_name: '',
  published: '2',
};

class OnlineSurveySearchForm extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    onSubmit: PropTypes.func.isRequired,
    // redux form,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.clearForm = this.clearForm.bind(this);
  }

  async clearForm(event = {}) {
    if (event.preventDefault) {
      event.preventDefault();
    }
    const { onSubmit, reset } = this.props;
    await onSubmit(EMPTY_FORM);
    reset();
  }

  render() {
    const { classes, handleSubmit, onSubmit } = this.props;
    return (
      <SearchFormBase onSubmit={handleSubmit(onSubmit)} onClear={this.clearForm}>
        <div className={classes.row}>
          <Field
            name="id"
            className={classes.field}
            label="ID"
            component={TextField}
          />
          <Field
            name="name"
            className={classes.field}
            label="Name"
            component={TextField}
          />
          <Field
            name="activity_name"
            className={classes.field}
            label="Activity Name"
            component={TextField}
          />
          <Field
            name="course_code"
            className={classes.field}
            label="Course Code"
            component={TextField}
          />
          <Field
            name="year"
            className={classes.field}
            label="Academic Year"
            component={TextField}
          />
          <Field
            name="questionnaire_name"
            className={classes.field}
            label="Questionnaire Name"
            component={TextField}
          />
          <Field
            name="department_code"
            className={classes.field}
            label="Department Code"
            component={TextField}
          />
          <Field
            name="overall_cte"
            className={classes.field}
            label="Overall Course Evaluation"
            component={TextField}
            select
            SelectProps={{ native: true }}
          >
            <option value="" />
            <option value="1">Yes</option>
            <option value="0">No</option>
          </Field>
          <Field
            name="published"
            className={classes.field}
            label="Status"
            component={TextField}
            select
            SelectProps={{ native: true }}
          >
            <option value="" />
            <option value="0">Draft</option>
            <option value="1">Published</option>
            <option value="2">Completed</option>
            <option value="-1">Cancelled</option>
          </Field>
        </div>
      </SearchFormBase>
    );
  }
}


export default reduxForm({
  form: 'CoordinatorOnlineSurveySearchForm',
  enableReinitialize: true,
})(withStyles(styles)(OnlineSurveySearchForm));
