import buildQuery from 'common/helpers/buildQuery';
import axios from 'public/helpers/MyAxios';
import Config from 'public/constants/Config';

const service = {
  fetchList: (search) => {
    const qs = buildQuery(search);
    return axios.get(`${Config.baseUrl}/staff/questionnaires?${qs}`);
  },
  fetchOne: (id, expand = '') => axios.get(`${Config.baseUrl}/staff/questionnaires/${id}${expand}`),
  create: data => axios.post(`${Config.baseUrl}/staff/questionnaires`, data),
  copy: values => axios.post(`${Config.baseUrl}/staff/questionnaire/copy`, values),
  update: data => axios.put(`${Config.baseUrl}/staff/questionnaires/${data.id}`, data),
  deleteOne: id => axios.delete(`${Config.baseUrl}/staff/questionnaires/${id}`),
};

export default service;
