import buildQuery from 'common/helpers/buildQuery';
import axios from 'public/helpers/MyAxios';
import Config from 'public/constants/Config';

const service = {
  fetchList: (search) => {
    const qs = buildQuery(search);
    return axios.get(`${Config.baseUrl}/staff/activities?${qs}`);
  },
  fetchOne: id => axios.get(`${Config.baseUrl}/staff/activities/${id}?expand=teachers`),
  create: data => axios.post(`${Config.baseUrl}/staff/activities?expand=teachers`, data),
  copy: data => axios.post(`${Config.baseUrl}/staff/activity/copy?expand=teachers`, data),
  update: data => axios.put(`${Config.baseUrl}/staff/activities/${data.id}?expand=teachers`, data),
  deleteOne: id => axios.delete(`${Config.baseUrl}/staff/activities/${id}`),
};

export default service;
