import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Add from '@material-ui/icons/Add';

import QuestionSelectionDialog from 'public/components/Selection/QuestionSelectionDialog';
import ItemRow from './ItemRow';


const styles = theme => ({
  btnGroup: {
    textAlign: 'right',
    padding: theme.spacing(1),
  },
  btn: {
    marginRight: theme.spacing(1),
  },
  paperWidthMd: {
    maxWidth: 1200,
  },
});

const DEFAULT_T_ITEM = {
  type: 'T',
  seq: null,
  content: '',
  required: false,
};

const DEFAULT_Q_ITEM = {
  type: 'Q',
  seq: null,
  question_id: null,
  required: true,
};

class ItemList extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    fields: PropTypes.shape({
    }).isRequired,
    sectionNum: PropTypes.number.isRequired,
    canUpdate: PropTypes.bool.isRequired,
  }

  state = {
    dialogOpen: false,
  }

  renderItem = (path, index, items, questionNum, canUpdate) => (
    <ItemRow
      key={path}
      path={path}
      index={index}
      questionNum={questionNum}
      canUpdate={canUpdate}
      onMoveUp={() => {
        if (index > 0) {
          items.swap(index, index - 1);
        }
      }}
      onMoveDown={() => {
        if (index < (items.length - 1)) {
          items.swap(index, index + 1);
        }
      }}
      onRemove={() => items.remove(index)}
    />
  )


  renderItems = (items, sectionNum, canUpdate) => {
    const rendered = [];
    let questionNum = 0;
    items.forEach((path, index) => {
      const values = items.get(index);
      if (values && values.type === 'Q') {
        questionNum += 1;
      }
      rendered.push(this.renderItem(path, index, items, `${sectionNum}.${questionNum}`, canUpdate));
    });
    return rendered;
  }

  render() {
    const { fields, classes, sectionNum, canUpdate } = this.props;
    const onClose = () => this.setState({ dialogOpen: false });
    return (
      <div>
        {this.renderItems(fields, sectionNum, canUpdate)}
        {canUpdate ?
          <>
            <QuestionSelectionDialog
              open={this.state.dialogOpen}
              onClose={onClose}
              maxWidth="md"
              classes={{
                paperWidthMd: classes.paperWidthMd,
              }}
              onFinish={(selected = []) => {
                selected.forEach(question => fields.push({
                  ...DEFAULT_Q_ITEM,
                  question_id: question.id,
                  question,
                }));
                this.setState({ dialogOpen: false });
              }}
            />
            <div className={classes.btnGroup}>
              <Button
                className={classes.btn}
                disabled={!canUpdate}
                variant="contained"
                color="primary"
                onClick={() => fields.push({
                  ...DEFAULT_T_ITEM,
                  seq: fields.length + 1,
                })}
              >
                <Add /> Text Block
            </Button>
              <Button
                className={classes.btn}
                disabled={!canUpdate}
                variant="contained"
                color="primary"
                onClick={() => this.setState({ dialogOpen: true })}
              >
                <Add /> Question
            </Button>
            </div>
          </>
          :
          null
        }
      </div >
    );
  }
}

export default withStyles(styles)(ItemList);

