import * as Actions from './types';

const initState = {
  form: {
  },
  sort: {
    key: 'id',
    asc: false,
  },
  page: 0,
  totalElements: 0,
  totalPages: 0,
  pageSize: 20,
  numberOfElements: 0,
  data: [],
  init: false,
  submitting: false,
  done: false,
};

const QuestionnairesList = (state = initState, action) => {
  switch (action.type) {
    case Actions.FETCH_LIST:
      return {
        ...state,
        init: true,
        data: action.data.items,
        page: (action.data.meta.currentPage - 1), // zero-based
        totalElements: action.data.meta.totalCount,
        totalPages: action.data.meta.pageCount,
        numberOfElements: action.data.items.length,
        pageSize: action.data.meta.perPage,
        submitting: false,
        done: true,
      };
    case Actions.SORT_CHANGE:
      return {
        ...state,
        submitting: true,
        done: false,
        sort: action.sort,
      };
    case Actions.SEARCH_FORM_SUBMIT:
      return {
        ...state,
        submitting: true,
        done: false,
        form: {
          ...initState.form,
          ...action.form,
        },
      };
    case Actions.PAGE_CHANGE:
      return {
        ...state,
        submitting: true,
        done: false,
        page: action.page,
      };
    default:
      return state;
  }
};
export default QuestionnairesList;
