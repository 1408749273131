import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  IconButton,
  Tooltip,
  TableCell,
  TableRow,
  TableContainer,
  makeStyles,
} from '@material-ui/core';
import AddBox from '@material-ui/icons/AddBox';
import Delete from '@material-ui/icons/Delete';
import DeleteForever from '@material-ui/icons/DeleteForever';
import CloudUpload from '@material-ui/icons/CloudUpload';

import ConfirmButton from 'admin/components/ConfirmButton';
import { FormCard } from 'common/components/FormCard';
import { EnhancedTable } from 'common/components/tables';
import ToolBar from 'common/components/ToolBar';
import Loading from 'common/components/Loading';

import ListActions from 'public/redux/Activities/studentActions';
import CommonActions from 'public/redux/common/actions';

import StudentCreateForm from './StudentCreateFormContainer';
import StudentXlsxUploadForm from './StudentXlsxUploadFormContainer';

const columnData = [
  { id: 'student_id', numeric: false, disablePadding: false, label: 'Student ID', sortable: true },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: true },
  { id: 'actions', numeric: false, disablePadding: false, label: '', sortable: false },
];

const useStyles = makeStyles(() => ({
  paper: { position: 'relative' }
}));

const StudentRow = ({ rowData }) => {
  const dispatch = useDispatch();
  const handleDelete = React.useCallback(async () => {
    await dispatch(ListActions.delete(rowData));
    dispatch(CommonActions.setSnackbarMessage(`${rowData.student_id} is removed.`));
  }, [dispatch]);
  return (
    <TableRow hover tabIndex={-1}>
      <TableCell>{rowData.student_id}</TableCell>
      <TableCell>{rowData.name}</TableCell>
      <TableCell>
        <Tooltip title="Remove">
          <ConfirmButton
            ButtonComponent={IconButton}
            onConfirm={handleDelete}
            message={`Confirm to remove "${rowData.student_id}" from list?`}
          >
            <Delete />
          </ConfirmButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
const renderRow = ({ rowData, ...props }) => <StudentRow key={rowData.id} rowData={rowData} {...props} />;

const mapStateToProps = ({ ActivityStudents }) => ActivityStudents;
const StudentTable = ({
  modelID
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { page, pageSize, data, totalElements, sort, init, submitting } = useSelector(mapStateToProps);
  const [showCreate, setShowCreate] = React.useState(false);
  const [showUpload, setShowUpload] = React.useState(false);

  const handleShowCreate = React.useCallback(() => setShowCreate(true), []);
  const handleHideCreate = React.useCallback(() => setShowCreate(false), []);
  const handleShowUpload = React.useCallback(() => setShowUpload(true), []);
  const handleHideUpload = React.useCallback(() => setShowUpload(false), []);
  const handleSortChange = React.useCallback(sort => dispatch(ListActions.sortChange(sort)).catch(console.log), [dispatch]);
  const handlePageChange = React.useCallback(page => dispatch(ListActions.pageChange(page)).catch(console.log), [dispatch]);

  const renderExtraButton = React.useCallback(() => (
    <Tooltip title="Delete all students from list">
      <ConfirmButton
        ButtonComponent={IconButton}
        color="inherit"
        onConfirm={async () => {
          await dispatch(ListActions.deleteAll(modelID));
          dispatch(CommonActions.setSnackbarMessage('All students are deleted.'));
        }}
        message="Confirm to delete all students from list?"
      >
        <DeleteForever />
      </ConfirmButton>
    </Tooltip>
  ), [dispatch, modelID]);


  React.useEffect(() => {
    if (!init && !submitting) {
      dispatch(ListActions.searchFormSubmit({})).catch(console.log);
    }
  }, [dispatch, init, submitting]);

  return (
    <FormCard>
      <ToolBar
        title="Students"
        renderExtraButton={renderExtraButton}
        rightButton={[
          { title: 'Upload Xlsx', icon: (<CloudUpload />), onClick: handleShowUpload },
          { title: 'Add', icon: (<AddBox />), onClick: handleShowCreate },
        ]}
      />
      <Dialog open={showCreate} onClose={handleHideCreate} disableBackdropClick disableEscapeKeyDown>
        <StudentCreateForm
          onClose={handleHideCreate}
          onSubmitSuccess={(data) => {
            handleHideCreate();
            dispatch(CommonActions.setSnackbarMessage(`${data.student_id} is added.`));
          }}
        />
      </Dialog>
      <Dialog open={showUpload} onClose={handleHideUpload} disableBackdropClick disableEscapeKeyDown>
        <StudentXlsxUploadForm
          onClose={handleHideUpload}
          onSubmitSuccess={(data) => {
            handleHideUpload();
            dispatch(CommonActions.setSnackbarMessage(`${data.count} student(s) are added.`));
            handlePageChange(page);// refresh list
          }}
        />
      </Dialog>
      <div className={classes.paper}>
        <TableContainer>
          <EnhancedTable
            noCheckbox
            totalElements={totalElements}
            pageSize={pageSize}
            page={page}
            sort={sort}
            columnData={columnData}
            data={data}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange}
            renderRow={renderRow}
          />
        </TableContainer>
        <Loading loading={submitting} />
      </div>
    </FormCard>
  );
}

export default StudentTable;
