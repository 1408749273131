import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, withRouter } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import ReactGA from 'react-ga';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import Config, { DEV } from './constants/Config';
import store from './redux/store';
import MainApp from './MainApp';
import withTracker from './withTracker';

const MUItheme = createMuiTheme({
  palette: {
    primary: {
      light: '#91398d',
      main: '#61005f',
      dark: '#340035',
      contrastText: '#fff',
    },
    secondary: {
      light: '#b2d35e',
      main: '#80a22e',
      dark: '#507300',
      contrastText: '#000',
    },
  },
});
const meta = {
  title: 'Course and Teaching Evaluation System',
};

if (!DEV) {
  ReactGA.initialize('UA-44795713-64');
  ReactGA.set({ anonymizeIp: true });
  // console.log('initialized GA');
}

const WrappedMainApp = DEV ? MainApp : withRouter(withTracker(MainApp));

const App = () => (
  <Provider store={store}>
    <MuiThemeProvider theme={MUItheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DocumentMeta {...meta}>
          <CssBaseline />
          <BrowserRouter basename={Config.basename}>
            <WrappedMainApp />
          </BrowserRouter>
        </DocumentMeta>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </Provider>
);

export default App;
