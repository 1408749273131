import * as Actions from './types';

const initState = {
  init: false,
  model: {
    id: null,
    created_at: null,
    updated_at: null,
  },  
};

const StudentListView = (state = initState, action) => {
  switch (action.type) {
    case Actions.FETCH_ONE:
      return {
        ...state,
        init: true,
        model: action.model,
      };
    case Actions.CLEAR_ONE:
      return {
        ...initState,
      };
    default:
      return state;
  }
};
export default StudentListView;
