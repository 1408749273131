import { connect } from 'react-redux';
import AccountActions from 'public/redux/account/actions';

import LoginView from './LoginView';

const mapStateToProps = ({ account }) => account;
const mapDispatchToProps = {
  login: AccountActions.login,
  setRedirectTo: AccountActions.setRedirectTo,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);

