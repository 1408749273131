import * as Types from './types';

const INITIAL_STATE = {
  open: false,
  menuOpen: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.OPEN_DRAWER:
      return {
        ...state,
        open: true,
      };
    case Types.CLOSE_DRAWER:
      return {
        ...state,
        open: false,
      };
    case Types.TOGGLE_DRAWER:
      return {
        ...state,
        open: !state.open,
      };
    case Types.CLOSE_SUBMENU:
      return {
        ...state,
        menuOpen: '',
      };
    case Types.OPEN_SUBMENU:
      return {
        ...state,
        menuOpen: action.menu,
      };
    default:
      return state;
  }
};
