import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, FieldArray, Field } from 'redux-form';
import TextField from 'common/components/form/TextField';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import Delete from '@material-ui/icons/Delete';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Person from '@material-ui/icons/Person';
import Close from '@material-ui/icons/Close';

import { handle422 } from 'common/helpers/ReduxFormHelper';
import { FormCard } from 'common/components/FormCard';
import ToolBar from 'common/components/ToolBar';
import PageLoading from 'common/components/PageLoading';
import ConfirmButton from 'common/components/ConfirmButton';

import TeacherSelectionDialog from 'public/components/Selection/TeacherSelectionDialog';
import ItemList from './ItemList';

const DEAFULT_SECTION = {
  id: null,
  title: null,
  seq: null,
  questionnaire_id: null,
  items: [{
    type: 'T',
    seq: 1,
    content: '<p>1=Strongly disagree &nbsp; 2=Disagree &nbsp; 3=Slightly disagree &nbsp; 4=Slightly agree &nbsp; 5=Agree &nbsp; 6=Strongly agree &nbsp; NA=Not applicable</p>',
    required: false,
  }],
};

const Required = v => (!v ? 'Required' : null);

const styles = theme => ({
  btnGroup: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  section: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  sectionBtnGroup: {
    textAlign: 'center',
  },
  dirty: {
    border: '2px solid rgb(128,128,255)',
  }
});

class SectionList extends Component { // eslint-disable-line
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    // redux form
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    // redux connect
    QSections: PropTypes.shape({
      init: PropTypes.bool,
    }).isRequired,
    searchFormSubmit: PropTypes.func.isRequired,
    batchUpdate: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.renderSection = this.renderSection.bind(this);
    this.renderSections = this.renderSections.bind(this);
  }

  state = {
    showTeacherListFor: -1,
  }

  componentDidMount() {
    const { QSections: { init }, searchFormSubmit } = this.props;
    if (!init) {
      searchFormSubmit({})
        .catch(err => console.log('error', err));
    }
    this.props.setSectionsDirty?.(this.props.dirty);
  }

  componentDidUpdate() {
    this.props.setSectionsDirty?.(this.props.dirty);
  }

  async onSubmit(values) {
    if (!this.canUpdate()) {
      return null;
    }
    const { batchUpdate, model } = this.props;
    const { sections } = values;

    // console.log('beforeBatch', model.id, sections);
    const patched = sections.map((s, index) => ({
      ...s,
      questionnaire_id: model.id,
      seq: index + 1,
      items: s.items.map((q, qi) => ({
        ...q,
        seq: qi + 1,
      })),
    }));
    try {
      // console.log(patched);
      const resp = await batchUpdate({
        id: model.id,
        sections: patched,
      });
      // console.log(resp);
      return resp;
    } catch (e) {
      handle422(e.response);
      throw e;
    }
  }


  canUpdate() {
    const { model } = this.props;
    return (model.status === 0) && Boolean(model.department_code);
  }

  renderSection(path, index, sections) {
    const { classes, change } = this.props;
    const canUpdate = this.canUpdate();
    const { teacher, items } = sections.get(index);
    const empty = !Boolean(items.map(r => r.type).filter(type => type === 'Q')?.length || 0);
    return (
      <div key={path} className={classes.section}>
        <Grid container alignItems="center" spacing={8}>
          <Grid item>
            <Typography color="primary" variant="headline">Section #{index + 1}: </Typography>
          </Grid>
          <Grid item xs>
            <Field
              disabled={!canUpdate}
              name={`${path}.title`}
              component={TextField}
              placeholder="Title"
              fullWidth
              validate={[Required]}
            />
          </Grid>
          <Grid item>
            <IconButton
              disabled={!canUpdate}
              onClick={() => this.setState({ showTeacherListFor: index })}
              color="primary"
              title="Select Teacher"
            >
              <Person />
            </IconButton>
            <IconButton
              disabled={!canUpdate}
              onClick={() => {
                if (index > 0) {
                  sections.swap(index, index - 1);
                }
              }}
              color="primary"
              title="Move Section Up"
            >
              <ArrowUpward />
            </IconButton>
            <IconButton
              disabled={!canUpdate}
              onClick={() => {
                if (index < (sections.length - 1)) {
                  sections.swap(index, index + 1);
                }
              }}
              color="primary"
              title="Move Section Down"
            >
              <ArrowDownward />
            </IconButton>
            <ConfirmButton
              disabled={!canUpdate}
              ButtonComponent={IconButton}
              onConfirm={() => sections.remove(index)}
              message={`Confirm to remove section #${index + 1}?`}
            >
              <Delete />
            </ConfirmButton>
          </Grid>
        </Grid>
        {teacher ?
          <Typography>Associated to: {teacher.name}
            <IconButton
              onClick={() => {
                console.log(teacher);
                change(`${path}.teacher`, {});
                change(`${path}.teacher_id`, '');
              }}
              color="primary"
              title="Remove"
            >
              <Close />
            </IconButton>
          </Typography>
          : null}
        {empty ? <Typography variant="h5" color="error">There are no questions added to this section yet!</Typography> : null}
        <FieldArray name={`${path}.items`} component={ItemList} sectionNum={index + 1} canUpdate={canUpdate} />
        <hr />
      </div>
    );
  }

  // TODO createe Component
  renderSections({ fields }) {
    const { classes } = this.props;
    const canUpdate = this.canUpdate();
    return (
      <div>
        {fields.map(this.renderSection)}
        <div className={classes.sectionBtnGroup}>
          {canUpdate ?
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={() => { fields.push(DEAFULT_SECTION); }}
            >
              Add Section #{fields.length + 1}
            </Button>
            : null
          }
        </div>
      </div >
    );
  }

  render() {
    const { QSections: { init }, handleSubmit, pristine, submitting, reset, classes, change, dirty } = this.props;
    const onSubmit = handleSubmit(v => this.onSubmit(v));
    // console.log(data, sections);

    const canUpdate = this.canUpdate();
    const { showTeacherListFor } = this.state;
    return (
      <FormCard>
        <ToolBar
          title="Sections"
        />
        {
          init ?
            <div className={dirty ? classes.dirty : null}>
              <FieldArray name="sections" component={this.renderSections} />
              <div className={classes.btnGroup}>
                <Button size="large" variant="contained" color="primary" onClick={onSubmit} disabled={!canUpdate || pristine || submitting}>SAVE</Button>
                <Button size="large" color="primary" onClick={reset} disabled={pristine}>RESET</Button>
              </div>
              <TeacherSelectionDialog
                open={showTeacherListFor >= 0}
                onClose={() => this.setState({ showTeacherListFor: -1 })}
                onFinish={(t) => {
                  change(`sections[${showTeacherListFor}].teacher`, t);
                  change(`sections[${showTeacherListFor}].teacher_id`, t.id);
                  this.setState({ showTeacherListFor: -1 });
                }}
              />
            </div>
            : <PageLoading />
        }
      </FormCard>
    );
  }
}

export default reduxForm({
  form: 'SectionListForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
})(withStyles(styles)(SectionList));

