import { connect } from 'react-redux';
// import { formValueSelector } from 'redux-form';
import actions from 'public/redux/OnlineSurveys/actions';
import OnlineSurveyView from './OnlineSurveyView';

// const selector = formValueSelector('OnlineSurveyUpdateForm');

const mapStateToProps = state => ({
  ...state.OnlineSurveyForm,
  initialValues: {
    ...state.OnlineSurveyForm.model,
  },
});
const mapDispatchToProps = {
  fetchOne: actions.fetchOne,
  clearOne: actions.clearOne,
  update: actions.update,
};

// connect with redux
export default connect(mapStateToProps, mapDispatchToProps)(OnlineSurveyView);
