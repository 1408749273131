import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';


const EnhancedTableHead = ({
  onSelectAllClick, onRequestSort,
  order, orderBy, numSelected, rowCount, columnData, noCheckbox,
  disableSelectAll = false,
  ...props
}) => {
  const createSortHandler = property => event => onRequestSort(event, property);
  return (
    <TableHead {...props}>
      <TableRow>
        {!noCheckbox ?
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              disabled={disableSelectAll}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          : null
        }
        {columnData.map(column => (
          <TableCell
            key={column.id}
            align={column.numeric ? 'right' : undefined}
            padding={column.disablePadding ? 'none' : (column.padding || undefined)}
            sortDirection={orderBy === column.id ? order : false}
          >
            {column.sortable ?
              <Tooltip
                title="Sort"
                placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                enterDelay={300}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={order}
                  onClick={createSortHandler(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </Tooltip> : column.label
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  columnData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.node,
    numeric: PropTypes.bool,
    padding: PropTypes.string,
    disablePadding: PropTypes.bool,
  })).isRequired, // eslint-disable-line
  noCheckbox: PropTypes.bool,
};
EnhancedTableHead.defaultProps = {
  noCheckbox: false,
}

export default EnhancedTableHead;
