import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  TableContainer,
  IconButton,
  Paper,
  Tooltip,
  Collapse,
  TableCell,
  TableRow,
  Checkbox,
  Menu,
  makeStyles,
} from '@material-ui/core';

import FilterList from '@material-ui/icons/FilterList';
import Edit from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';

import { EnhancedTable } from 'common/components/tables';
import ToolBar from 'common/components/ToolBar';
import Loading from 'common/components/Loading';

import ListActions from 'public/redux/Coordinator/OnlineSurveys/actions';

import OnlineSurveySearchForm from './OnlineSurveySearchForm';
import ReportButtonsMenu from './ReportButtonsMenu';
import BatchDownloadReportDialog from './BatchDownloadReportDialog';

const columnData = [
  { id: 'id', numeric: false, disablePadding: false, padding: 'checkbox', label: 'ID', sortable: true },
  { id: 'name', numeric: false, disablePadding: false, padding: 'checkbox', label: 'Name', sortable: true },
  { id: 'c.name', numeric: false, disablePadding: false, padding: 'checkbox', label: 'Activity', sortable: true },
  { id: 'course.code', numeric: false, disablePadding: false, padding: 'checkbox', label: 'Course Code', sortable: true },
  { id: 'c.year', numeric: false, disablePadding: false, padding: 'checkbox', label: 'Academic Year', sortable: true },
  { id: 'q.name', numeric: false, disablePadding: false, padding: 'checkbox', label: 'Questionnaire', sortable: true },
  { id: 'department_code', numeric: false, disablePadding: false, padding: 'checkbox', label: 'Department Code', sortable: true },
  { id: 'overall_cte', numeric: false, disablePadding: false, padding: 'checkbox', label: 'Overall Course Evaluation', sortable: true },
  { id: 'published', numeric: false, disablePadding: false, padding: 'checkbox', label: 'Status', sortable: true },
  { id: 'start', numeric: true, disablePadding: false, padding: 'checkbox', label: 'Start', sortable: true },
  { id: 'end', numeric: true, disablePadding: false, padding: 'checkbox', label: 'End', sortable: true },
  { id: 'actions', numeric: false, disablePadding: false, padding: 'checkbox', label: '', sortable: false },
];


const useStyles = makeStyles(() => ({
  paper: { position: 'relative' },
}));

const formatDatetime = datetime => (datetime || moment(datetime).format());
const renderPublished = (row) => {
  switch (Number(row.published)) {
    case -1:
      return <span>Cancelled</span>;
    case 0:
      return <span>Draft</span>;
    case 1:
      return <strong>Published</strong>;
    case 2:
      return <strong>Completed</strong>;
    default:
      return null;
  }
};


const SuervyRow = ({
  rowData,
  selectedRows,
  setSelectedRows,
  setOpenMenuRowId,
  openMenuRowId,
  isDepartmentCoordinator,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isSelected = id => (selectedRows.findIndex(ele => ele.id === id) >= 0);
  const rowSelected = isSelected(rowData.id);
  const handleRowClick = () => {
    if (rowSelected) {
      setSelectedRows(selectedRows.filter(ele => ele.id !== rowData.id));
    } else {
      setSelectedRows(selectedRows.concat([rowData]));
    }
  };
  const handleMenuClick = React.useCallback((e) => {
    setAnchorEl(e.currentTarget);
    setOpenMenuRowId(rowData.id)
  }, [rowData.id]);
  const handleMenuClose = React.useCallback(() => {
    setAnchorEl(null);
    setOpenMenuRowId(null)
  }, []);

  return (
    <TableRow
      hover
      tabIndex={-1}
      selected={rowSelected}
      aria-checked={rowSelected}>
      <TableCell padding="checkbox">
        <Checkbox color="primary" checked={rowSelected} onClick={handleRowClick} />
      </TableCell>
      <TableCell padding="checkbox">{rowData.id}</TableCell>
      <TableCell padding="checkbox">{rowData.name}</TableCell>
      <TableCell padding="checkbox">
        {isDepartmentCoordinator ?
          <Link to={`/coordinator/activity/${rowData.class_id}`}>
            {rowData.class?.name || rowData.class_id}
          </Link>
          : <span>{rowData.class?.name || rowData.class_id}</span>
        }
      </TableCell>
      <TableCell padding="checkbox">
        {rowData.class?.course?.code}
      </TableCell>
      <TableCell padding="checkbox">
        {rowData.class?.year}
      </TableCell>
      <TableCell padding="checkbox">
        <Link to={`/coordinator/survey/preview/${rowData.id}`} target="_blank" rel="noreferer noopener">
          {rowData.questionnaire.name}
        </Link>
      </TableCell>
      <TableCell padding="checkbox">{rowData.department_code}</TableCell>
      <TableCell padding="checkbox">{rowData.overall_cte ? 'Yes' : 'No'}</TableCell>
      <TableCell padding="checkbox">{renderPublished(rowData)}</TableCell>
      <TableCell align="right" padding="checkbox">{formatDatetime(rowData.start)}</TableCell>
      <TableCell align="right" padding="checkbox">{formatDatetime(rowData.end)}</TableCell>
      <TableCell padding="checkbox">
        <Tooltip title="View Details">
          <IconButton component={Link} to={`/coordinator/survey/${rowData.id}`} aria-label="View Details"><Edit /></IconButton>
        </Tooltip>
        <Tooltip title="Download Report">
          <span>
            <IconButton
              aria-owns={`row-${rowData.id}`}
              aria-haspopup="true"
              onClick={handleMenuClick}
              aria-label="Download Report"
              disabled={(Number(rowData.published) !== 2)}
            >
              <GetAppIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Menu
          id={`row-${rowData.id}`}
          anchorEl={anchorEl}
          open={(Boolean(anchorEl) && openMenuRowId === rowData.id)}
          onClose={handleMenuClose}
        >
          <ReportButtonsMenu surveyId={rowData.id} activity={rowData.class || {}} />
        </Menu>
      </TableCell>
    </TableRow>);
};
const renderRow = ({ rowData, ...props }) => <SuervyRow key={`${rowData.id}`}  {...props} rowData={rowData} />;

const isNonEmptyArray = arr => Array.isArray(arr) && (arr.length > 0);
const checkIsStaff = user => Boolean(user.staff) && Boolean(user.staff?.status === 10) && isNonEmptyArray(user.staff?.staffDepartments);
const mapStateToProps = ({ CoordinatorOnlineSurveys, account }) => ({
  isDepartmentCoordinator: checkIsStaff(account.user) && isNonEmptyArray(account.user.staff?.staffCoordDepartments),
  ...CoordinatorOnlineSurveys,
});

const OnlineSurveyList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { init, submitting, page, pageSize, form, data, totalElements, sort, isDepartmentCoordinator } = useSelector(mapStateToProps);
  const [formOpen, setFormOpen] = React.useState(true);
  const [openMenuRowId, setOpenMenuRowId] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [batchDownloadOpen, setBatchDownloadOpen] = React.useState(false);

  const handleSearch = React.useCallback(values => dispatch(ListActions.searchFormSubmit(values)).catch(console.log), [dispatch]);
  const handleSortChange = React.useCallback(sort => dispatch(ListActions.sortChange(sort)).catch(console.log), [dispatch]);
  const handlePageChange = React.useCallback(page => dispatch(ListActions.pageChange(page)).catch(console.log), [dispatch]);
  React.useEffect(() => {
    if (!init && !submitting) {
      dispatch(ListActions.searchFormSubmit({})).catch(console.log);
    }
  }, [dispatch, init, submitting]);

  return (
    <div>
      <ToolBar
        color="default"
        title="Course Evaluation"
        rightButton={[
          { title: 'Filter list', icon: (<FilterList />), onClick: () => setFormOpen(!formOpen) },
          { title: 'Batch Download', icon: (<GetAppIcon />), ButtonProps: { disabled: (!(rows?.length) || rows?.length > 20) }, onClick: () => setBatchDownloadOpen(true) },
        ]}
      />
      <Collapse in={formOpen}>
        <OnlineSurveySearchForm
          initialValues={form}
          onSubmit={handleSearch}
        />
      </Collapse>
      <Paper className={classes.paper}>
        <TableContainer>
          <EnhancedTable
            totalElements={totalElements}
            pageSize={pageSize}
            page={page}
            sort={sort}
            columnData={columnData}
            data={data}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange}
            renderRow={renderRow}
            renderRowProps={{
              isDepartmentCoordinator,
              openMenuRowId,
              setOpenMenuRowId,
            }}
            onRowsSelected={setRows}
            initialRowsSelected={[]}
          />
        </TableContainer>
        <Loading loading={submitting} />
      </Paper>
      <BatchDownloadReportDialog open={batchDownloadOpen} onClose={() => setBatchDownloadOpen(false)} rows={rows} />
    </div>
  );
};

export default OnlineSurveyList;
