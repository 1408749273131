import React from 'react';
import {
  Dialog,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';

import FilterListIcon from '@material-ui/icons/FilterList';
import ToolBar from 'common/components/ToolBar';
import SingleTeacherSelectionDialogContent from './SingleTeacherSelectionDialogContent';
import MultipleTeacherSelectionDialogContent from './MultipleTeacherSelectionDialogContent';


const useStyles = makeStyles(() => ({
  dialogTitle: {
    paddingBottom: 0,
  },
}));

const TeacherSelectionDialog = ({
  onClose,
  onFinish,
  multiple,
  open = false,
  initialValues = {},
  ...props
}) => {
  const classes = useStyles();
  const [formOpen, setFormOpen] = React.useState(true);
  const handleToggleFormOpen = React.useCallback(() => setFormOpen(!formOpen), [formOpen]);
  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullScreen
      disableBackdropClick
      disableEscapeKeyDown
      {...props}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <ToolBar
          title="Select Teacher"
          rightButton={[
            { title: 'Filter list', icon: (<FilterListIcon />), onClick: handleToggleFormOpen },
          ]}
        />
      </DialogTitle>
      {
        multiple ?
          <MultipleTeacherSelectionDialogContent onClose={onClose} onFinish={onFinish} formOpen={formOpen} initialValues={initialValues} />
          : <SingleTeacherSelectionDialogContent onClose={onClose} onFinish={onFinish} formOpen={formOpen} initialValues={initialValues} />
      }
    </Dialog>
  );
}

export default TeacherSelectionDialog;