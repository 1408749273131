import merge from 'lodash/merge';
import * as actions from './types';
import service from './studentService';


const fetchStudentList = (search, dispatch) =>
  service
    .fetchList(search)
    .then(response => dispatch({ type: actions.STUDENTS_FETCH_LIST, data: response.data }));

const getNewStudentsSearch = (search, getState) => {
  const { ActivityStudents: { form, sort, page }, ActivityForm: { model } } = getState();
  return merge({}, { form, sort, page }, search, { form: { list_id: model.id } });
};

const creators = {
  sortChange: sort =>
    async (dispatch, getState) => {
      dispatch({ type: actions.STUDENTS_SORT_CHANGE, sort });
      return fetchStudentList(getNewStudentsSearch({ sort }, getState), dispatch);
    },
  pageChange: page =>
    async (dispatch, getState) => {
      dispatch({ type: actions.STUDENTS_PAGE_CHANGE, page });
      return fetchStudentList(getNewStudentsSearch({ page }, getState), dispatch);
    },
  searchFormSubmit: form =>
    async (dispatch, getState) => {
      dispatch({ type: actions.STUDENTS_SEARCH_FORM_SUBMIT, form });
      return fetchStudentList(getNewStudentsSearch({ form, page: 0 }, getState), dispatch);
    },
  create: data =>
    async (dispatch, getState) => {
      const response = await service.create(data);
      fetchStudentList(getNewStudentsSearch({}, getState), dispatch);
      return response.data;
    },
  delete: model =>
    async (dispatch, getState) => {
      const response = await service.deleteOne(model.id);
      fetchStudentList(getNewStudentsSearch({}, getState), dispatch);
      return response.data;
    },
  deleteAll: id =>
    async (dispatch, getState) => {
      const response = await service.deleteAll(id);
      fetchStudentList(getNewStudentsSearch({}, getState), dispatch);
      return response.data;
    },
};

export default creators;
