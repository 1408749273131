import { connect } from 'react-redux';
import actions from 'public/redux/Coordinator/OnlineSurveys/actions';
import OnlineSurveyList from './OnlineSurveyList';


const isNonEmptyArray = arr => Array.isArray(arr) && (arr.length > 0);
const checkIsStaff = user => Boolean(user.staff) && Boolean(user.staff.status === 10) && isNonEmptyArray(user.staff.staffDepartments);

const mapStateToProps = ({ CoordinatorOnlineSurveys, account }) => ({
  isDepartmentCoordinator: checkIsStaff(account.user) && isNonEmptyArray(account.user.staff.staffCoordDepartments),
  ...CoordinatorOnlineSurveys,
});
const mapDispatchToProps = {
  searchFormSubmit: actions.searchFormSubmit,
  pageChange: actions.pageChange,
  sortChange: actions.sortChange,
  update: actions.update,
  delete: actions.delete,
};

// connect with redux
export default connect(mapStateToProps, mapDispatchToProps)(OnlineSurveyList);
