import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CommonActions from 'public/redux/common/actions';

const CommonSnackbar = ({ ...props }) => {
  const dispatch = useDispatch();
  const { snackbarMessage } = useSelector(state => state.common);
  const handleClose = React.useCallback(() => dispatch(CommonActions.setSnackbarMessage(null)), [dispatch]);
  return (
    <Snackbar
      open={Boolean(snackbarMessage)}
      message={snackbarMessage}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={5000}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
      {...props}
    />
  );
}

export default CommonSnackbar;