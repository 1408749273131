import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

class GetFlashView extends Component {

  componentDidMount() {
    const { history, getFlashUser, redirectTo, setRedirectTo } = this.props;
    getFlashUser()
      .then(() => {
        setRedirectTo(null);
        history.replace(redirectTo || '/');
      })
      .catch(() => history.replace('/login'));
  }

  render() {
    return (<CircularProgress size={72} />);
  }
}

export default withRouter(GetFlashView);
