import React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  AppBar,
  Hidden,
  Toolbar,
  Typography,
  IconButton,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import LeftDrawerActions from 'public/redux/leftDrawer/actions';
import UserAvatar from './UserAvatar';

const useStyles = makeStyles(theme => ({
  appBar: {
    color: theme.palette.common.white,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginLeft: 0,
    marginRight: 20,
  },
  rightGroupItem: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right',
    },
  },
  title: {
    flex: 1,
  },
}));

const AppNav = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { name } = useSelector(mapStateToProps);
  const toggleDrawer = React.useCallback(() => dispatch(LeftDrawerActions.toggleDrawer()), [dispatch]);
  return (
    <AppBar className={clsx(classes.appBar)} position="fixed" color="primary">
      <Toolbar>
        <IconButton className={classes.menuButton} aria-label="Menu" onClick={toggleDrawer} color="inherit">
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" color="inherit" noWrap className={classes.title}>{document.title}</Typography>
        <Hidden smDown>
          <Typography color="inherit" variant="body2">{name}</Typography>
        </Hidden>
        <div className={classes.rightGroupItem}>
          <UserAvatar />
        </div>
      </Toolbar>
    </AppBar>
  )
};
const mapStateToProps = ({ account }) => ({
  name: account.user.full_name,
});

export default AppNav;
