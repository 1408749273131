import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  Collapse,
  Drawer,
  Paper,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';

import Home from '@material-ui/icons/Home';
import Group from '@material-ui/icons/Group';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import Assignment from '@material-ui/icons/Assignment';
import Folder from '@material-ui/icons/Folder';
import InsertChart from '@material-ui/icons/InsertChart';
import ShowChart from '@material-ui/icons/ShowChart';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';

import MedLogo from 'resources/cu_medi_logo_landscape.jpg';
import LeftDrawerActions from 'public/redux/leftDrawer/actions';

const drawerWidth = 260;
const useStyles = makeStyles(theme => ({
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    overflowY: 'auto',
    fontFamily: theme.typography.fontFamily,
  },
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  nested: {
    paddingLeft: theme.spacing(5),
  },
  img: {
    maxWidth: '100%',
  },
  collapseMenu: {
    listStyle: 'none',
  },
}));

const isNonEmptyArray = arr => Array.isArray(arr) && (arr.length > 0);
const checkIsStaff = user => Boolean(user.staff) && Boolean(user.staff.status === 10) && isNonEmptyArray(user.staff.staffDepartments);
const checkIsCoordinator = user => Boolean(user.staff) && Boolean(user.staff.status === 10) && (
  isNonEmptyArray(user.staff.staffCourseYears) || isNonEmptyArray(user.staff.staffCourses) ||
  isNonEmptyArray(user.staff.staffCoordDepartments) || isNonEmptyArray(user.staff.staffProgrammes)
);

const mapStateToProps = ({ leftDrawer, account }) => ({
  ...leftDrawer,
  user: account.user,
});

const StaffMenus = ({
  classes,
  handleDrawerClose,
  handleMenuOpen,
  menuOpen,
}) => {
  return (
    <>
      <ListItem button key="staff" onClick={() => handleMenuOpen('staff')}>
        <ListItemIcon>
          <Group />
        </ListItemIcon>
        <ListItemText primary="Staff Area" />
      </ListItem>
      <Collapse in={menuOpen === 'staff'}>
        <ListItem button key="online-surveys" className={classes.nested} component={NavLink} activeClassName={classes.selected} exact to="/staff/online-surveys" onClick={handleDrawerClose}>
          <ListItemIcon>
            <Assignment />
          </ListItemIcon>
          <ListItemText primary="Online CTE" />
        </ListItem>
        <ListItem button key="questionnaires" className={classes.nested} component={NavLink} activeClassName={classes.selected} exact to="/staff/questionnaires" onClick={handleDrawerClose}>
          <ListItemIcon>
            <LibraryBooks />
          </ListItemIcon>
          <ListItemText primary="Questionnaires" />
        </ListItem >
        <ListItem button key="questions" className={classes.nested} component={NavLink} activeClassName={classes.selected} exact to="/staff/questions" onClick={handleDrawerClose}>
          <ListItemIcon>
            <Folder />
          </ListItemIcon>
          <ListItemText primary="Questions" />
        </ListItem >
        <ListItem button key="activities" className={classes.nested} component={NavLink} activeClassName={classes.selected} exact to="/staff/activities" onClick={handleDrawerClose}>
          <ListItemIcon>
            <AssignmentInd />
          </ListItemIcon>
          <ListItemText primary="Activities" />
        </ListItem>
      </Collapse>
    </>
  );
};

const StaffReportMenus = ({
  classes,
  handleDrawerClose,
  handleMenuOpen,
  menuOpen,
}) => {
  return (
    <>
      <ListItem button key="report" onClick={() => handleMenuOpen('report')}>
        <ListItemIcon>
          <InsertChart />
        </ListItemIcon>
        <ListItemText primary="Reporting" />
      </ListItem>
      <Collapse in={menuOpen === 'report'}>
        <ListItem button key="spus" className={classes.nested} component={NavLink} activeClassName={classes.selected} exact to="/report/spus" onClick={handleDrawerClose}>
          <ListItemIcon>
            <ShowChart />
          </ListItemIcon>
          <ListItemText primary="SPUS" />
        </ListItem>
        <ListItem button key="teacher-yearly" className={classes.nested} component={NavLink} activeClassName={classes.selected} exact to="/report/teacher-yearly" onClick={handleDrawerClose}>
          <ListItemIcon>
            <ShowChart />
          </ListItemIcon>
          <ListItemText primary="Teacher’s CTE Summary Report" />
        </ListItem>
        <ListItem button key="response-rate" className={classes.nested} component={NavLink} activeClassName={classes.selected} exact to="/report/response-rate" onClick={handleDrawerClose}>
          <ListItemIcon>
            <ShowChart />
          </ListItemIcon>
          <ListItemText primary="Response Rate" />
        </ListItem>
      </Collapse>
    </>
  );
}

const CoordinatorMenus = ({
  classes,
  handleDrawerClose,
  handleMenuOpen,
  menuOpen,
  isDepartmentCoordinator,
}) => {

  return (
    <>
      <ListItem button key="coordinator" onClick={() => handleMenuOpen('coordinator')}>
        <ListItemIcon>
          <Group />
        </ListItemIcon>
        <ListItemText primary="Coordinator Area" />
      </ListItem>
      <Collapse in={menuOpen === 'coordinator'}>
        <ListItem button className={classes.nested} component={NavLink} activeClassName={classes.selected} exact to="/coordinator/surveys" onClick={handleDrawerClose}>
          <ListItemIcon>
            <Assignment />
          </ListItemIcon>
          <ListItemText primary="Course Evaluation" />
        </ListItem>
        {
          isDepartmentCoordinator ?
            <ListItem button className={classes.nested} component={NavLink} activeClassName={classes.selected} exact to="/coordinator/activities" onClick={handleDrawerClose}>
              <ListItemIcon>
                <AssignmentInd />
              </ListItemIcon>
              <ListItemText primary="Activities" />
            </ListItem> : null
        }
        <ListItem button className={classes.nested} component={NavLink} activeClassName={classes.selected} exact to="/coordinator/report/course-feedbacks" onClick={handleDrawerClose}>
          <ListItemIcon>
            <PictureAsPdf />
          </ListItemIcon>
          <ListItemText primary="Written comments" />
        </ListItem>
        <ListItem button key="/coordinator/report/teaching-performance" className={classes.nested} component={NavLink} activeClassName={classes.selected} exact to="/coordinator/report/teaching-performance" onClick={handleDrawerClose}>
          <ListItemIcon>
            <ShowChart />
          </ListItemIcon>
          <ListItemText primary="Teaching Performance" />
        </ListItem>
        {
          isDepartmentCoordinator ?
            <ListItem button className={classes.nested} component={NavLink} activeClassName={classes.selected} exact to="/coordinator/report/spus" onClick={handleDrawerClose}>
              <ListItemIcon>
                <ShowChart />
              </ListItemIcon>
              <ListItemText primary="SPUS" />
            </ListItem> : null
        }
        {
          isDepartmentCoordinator ?
            <ListItem button className={classes.nested} component={NavLink} activeClassName={classes.selected} exact to="/coordinator/report/teacher-yearly" onClick={handleDrawerClose}>
              <ListItemIcon>
                <ShowChart />
              </ListItemIcon>
              <ListItemText primary="Teacher’s CTE Summary Report" />
            </ListItem>
            : null
        }{
          isDepartmentCoordinator ?
            <ListItem button className={classes.nested} component={NavLink} activeClassName={classes.selected} exact to="/coordinator/report/response-rate" onClick={handleDrawerClose}>
              <ListItemIcon>
                <ShowChart />
              </ListItemIcon>
              <ListItemText primary="Response Rate" />
            </ListItem> : null
        }
      </Collapse>
    </>
  );
}

const LeftDrawer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open, menuOpen, user } = useSelector(mapStateToProps);
  const isStaff = user && checkIsStaff(user);
  const canViewReporting = isStaff && user.staff?.view_report;
  const isCoordinator = user && checkIsCoordinator(user);
  const isDepartmentCoordinator = isNonEmptyArray(user.staff?.staffCoordDepartments || []);

  // console.log(user, isStaff, canViewReporting, isCoordinator);
  // console.log(menuOpen);
  const handleDrawerClose = React.useCallback(() => {
    dispatch(LeftDrawerActions.closeDrawer());
  }, [dispatch]);
  const handleMenuOpen = React.useCallback((menuToOpen) => {
    if (menuOpen === menuToOpen) {
      dispatch(LeftDrawerActions.closeMenu());
    } else {
      dispatch(LeftDrawerActions.openMenu(menuToOpen));
    }
  }, [dispatch, menuOpen]);

  return (
    <Drawer
      variant="temporary"
      anchor="left"
      open={open}
      onBackdropClick={handleDrawerClose}
    >
      <Paper className={classes.drawerPaper}>
        <ListItem>
          <img src={MedLogo} alt="CUHK Faculty of Medicine" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          activeClassName={classes.selected}
          exact
          to="/"
          onClick={handleDrawerClose}
        >
          <ListItemIcon><Home /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          activeClassName={classes.selected}
          to="/survey"
          onClick={handleDrawerClose}
        >
          <ListItemIcon><LibraryBooks /></ListItemIcon>
          <ListItemText primary="Survey" />
        </ListItem>
        {
          isStaff ?
            <StaffMenus
              classes={classes}
              handleDrawerClose={handleDrawerClose}
              menuOpen={menuOpen}
              handleMenuOpen={handleMenuOpen}
            /> : null
        }
        {
          isCoordinator ?
            <CoordinatorMenus classes={classes}
              handleDrawerClose={handleDrawerClose}
              menuOpen={menuOpen}
              handleMenuOpen={handleMenuOpen}
              isDepartmentCoordinator={isDepartmentCoordinator}
            /> : null
        }
        {
          canViewReporting ?
            <StaffReportMenus
              classes={classes}
              handleDrawerClose={handleDrawerClose}
              menuOpen={menuOpen}
              handleMenuOpen={handleMenuOpen}
            /> : null
        }
      </Paper>
    </Drawer >
  );
}

class LeftDrawer1 extends Component {// eslint-disable-line
  renderCoordinatorMenus() {
    const { classes, handleDrawerClose, user } = this.props;
    const isDepartmentCoordinator = isNonEmptyArray(user.staff.staffCoordDepartments);

    const menus = [
      <ListItem button key="coordinator">
        <ListItemIcon>
          <Group />
        </ListItemIcon>
        <ListItemText primary="Coordinator Area" />
      </ListItem>,
      <ListItem button key="coordinator/surveys" className={classes.nested} component={NavLink} activeClassName={classes.selected} exact to="/coordinator/surveys" onClick={handleDrawerClose}>
        <ListItemIcon>
          <Assignment />
        </ListItemIcon>
        <ListItemText primary="Course Evaluation" />
      </ListItem>,
    ];
    if (isDepartmentCoordinator) {
      menus.push(
        <ListItem button key="coordinator/activities" className={classes.nested} component={NavLink} activeClassName={classes.selected} exact to="/coordinator/activities" onClick={handleDrawerClose}>
          <ListItemIcon>
            <AssignmentInd />
          </ListItemIcon>
          <ListItemText primary="Activities" />
        </ListItem>
      );
    }
    menus.push(
      <ListItem button key="/coordinator/report/course-feedbacks" className={classes.nested} component={NavLink} activeClassName={classes.selected} exact to="/coordinator/report/course-feedbacks" onClick={handleDrawerClose}>
        <ListItemIcon>
          <PictureAsPdf />
        </ListItemIcon>
        <ListItemText primary="Written comments" />
      </ListItem>
    );
    menus.push(
      <ListItem button key="/coordinator/report/teaching-performance" className={classes.nested} component={NavLink} activeClassName={classes.selected} exact to="/coordinator/report/teaching-performance" onClick={handleDrawerClose}>
        <ListItemIcon>
          <ShowChart />
        </ListItemIcon>
        <ListItemText primary="Teaching Performance" />
      </ListItem>
    );
    if (isDepartmentCoordinator) {
      menus.push(
        <ListItem button key="/coordinator/report/spus" className={classes.nested} component={NavLink} activeClassName={classes.selected} exact to="/coordinator/report/spus" onClick={handleDrawerClose}>
          <ListItemIcon>
            <ShowChart />
          </ListItemIcon>
          <ListItemText primary="SPUS" />
        </ListItem>
      );
    }
    return menus;
  }
}

export default LeftDrawer;
