import axios from 'axios';
import Config from 'public/constants/Config';
import * as Types from './types';

export default {
  login: loginForm =>
    dispatch => axios.post(`${Config.baseUrl}/account/login`, loginForm)
      .then((response) => {
        dispatch({ type: Types.LOGIN_USER, user: response.data });
        return response.data;
      }),
  getFlashUser: () =>
    dispatch => axios.get(`${Config.baseUrl}/one-pass/flash`)
      .then((response) => {
        dispatch({ type: Types.LOGIN_USER, user: response.data });
        return response.data;
      }),
  logout: () =>
    async dispatch => dispatch({ type: Types.LOGOUT_USER }),
  setRedirectTo: url =>
    async dispatch => dispatch({ type: Types.SET_REDIRECT_TO, url }),
};
