import * as Actions from './types';
const INIT_STATE = {
  snackbarMessage: null,
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.UPDATE_SNACKBAR_MESSAGE:
      return {
        ...state,
        snackbarMessage: action.message,
      };
    default:
      return state;
  }
}