import React from 'react';
import { reduxForm, Field } from 'redux-form';
import {
  InputLabel,
  makeStyles,
} from '@material-ui/core';

import TextField from 'common/components/form/TextField';
import SearchFormBase from 'common/components/SearchFormBase';
import UserDepartmentDropdown from 'public/components/form/UserDepartmentDropdown';

const useStyles = makeStyles(theme => ({
  field: {
    marginRight: theme.spacing(2),
    minWidth: 150,
  },
}));


const EMPTY_FORM = {
  id: '',
  name: '',
  is_master: null,
  department_code: '',
  çourse_code: '',
  year: '',
  teacherName: '',
  active: null,
};

const ActivitySearchForm = ({
  handleSubmit,
  onSubmit,
}) => {
  const classes = useStyles();
  const handleClear = React.useCallback(() => onSubmit(EMPTY_FORM), [onSubmit]);
  return (
    <SearchFormBase onSubmit={handleSubmit} onClear={handleClear}>
      <Field
        name="id"
        className={classes.field}
        label="ID"
        component={TextField}
      />
      <Field
        name="name"
        className={classes.field}
        label="Name"
        component={TextField}
      />
      <Field
        name="year"
        className={classes.field}
        label="Academic Year"
        component={TextField}
      />
      <Field
        name="course_code"
        className={classes.field}
        label="Course Code"
        component={TextField}
      />
      <Field
        name="is_master"
        className={classes.field}
        label="Master Template"
        component={TextField}
        select
        SelectProps={{ native: true }}
      >
        <option value="" />
        <option value="1">Yes</option>
        <option value="0">No</option>
      </Field>
      <div className={classes.field}>
        <InputLabel>Department</InputLabel>
        <Field
          name="department_code"
          component={UserDepartmentDropdown}
          placeholder="Select..."
          fullWidth
        />
      </div>
      <Field
        name="teacherName"
        className={classes.field}
        label="Teacher Name"
        component={TextField}
      />
      <Field
        name="active"
        className={classes.field}
        label="Status"
        component={TextField}
        select
        SelectProps={{ native: true }}
      >
        <option value="" />
        <option value="1">Active</option>
        <option value="0">Inactive</option>
      </Field>
      <Field
        name="by_me"
        className={classes.field}
        label="Created By"
        component={TextField}
        select
        SelectProps={{ native: true }}
      >
        <option value="" />
        <option value="1">Me only</option>
      </Field>
    </SearchFormBase>
  );
};


export default reduxForm({
  form: 'ActivitySearchForm',
  enableReinitialize: true,
})(ActivitySearchForm);
