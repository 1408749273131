import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  formCardSectionRow: {
    display: 'flex',
  },
  formCardHeader: {
    width: 240,
    minWidth: 240,
    margin: theme.spacing(2),
  },
  formCardSectionContent: {
    margin: theme.spacing(2),
    flex: '1 1 auto',
  },
});

const FormCardSectionFieldRow = (props) => {
  const { classes, children, title } = props;
  return (
    <div className={classes.formCardSectionRow}>
      <div className={classes.formCardHeader}>
        <Typography variant="h6">{title}</Typography>
      </div>
      <div className={classes.formCardSectionContent}>
        {children}
      </div>
    </div>
  );
};
FormCardSectionFieldRow.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};
FormCardSectionFieldRow.defaultProps = {
  children: null,
};

export default withStyles(styles)(FormCardSectionFieldRow);
