import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Async } from 'react-select-plus';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';

import Clear from '@material-ui/icons/Clear';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import 'react-select-plus/dist/react-select-plus.css';

import axios from 'public/helpers/MyAxios';

import BaseField from './BaseField';

const styles = theme => ({
  dropdown: {
    fontFamily: theme.typography.fontFamily,
    '& .Select-menu-outer': {
      zIndex: theme.zIndex.modal + 1,
    },
  },
  error: {
    '& .Select-control': {
      borderColor: theme.palette.error.main,
    },
  },
});

class RemoteDropdown extends Component {
  static propTypes = {
    input: PropTypes.shape({}).isRequired,
    label: PropTypes.string,
    meta: PropTypes.shape({}).isRequired,
    multi: PropTypes.bool,
    fullWidth: PropTypes.bool,
    labelProperty: PropTypes.string,
    valueProperty: PropTypes.string,
    apiUrl: PropTypes.string.isRequired,
    classes: PropTypes.shape({}).isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    label: null,
    multi: false,
    fullWidth: false,
    labelProperty: 'name',
    valueProperty: 'code',
    className: null,
  };

  onChange(selected) {
    const { input, multi } = this.props;
    if (multi) {
      return input.onChange(selected.map((s) => {
        if (s && s.value) {
          return s.value;
        }
        return s;
      }));
    }
    return input.onChange(selected);
  }

  materialArrowRender = (onMouseDown, isOpen) =>
    (isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />);

  materialClearRender = () => <Clear />

  loadOptions = async () => {
    const { apiUrl, labelProperty, valueProperty } = this.props;
    const response = await axios.get(axios.addApiUrl(apiUrl), {
      headers: {
        Accept: 'application/json',
      },
    });
    const { data } = response;
    const options = data
      .map(row => ({
        ...row,
        label: row[labelProperty],
        value: row[valueProperty],
      }));
    return { options, complete: true };
  }

  render() {
    const {
      label,
      input,
      meta,
      multi,
      fullWidth,
      classes,
      className,
      helperText,
      ...rest
    } = this.props;
    return (
      <div className={className}>
        {label ? <InputLabel>{label}</InputLabel> : null}
        <BaseField label={null} input={input} meta={meta} fullWidth={fullWidth} helperText={helperText}>
          <Async
            className={cx(classes.dropdown, meta.error ? classes.error : null)}
            arrowRenderer={this.materialArrowRender}
            clearRenderer={this.materialClearRender}
            loadOptions={this.loadOptions}
            multi={multi}
            {...input}
            {...rest}
            onChange={selected => this.onChange(selected)}
            onBlur={() => input.onBlur(input.value)}
          />
        </BaseField>
      </div>
    );
  }
}
export default withStyles(styles)(RemoteDropdown);
