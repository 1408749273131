import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { union, unionBy } from 'lodash';
import { Field, reduxForm } from 'redux-form';
import TextField from 'common/components/form/TextField';

import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';

import { handle422 } from 'common/helpers/ReduxFormHelper';
import AcademicInput from 'common/components/form/AcademicInput';
import { FormCard, FormCardSection, FormCardSectionFieldRow, FormCardContent } from 'common/components/FormCard';
import Toolbar from 'common/components/ToolBar';
import NotificationSnackbar from 'common/components/NotificationSnackbar';
import BooleanCheckbox from 'common/components/form/BooleanCheckbox';
import { isAcademicYear } from 'common/helpers/validation';

import UserDepartmentDropdown from 'public/components/form/UserDepartmentDropdown';
import RemoteDropdown from 'public/components/form/RemoteDropdown';
import TeacherSelectionDialog from 'public/components/Selection/TeacherSelectionDialog';


const parseCode = v => (v ? v.value : null);
const validate = (values) => {
  const errors = {};
  // console.log(values);
  if (!values.name) {
    errors.name = 'Required';
  }
  errors.year = isAcademicYear(values?.year);
  if (!values.is_master && !values.department_code) {
    errors.department_code = 'Required';
  }

  return errors;
};

class ActivityListFormCard extends Component {
  static propTypes = {
    // redux form
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    // redux connect
    isMaster: PropTypes.bool.isRequired,
    update: PropTypes.func.isRequired,
    model: PropTypes.shape({}).isRequired,
  }

  state = {
    notiMessage: null,
    showTeachers: false,
  }

  onSubmit = async (values) => {
    const { model } = this.props;
    const canUpdate = !model.is_master;
    if (!canUpdate) {
      return;
    }
    try {
      await this.props.update(values);
      this.setState({ notiMessage: 'Details updated.' });
    } catch (e) {
      handle422(e.response);
    }
  }


  render() {
    const { model, handleSubmit, submitting, pristine, isMaster, teachers, teachers_id, change } = this.props;
    const { notiMessage, showTeachers } = this.state;
    const canUpdate = !model.is_master;
    return (
      <div>
        <NotificationSnackbar
          open={Boolean(notiMessage)}
          message={notiMessage}
          onClose={() => this.setState({ notiMessage: null })}
        />
        <TeacherSelectionDialog
          multiple
          open={canUpdate && showTeachers}
          onClose={() => this.setState({ showTeachers: false })}
          onFinish={(ts) => {
            change('teachers_id', union(teachers_id, ts.map(t => t.id)));
            change('teachers', unionBy(teachers, ts, t => t.id));
            this.setState({ showTeachers: false });
          }}
        />
        <FormCard>
          <Toolbar
            title="Details"
          />
          <CardContent>
            <FormCardSection>
              <FormCardSectionFieldRow title="ID">
                <Typography variant="body1">{model.id}</Typography>
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Name">
                <Field name="name" component={TextField} fullWidth disabled={!canUpdate} />
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Academic Year">
                <Field
                  name="year"
                  component={TextField}
                  multiline
                  fullWidth
                  InputProps={{
                    inputComponent: AcademicInput,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={!canUpdate}
                />
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Course">
                <Field
                  name="course_id"
                  component={RemoteDropdown}
                  apiUrl="/staff/values/courses"
                  labelProperty="label"
                  valueProperty="id"
                  parse={parseCode}
                  fullWidth
                  disabled={!canUpdate}
                />
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Department">
                <Field
                  name="is_master"
                  component={BooleanCheckbox}
                  label="Master Template"
                  fullWidth
                  disabled
                />
                {
                  isMaster ?
                    null :
                    <Field
                      name="department_code"
                      component={UserDepartmentDropdown}
                      fullWidth
                      disabled={!canUpdate}
                    />
                }
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Teachers">
                {teachers && teachers.length > 0 ?
                  teachers.map(teacher => (
                    <Typography variant="body1" key={teacher.id}>
                      {teacher.name} ({teacher.email})
                      {canUpdate ?
                        <IconButton
                          onClick={() => {
                            change('teachers', teachers.filter(t => t.id !== teacher.id));
                            change('teachers_id', teachers_id.filter(id => id !== teacher.id));
                          }}
                        >
                          <Delete />
                        </IconButton>
                        : null
                      }
                    </Typography>
                  ))
                  : <Typography variant="body1" gutterBottom >No Associated Teachers</Typography>
                }
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={!canUpdate}
                  onClick={() => this.setState({ showTeachers: true })}
                >
                  Add
                </Button>
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Protocol">
                <Field
                  name="protocol_code"
                  component={RemoteDropdown}
                  apiUrl="/staff/values/protocols"
                  parse={parseCode}
                  disabled={!canUpdate}
                  fullWidth
                />
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Remarks">
                <Field
                  name="remarks"
                  component={TextField}
                  multiline
                  fullWidth
                  disabled={!canUpdate}
                />
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Status">
                <Field
                  name="active"
                  component={TextField}
                  select
                  SelectProps={{ native: true }}
                  disabled={!canUpdate}
                >
                  <option value="1">ACTIVE</option>
                  <option value="0">INACTIVE</option>
                </Field>
              </FormCardSectionFieldRow>
            </FormCardSection>
            <FormCardSection>
              <FormCardSectionFieldRow title="Created At">
                <Typography variant="body1">{moment(model.created_at).format()} by {model.created_by}</Typography>
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Last Updated At">
                <Typography variant="body1">{moment(model.updated_at).format()} by {model.updated_by}</Typography>
              </FormCardSectionFieldRow>
            </FormCardSection>
          </CardContent>
          <CardActions>
            <FormCardContent>
              <Button variant="contained" color="primary" onClick={handleSubmit(this.onSubmit)} disabled={!canUpdate || pristine || submitting}>SAVE</Button>
              <Button color="primary" onClick={this.props.reset} disabled={pristine}>RESET</Button>
            </FormCardContent>
          </CardActions>
        </FormCard>
      </div>
    );
  }
}

export default reduxForm({
  form: 'ActivityForm',
  enableReinitialize: true,
  validate,
})(ActivityListFormCard);
