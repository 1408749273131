import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';

import FilterList from '@material-ui/icons/FilterList';

import Pagination from 'common/components/Pagination';
import PaginationText from 'common/components/PaginationText';

import SurveyCard from 'public/components/SurveyCard';

import SurveySearchForm from './SurveySearchForm';
import SurveySortButton from './SurveySortButton';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    maxWidth: '100%',
  },
  container: {
    flex: 1,
    maxWidth: 1280,
  },
  headingRow: {
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    flex: 1,
  },
  surveyRow: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  surveyDone: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(0.5),
  },
  surveyName: {
    flex: 2,
  },
  surveyDate: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  card: {
    marginBottom: theme.spacing(2),
  },
});

class SurveyListView extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    searchFormSubmit: PropTypes.func.isRequired,
    // sortChange: PropTypes.func.isRequired,
    pageChange: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }

  static defaultProps = {
    data: [],
  }

  state = {
    searchOpen: true,
  }

  componentDidMount() {
    const { searchFormSubmit } = this.props;
    searchFormSubmit({})
      .catch(console.log);
  }

  renderSurvey() {
    const { init, data } = this.props;
    if (!init) {
      return (<CircularProgress size={72} />);
    }
    if (data && data.length) {
      return data.map(survey => <SurveyCard key={survey.id} survey={survey} />);
    }
    return (<Typography variant="body2">No Survey / Evaluation are available now.</Typography>);
  }

  render() {
    const { classes, page, totalPages, numberOfElements, totalElements, pageSize, pageChange, sort, form, searchFormSubmit, sortChange } = this.props;
    const { searchOpen } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.headingRow}>
            <Typography className={classes.heading} variant="h4" component="h1" gutterBottom>Survey / Evaluation</Typography>
            <div>
              <IconButton onClick={() => this.setState({ searchOpen: !searchOpen })}><FilterList /></IconButton>
            </div>
          </div>
          <Collapse in={searchOpen}>
            <SurveySearchForm
              initialValues={form}
              onSubmit={searchFormSubmit}
              onClear={() => searchFormSubmit({})}
              InputProps={{
                color: 'primary',
              }}
            />
          </Collapse>
          <div>
            <SurveySortButton sortChange={sortChange} sort={sort} />
          </div>
          {this.renderSurvey()}
          <PaginationText page={page} numberOfElements={numberOfElements} totalElements={totalElements} pageSize={pageSize} gutterBottom />
          <Pagination page={page} totalPages={totalPages} onPageLinkClick={pageChange} />
        </div >
      </div >
    );
  }
}

export default withStyles(styles)(SurveyListView);
