import { connect } from 'react-redux';
import actions from 'public/redux/StudentSurvey/actions';
import SurveyView from './SurveyView';

const mapStateToProps = ({ StudentSurveyForm }) => StudentSurveyForm;

const mapDispatchToProps = {
  fetchOne: actions.fetchOne,
  clearOne: actions.clearOne,
};

// connect with redux
export default connect(mapStateToProps, mapDispatchToProps)(SurveyView);
