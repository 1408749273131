import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Search from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
// import Button from '@material-ui/core/Button';

import PageLoading from 'common/components/PageLoading';
import Toolbar from 'common/components/ToolBar';
import NotificationSnackbar from 'common/components/NotificationSnackbar';

import UpdateFormCard from './Detail/QuestionnaireFormCard';
import SectionListing from './Detail/SectionListingContainer';
import { Fab } from '@material-ui/core';


const styles = theme => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  fab: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    [theme.breakpoints.up('lg')]: {
      bottom: 40,
      right: '5%',
    },
  },
});

const QuestionnaireView = ({
  classes,
  match,
  model = {},
  init,
  clearOne,
  fetchOne
}) => {
  const [notiMessage, setNotiMessage] = React.useState(null);
  const [formDirty, setFormDirty] = React.useState(false);
  const [sectionsDirty, setSectionsDirty] = React.useState(false);
  React.useEffect(() => {
    if ((Number(match.params.id) !== Number(model.id))) {
      clearOne()
        .then(() => fetchOne(match.params.id));
    }
  }, [match, model]);
  if (!init) {
    return (<PageLoading />);
  }
  return (
    <div>
      <Toolbar
        showBackButton
        title={`Questionnaire: ${model?.name}`}
        color="default"
      />
      {/* <Prompt when={formDirty || sectionsDirty} message="You have unsaved changes. Are you sure you want to leave?" /> */}
      <div className={classes.wrapper}>
        <NotificationSnackbar
          open={Boolean(notiMessage)}
          message={notiMessage}
          onClose={() => setNotiMessage(null)}
        />
        <UpdateFormCard onSubmitSuccess={() => setNotiMessage('Details updated.')} setFormDirty={setFormDirty} />
        <SectionListing onSubmitSuccess={() => setNotiMessage('Sections updated.')} setSectionsDirty={setSectionsDirty} />
        <div className={classes.fab}>
          <Tooltip title="Preview">
            <Fab
              color="primary"
              aria-label="Preview"
              component={Link}
              to={`/staff/questionnaire/preview/${model.id}`}
              target="_blank"
            >
              <Search />
            </Fab>
          </Tooltip>
        </div>
      </div>
    </div >
  );
}

export default withRouter(withStyles(styles)(QuestionnaireView));
