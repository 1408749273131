import React from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import TextField from 'common/components/form/TextField';

import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Flag from '@material-ui/icons/Flag';
import { makeStyles } from '@material-ui/core';

import { handle422 } from 'common/helpers/ReduxFormHelper';
import ConfirmButton from 'common/components/ConfirmButton';
import { FormCard, FormCardSection, FormCardSectionFieldRow } from 'common/components/FormCard';
import Toolbar from 'common/components/ToolBar';
import RichTextMarkdown from 'common/components/form/RichTextMarkdown';
import AcademicInput from 'common/components/form/AcademicInput';
import { isAcademicYear } from 'common/helpers/validation';
import UserDepartmentDropdown from 'public/components/form/UserDepartmentDropdown';
import QuestionnairesActions from 'public/redux/Questionnaires/actions';

const useStyles = makeStyles(theme => ({
  btnGroup: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Required';
  }
  errors.year = isAcademicYear(values?.year);
  if (!values.department_code) {
    errors.department_code = 'Required';
  }
  return errors;
};


const renderStatus = (status) => {
  switch (Number(status)) {
    case 10:
      return 'Active';
    case 5:
      return 'Submitted';
    case 0:
    default:
      return 'Draft';
  }
};

const QuestionnaireForm = reduxForm({
  form: 'QuestionnaireUpdateForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(({
  submitting,
  pristine,
  handleSubmit,
  onSubmit,
  reset,
  model = {},
  canSubmit = false,
  canUpdate = false,
  setFormDirty,
}) => {
  const classes = useStyles();
  React.useEffect(() => {
    setFormDirty(!pristine);
  }, [pristine]);
  return (
    <>
      <CardContent>
        <FormCardSection>
          <FormCardSectionFieldRow title="ID">
            <Typography variant="body1">{model?.id}</Typography>
          </FormCardSectionFieldRow>
          <FormCardSectionFieldRow title="Academic Year">
            <Field
              className={classes.field}
              name="year"
              component={TextField}
              multiline
              fullWidth
              InputProps={{
                inputComponent: AcademicInput,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!canUpdate}
            />
          </FormCardSectionFieldRow>
          <FormCardSectionFieldRow title="Name">
            <Field name="name" component={TextField} fullWidth disabled={!canUpdate} />
          </FormCardSectionFieldRow>
          <FormCardSectionFieldRow title="Department">
            {
              canUpdate ?
                <Field
                  name="department_code"
                  component={UserDepartmentDropdown}
                  placeholder="Select..."
                  fullWidth
                />
                :
                <Typography>{model?.department_code == null ? <strong>Master Template</strong> : model?.department_code}</Typography>
            }

          </FormCardSectionFieldRow>
          <FormCardSectionFieldRow title="Status">
            <Typography>{renderStatus(model.status)}</Typography>
          </FormCardSectionFieldRow>
        </FormCardSection>
        <FormCardSection>
          <FormCardSectionFieldRow title="Created At">
            <Typography variant="body1">{moment(model?.created_at).format()} by {model?.created_by}</Typography>
          </FormCardSectionFieldRow>
          <FormCardSectionFieldRow title="Submitted At">
            <Typography variant="body1">{model?.submitted_at ? moment(model?.submitted_at).format() : null} by {model?.submitted_by}</Typography>
          </FormCardSectionFieldRow>
          <FormCardSectionFieldRow title="Last Updated At">
            <Typography variant="body1">{moment(model?.updated_at).format()} by {model?.updated_by}</Typography>
          </FormCardSectionFieldRow>
        </FormCardSection>
        <FormCardSection>
          <FormCardSectionFieldRow title="Leading Text">
            <Field
              name="intro"
              component={RichTextMarkdown}
              fullWidth
              disabled={!canUpdate}
            />
          </FormCardSectionFieldRow>
        </FormCardSection>
      </CardContent>
      <div className={classes.btnGroup}>
        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)} disabled={!canUpdate || pristine || submitting}>SAVE</Button>
        <Button color="primary" onClick={reset} disabled={pristine}>RESET</Button>
      </div>
      <CardContent>
        <div>
          <ConfirmButton
            disabled={Boolean(!canSubmit || !pristine || submitting)}
            color="secondary"
            variant="contained"
            message="You cannot modify the questionnaire after Submission. Confirm to submit this questionnaire?"
            onConfirm={() => onSubmit({ id: model.id, status: 5 })}
          >
            <Flag />
            Submit For Review
          </ConfirmButton>
          {
            !canSubmit ?
              <Typography>There are questions that is not active. Please submit draft question to admin and wait for approval.</Typography>
              : null
          }
        </div>
      </CardContent>
    </>);
});


const mapStateToProps = state => ({
  ...state.QuestionnaireForm,
  QSections: state.QSections,
});

const QuestionnaireFormCard = ({
  setFormDirty
}) => {
  const dispatch = useDispatch();
  const { model, QSections } = useSelector(mapStateToProps);

  const canUpdate = React.useMemo(() => {
    return (model.status === 0) && Boolean(model.department_code)
  }, [model]);
  const canSubmit = React.useMemo(() => {
    const { init, data } = QSections;
    // console.log(init, data);
    if (!init) { // not yet init
      return false;
    }
    if (!canUpdate) {
      return false;
    }
    return !data.find((section) => {
      const { items } = section;
      return Boolean(items.find(item => (item.type === 'Q') && (item.question) && (!item.question.item_no)));
    });
  }, [canUpdate, QSections]);

  const onSubmit = React.useCallback(async (values) => {
    if (!canUpdate) {
      throw new Exception('Cannot Update'); // throw exception to stop everyting
    }
    try {
      await dispatch(QuestionnairesActions.update(values));
    } catch (e) {
      handle422(e.response);
    }
  }, [canUpdate, dispatch]);

  return (
    <FormCard>
      <Toolbar title="Details" />
      <QuestionnaireForm
        initialValues={model}
        model={model}
        canUpdate={canUpdate}
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        setFormDirty={setFormDirty}
      />
    </FormCard>
  );
}

export default QuestionnaireFormCard;
