import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import TextField from 'common/components/form/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import SearchFormBase from 'common/components/SearchFormBase';
import UserDepartmentDropdown from 'public/components/form/UserDepartmentDropdown';

const styles = theme => ({
  field: {
    marginRight: theme.spacing(2),
    minWidth: 150,
  },
});

const EMPTY_FORM = {
  id: '',
  name: '',
  year: '',
  is_master: null,
  department_code: '',
  status: null,
};

class QuestionnaireSearchForm extends Component { // eslint-disable-line
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
  }

  static defaultProps = {
    onSubmit: () => { },
  }

  constructor(props) {
    super(props);
    this.clearForm = this.clearForm.bind(this);
  }

  async clearForm(event = {}) {
    if (event.preventDefault) {
      event.preventDefault();
    }
    const { onSubmit, reset } = this.props;
    await onSubmit(EMPTY_FORM);
    reset();
  }

  render() {
    const { classes, handleSubmit, onSubmit } = this.props;
    return (
      <SearchFormBase onSubmit={handleSubmit(onSubmit)} onClear={this.clearForm}>
        <Field
          name="id"
          className={classes.field}
          label="ID"
          component={TextField}
        />
        <Field
          name="name"
          className={classes.field}
          label="Name"
          component={TextField}
        />
        <Field
          name="year"
          className={classes.field}
          label="Academic Year"
          component={TextField}
        />
        <Field
          name="is_master"
          className={classes.field}
          label="Master Template"
          component={TextField}
          select
          SelectProps={{ native: true }}
        >
          <option value="" />
          <option value="1">Yes</option>
          <option value="0">No</option>
        </Field>
        <div className={classes.field}>
          <InputLabel>Department</InputLabel>
          <Field
            name="department_code"
            component={UserDepartmentDropdown}
            placeholder="Select..."
            fullWidth
          />
        </div>
        <Field
          name="status"
          className={classes.field}
          label="Status"
          component={TextField}
          select
          SelectProps={{ native: true }}
        >
          <option value="" />
          <option value="10">Active</option>
          <option value="5">Submitted</option>
          <option value="0">Draft</option>
        </Field>
        <Field
          name="by_me"
          className={classes.field}
          label="Created By"
          component={TextField}
          select
          SelectProps={{ native: true }}
        >
          <option value="" />
          <option value="1">Me only</option>
        </Field>
      </SearchFormBase >
    );
  }
}

export default reduxForm({
  form: 'QuestionnaireSearchForm',
  enableReinitialize: true,
})(withStyles(styles)(QuestionnaireSearchForm));
