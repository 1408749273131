import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
  },
  inherit: {
    color: 'inherit',
  },
  primary: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.primary.main,
  },
}));

const MyLink = React.forwardRef(({
  className = null,
  color = 'inherit',
  ...props
}, ref) => {
  const classes = useStyles();
  return (
    <Link
      className={clsx(classes.root, {
        [classes.inherit]: color === 'inherit',
        [classes.primary]: color === 'primary',
        [classes.secondary]: color === 'secondary',
      }, className)}
      ref={ref}
      {...props}
    />
  );
});

MyLink.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['inherit', 'primary', 'secondary']),
};

export default MyLink;
