import * as actions from './types';
import service from './service';

const creators = {
  fetchPreview: id =>
    async (dispatch) => {
      const response = await service.fetchOne(id, 'expand=sections.items');
      dispatch({ type: actions.FETCH_PREVIEW, model: response.data });
      return response.data;
    },

  clearPreview: () =>
    async dispatch => dispatch({ type: actions.CLEAR_PREVIEW }),
};

export default creators;
