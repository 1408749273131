import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  pageCurrent: {
    color: theme.palette.primary.textConstrast,
    backgroundColor: theme.palette.primary.main,
    minWidth: 48,
    marginRight: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  pageDefault: {
    minWidth: 48,
    marginRight: theme.spacing(0.5),
  },
});

class Pagination extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    page: PropTypes.number,
    totalPages: PropTypes.number,
    onPageLinkClick: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    onPageLinkClick: () => { },
    totalPages: 0,
    page: 0,
    className: null,
  }

  getPages = () => {
    const { totalPages, page } = this.props;

    const pages = [];
    if (totalPages > 0) {
      pages.push({ page: 0, text: '|<<', current: (page === 0), tooltip: 'First Page' });
    }

    if (totalPages < 11) {
      // small size
      for (let i = 0; i < totalPages; i += 1) {
        pages.push({ page: i, text: `${i + 1}`, current: (page === i), tooltip: `Page ${i + 1}` });
      }
    } else if (page < 5) {
      for (let i = 0; i < 10; i += 1) {
        pages.push({ page: i, text: `${i + 1}`, current: (page === i), tooltip: `Page ${i + 1}` });
      }
    } else if (page > totalPages - 6) {
      for (let i = (totalPages - 10); i < totalPages; i += 1) {
        pages.push({ page: i, text: `${i + 1}`, current: (page === i), tooltip: `Page ${i + 1}` });
      }
    } else {
      for (let i = (page - 4); i < (page + 6); i += 1) {
        pages.push({ page: i, text: `${i + 1}`, current: (page === i), tooltip: `Page ${i + 1}` });
      }
    }

    if (totalPages > 0) {
      pages.push({ page: totalPages - 1, text: '>>|', current: (page === (totalPages - 1)), tooltip: 'Last Page' });
    }
    return pages;
  }

  createPageButton = p => (
    <Tooltip title={p.tooltip} key={p.text}>
      <span>
        <Button
          onClick={this.wrapPageLinkClick(p.page)}
          className={p.current ? this.props.classes.pageCurrent : this.props.classes.pageDefault}
          variant={p.current ? 'contained' : 'outlined'}
          color={p.current ? 'primary' : 'default'}
          size="small"
          disabled={p.current}
        >
          {p.text}
        </Button>
      </span>
    </Tooltip>);


  wrapPageLinkClick = toPage =>
    (event) => {
      event.preventDefault();

      if (typeof this.props.onPageLinkClick === 'function') {
        this.props.onPageLinkClick(toPage, event);
      }
    };


  render() {
    const pages = this.getPages();
    return (
      <div className={cx(this.props.className)}>
        {pages.map(p => this.createPageButton(p))}
      </div>
    );
  }
}

export default withStyles(styles)(Pagination);
