import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

import Link from 'common/components/Link';

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  surveyRow: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  surveyName: {
    flex: 2,
    paddingBottom: theme.spacing(1),
  },
  surveyDate: {
    flex: 1,
    paddingBottom: theme.spacing(1),
  },
  completed: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
});

const SurveyCard = ({ classes, survey }) => {
  const isOpen = moment().isBefore(moment(survey.end));
  return (
    <Card className={classes.root}>
      <CardContent className={classes.surveyRow}>
        <div className={classes.surveyName}>
          <Typography variant="h6">
            {isOpen ? <Link to={`/survey/${survey.id}`} color="primary" >{survey.name}</Link> : survey.name}
          </Typography>
          <Typography>
            Activity: {survey.class.name}
          </Typography>
          {survey.answered ?
            <Chip label="Completed" className={classes.completed} />
            : null
          }
        </div>
        <div className={classes.surveyDate}>
          <Typography>Close at : {survey.end}</Typography>
          {!isOpen ? <Chip label="Closed" /> : null}
        </div>
      </CardContent>
    </Card >
  );
};

export default withStyles(styles)(SurveyCard);
