import buildQuery from 'common/helpers/buildQuery';
import axios from 'public/helpers/MyAxios';
import Config from 'public/constants/Config';

const service = {
  fetchList: (search) => {
    const qs = buildQuery(search);
    return axios.get(`${Config.baseUrl}/coordinator/activities?${qs}`);
  },
  fetchOne: id => axios.get(`${Config.baseUrl}/coordinator/activities/${id}?expand=teachers,protocol,department`),
};

export default service;
