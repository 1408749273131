import * as Types from './types';

const INITIAL_STATE = {
  user: null,
  redirectTo: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOGIN_USER:
      return {
        ...state,
        user: action.user,
      };
    case Types.LOGOUT_USER:
      return {
        ...state,
        user: null,
        redirectTo: null,
      };
    case Types.SET_REDIRECT_TO:
      return {
        ...state,
        redirectTo: action.url,
      };
    default:
      return state;
  }
};
