import ReactGA from 'react-ga';
import { DEV } from 'public/constants/Config';

const MyGA = {
  event: (options) => {
    if (!DEV) {
      ReactGA.event(options);
    }
  },
  modalview: (modalName) => {
    if (!DEV) {
      ReactGA.modalview(modalName);
    }
  },
  timing: (options) => {
    if (!DEV) {
      ReactGA.timing(options);
    }
  },
};
export default MyGA;

