import { connect } from 'react-redux';
import actions from 'public/redux/HomeSurvey/actions';

import HomeView from './HomeView';

const mapStateToProps = ({ HomeSurvey, account }) => ({
  account,
  HomeSurvey,
});
const mapDispatchToProps = {
  searchFormSubmit: actions.searchFormSubmit,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeView);

