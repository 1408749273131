import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const Alert = ({
  message, open, onClose, disableBackdropClick,
  ...DialogProps
}) =>
  (
    <Dialog open={open} onClose={onClose} disableBackdropClick={disableBackdropClick} {...DialogProps}>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">OK</Button>
      </DialogActions>
    </Dialog >
  );

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  disableBackdropClick: PropTypes.bool,
};
Alert.defaultProps = {
  disableBackdropClick: true,
};

export default Alert;
