import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import copyToClipboard from 'copy-to-clipboard';
import fileDownload from 'js-file-download';

import {
  TableContainer,
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
  Collapse,
} from '@material-ui/core';

import Done from '@material-ui/icons/Done';
import Remove from '@material-ui/icons/Remove';
import Delete from '@material-ui/icons/Delete';
import AddBox from '@material-ui/icons/AddBox';
import ContentCopy from '@material-ui/icons/FileCopy';
import GetApp from '@material-ui/icons/GetApp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';

import { FormCard } from 'common/components/FormCard';
import { EnhancedTable } from 'common/components/tables';
import ToolBar from 'common/components/ToolBar';
import ConfirmButton from 'common/components/ConfirmButton';

import axios from 'public/helpers/MyAxios';
import ListActions from 'public/redux/OnlineSurveys/tokens/actions';

import LoadingXlsxDialog from './LoadingXlsxDialog';
import GenerateNewLinksDialog from './GenerateNewLinksDialog';

const columnData = [
  { id: 'token', numeric: false, padding: 'checkbox', label: 'Link', sortable: true },
  { id: 'completed', numeric: false, padding: 'checkbox', label: '', sortable: false },
  { id: '', numeric: false, padding: 'checkbox', label: '', sortable: false },
];

const mapStateToProps = ({ OnlineSurveyTokens }) => OnlineSurveyTokens;

const TokenRow = ({
  rowData,
}) => {
  const dispatch = useDispatch();
  return (
    <TableRow hover tabIndex={-1}>
      <TableCell padding="checkbox"><a href={rowData.url} target="_blank" rel="noreferrer noopener">{rowData.url}</a></TableCell>
      <TableCell padding="checkbox">
        {rowData.completed ? <Tooltip title="Done."><Done /></Tooltip> : <Tooltip title="Not yet done."><Remove /></Tooltip>}
      </TableCell>
      <TableCell padding="checkbox">
        <Tooltip title="Copy to Clipboard">
          <IconButton onClick={() => copyToClipboard(rowData.url)} >
            <ContentCopy />
          </IconButton>
        </Tooltip>
        <Tooltip title="Remove Link">
          <ConfirmButton
            ButtonComponent={IconButton}
            onConfirm={async () => {
              try {
                await axios.delete(axios.addApiUrl(`/staff/online-survey/delete-token?id=${rowData.id}`));
                dispatch(ListActions.searchFormSubmit()).catch(console.log);
              } catch (e) {
                dispatch(CommonActions.setSnackbarMessage(`Failed: ${(e.response?.data?.message || e)}`));
              }
            }}
            message="Confirm to remove this link? (Completed CTE will not be removed.)"
          >
            <Delete />
          </ConfirmButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
const renderRow = ({ rowData, ...props }) => <TokenRow key={`${rowData.id}`} rowData={rowData} {...props} />;

const TokenTable = ({ surveyId }) => {
  const dispatch = useDispatch();
  const { page, pageSize, data, totalElements, sort } = useSelector(mapStateToProps);
  const [loadingXlsx, setLoadingXlsx] = React.useState(false);
  const [xlsxProgess, setXlsxProgess] = React.useState(null);
  const [showCreate, setShowCreate] = React.useState(false);
  const [showTable, setShowTable] = React.useState(true);

  const handleShowCreate = React.useCallback(() => setShowCreate(true), []);
  const handleHideCreate = React.useCallback(() => setShowCreate(false), []);

  const handleSortChange = React.useCallback(sort => dispatch(ListActions.sortChange(sort)).catch(console.log), [dispatch]);
  const handlePageChange = React.useCallback(page => dispatch(ListActions.pageChange(page)).catch(console.log), [dispatch]);
  const downloadXlsx = React.useCallback(async () => {
    setLoadingXlsx(true);
    try {
      const response = await axios.get(axios.addApiUrl(`/staff/online-survey/download-token-list?id=${surveyId}`), {
        responseType: 'blob',
        onDownloadProgress: e => setXlsxProgess({ loaded: e.loaded, total: e.total }),
      });
      fileDownload(response.data, `Online_Survey_${surveyId}_TokenList.xlsx`);
    } catch (e) {
      dispatch(CommonActions.setSnackbarMessage(`Error: ${e.message}`))
    } finally {
      setLoadingXlsx(false);
      setXlsxProgess(null);
    }
  }, [dispatch, surveyId]);

  React.useEffect(() => {
    dispatch(ListActions.searchFormSubmit()).catch(console.log);
  }, [dispatch]);

  return (
    <FormCard>
      <LoadingXlsxDialog open={loadingXlsx} progress={xlsxProgess} />
      <ToolBar
        title="Invitation Links"
        rightButton={[
          {
            title: 'Generate new links',
            icon: <AddBox />,
            onClick: handleShowCreate,
          },
          { title: 'Download List', icon: (<GetApp />), onClick: downloadXlsx },
          {
            title: '',
            icon: showTable ? <KeyboardArrowUp /> : <KeyboardArrowDown />,
            onClick: () => setShowTable(!showTable),
          },
        ]}
      />
      <GenerateNewLinksDialog
        open={showCreate}
        onClose={handleHideCreate}
        onSubmit={async values => axios.post(axios.addApiUrl('/staff/online-survey/generate-tokens'), values)}
        onSubmitSuccess={() => {
          handleHideCreate();
          dispatch(ListActions.searchFormSubmit()).catch(console.log);
        }}
        initialValues={{
          id: surveyId,
          count: null,
        }}
      />
      <Collapse in={showTable}>
        <TableContainer>
          <EnhancedTable
            noCheckbox
            totalElements={totalElements}
            pageSize={pageSize}
            page={page}
            sort={sort}
            columnData={columnData}
            data={data}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange}
            renderRow={renderRow}
          />
        </TableContainer>
      </Collapse>
    </FormCard>
  )
};

export default TokenTable;
