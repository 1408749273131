
import pick from 'lodash/pick';
import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import {
  Button,
  Typography,
  CardContent,
  CardActions,
  makeStyles,
} from '@material-ui/core';

import ToolBar from 'common/components/ToolBar';
import TextField from 'common/components/form/TextField';
import { CURRENT_ACADEMIC_YEAR } from 'common/constants/years';
import buildQuery from 'common/helpers/buildQuery';
import { isRequired, isAcademicYear } from 'common/helpers/validation';
import AcademicInput from 'common/components/form/AcademicInput';
import { FormCard, FormCardSection, FormCardContent, FormCardSectionFieldRow } from 'common/components/FormCard';

import LoadingFileDialog from 'public/components/common/LoadingFileDialog';
import CommonActions from 'public/redux/common/actions';
import axios from 'public/helpers/MyAxios';
import TeacherSelectionDialog from 'public/components/Selection/TeacherSelectionDialog';

import { handle422 } from 'common/helpers/ReduxFormHelper';
import CoordDepartmentDropdown from './CoordDepartmentDropdown';


// const parseCode = v => (v ? v.value : null);

const RequiredValidate = [isRequired];
const AcademicYearValidate = [isRequired, isAcademicYear];

const NumOfYearsValidate = [isRequired, (val) => (Number(val) < 1 || Number(val)) > 5 ? 'Must between 1 and 5' : ''];

const useStyles = makeStyles(theme => ({
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));


const selector = formValueSelector('CoordinatorTeacherYearlyForm');

const useDepartmentCode = () => {
  return useSelector(({ account }) => {
    if (account?.user?.staff?.staffCoordDepartments) {
      return account?.user?.staff?.staffCoordDepartments.map(v => v.department_code)?.[0] || '';
    }
    return '';
  });
}

const CoordinatorTeacherYearlyForm = ({
  handleSubmit,
  submitting,
  change,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [progress, setProgress] = React.useState(null);
  const [showTeachers, setShowTeachers] = React.useState(false);
  const teacher = useSelector(state => selector(state, 'teacher'));
  const department_code = useDepartmentCode();

  const teacherName = teacher?.name || 'Teacher';
  const downloadFile = React.useCallback(async (values) => {
    try {
      const response = await axios.get(axios.addApiUrl(`/coordinator/report/teacher-yearly?${buildQuery({
        form: pick(values, ['teacher_id', 'year', 'department_code', 'num_of_years'])
      })}`), {
        responseType: 'blob',
        onDownloadProgress: e => setProgress({ loaded: e.loaded, total: e.total }),
      });
      fileDownload(response.data, `Teacher_${teacherName}.xlsx`);
      setProgress(null);
    } catch (e) {
      if (e.response?.status === 422 && e.response?.data?.type === 'application/json') {
        const promise = new Promise((resolve) => {
          const fr = new FileReader();
          fr.onload = () => {
            const errors = JSON.parse(fr.result);
            resolve({ status: 422, data: errors });
          };
          fr.readAsText(e.response.data);
        });
        const r = await promise;
        handle422(r);
      } else {
        dispatch(CommonActions.setSnackbarMessage(`Error: ${e.message}`));
      }
    }
  }, [dispatch, teacherName]);

  const onSubmit = handleSubmit(downloadFile);
  return (
    <div>
      <ToolBar title="Teacher’s CTE Summary Report" color="default" />
      <LoadingFileDialog open={submitting} progress={progress} />
      <TeacherSelectionDialog
        open={showTeachers}
        onClose={() => setShowTeachers(false)}
        initialValues={{ department_code }}
        onFinish={(t) => {
          // console.log(t);
          change('teacher_id', t.id);
          change('teacher', t);
          // change('department_code', t?.departments?.[0]?.code);
          setShowTeachers(false);
        }}
      />
      <form onSubmit={onSubmit}>
        <FormCard>
          <CardContent>
            <FormCardSection>
              <FormCardSectionFieldRow title="Teacher">
                <Typography variant="subtitle1" gutterBottom onClick={() => setShowTeachers(true)}>
                  {teacher ? `${teacher.name}(${teacher.email})` : 'Please select Teacher.'}
                </Typography>
                <Field
                  component={TextField}
                  name="teacher_id"
                  InputProps={{
                    type: 'hidden'
                  }}
                  validate={RequiredValidate}
                  fullWidth
                />
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => setShowTeachers(true)}
                >
                  Select
                </Button>
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Academic Year">
                <Field
                  className={classes.field}
                  name="year"
                  component={TextField}
                  fullWidth
                  InputProps={{
                    inputComponent: AcademicInput,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  validate={AcademicYearValidate}
                />
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Number of Years">
                <Field
                  className={classes.field}
                  name="num_of_years"
                  component={TextField}
                  fullWidth
                  validate={NumOfYearsValidate}
                />
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Deparment">
                <Field
                  className={classes.field}
                  name="department_code"
                  placeholder=" "
                  component={CoordDepartmentDropdown}
                  fullWidth
                  validate={RequiredValidate}
                />
              </FormCardSectionFieldRow>
            </FormCardSection>
          </CardContent>
          <CardActions>
            <FormCardContent>
              <Button type="submit" variant="contained" color="primary">Download Report</Button>
            </FormCardContent>
          </CardActions>
        </FormCard>
      </form>
    </div>
  )
}


export default reduxForm({
  form: 'CoordinatorTeacherYearlyForm',
  initialValues: {
    year: CURRENT_ACADEMIC_YEAR,
    num_of_years: '5',
  },
})(CoordinatorTeacherYearlyForm);
