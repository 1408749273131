import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  formCard: {
    maxWidth: 1024,
    margin: '0 auto',
  },
  formCardLarge: {
    maxWidth: 1280,
  }
});

const FormCard = (props) => {
  const { classes, size, children } = props;
  return (
    <Card className={clsx(classes.formCard, { [classes.formCardLarge]: size === 'large' })}>
      {children}
    </Card>
  );
};
FormCard.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.any, // eslint-disable-line
};

export default withStyles(styles)(FormCard);
