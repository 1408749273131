import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DialogContent,
  DialogActions,
  Collapse,
  Button,
  TableContainer,
  TableRow,
  TableCell,
  Checkbox,
  Tooltip,
  makeStyles,
} from '@material-ui/core';

import { EnhancedTable } from 'common/components/tables';
import Loading from 'common/components/Loading';

import ListActions from 'public/redux/Teachers/actions';
import TeacherSearchForm from './TeacherSelectionDialogSearchForm';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    paddingBottom: 0,
  },
  dialogContent: {
    position: 'relative',
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const columnData = [
  { id: 'email', numeric: false, disablePadding: false, label: 'Email', sortable: true },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: true },
  { id: 'departments', numeric: false, disablePadding: false, label: 'Departments', sortable: false },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status', sortable: true },
];

const renderDepartments = (row) => {
  if (row.is_external) {
    return (<strong>External</strong>);
  }
  const { departments } = row;
  if (departments && departments.length > 0) {
    return (
      <div>
        {departments.map(d => (
          <div key={d.code}>
            <Tooltip placement="bottom" title={d.name}><div>{d.code}</div></Tooltip>
          </div>))}
      </div>
    );
  }
  return null;
}


const TeacherRow = ({
  rowData,
  selectedRows,
  setSelectedRows,
}) => {
  const isSelected = id => (selectedRows.findIndex(ele => ele.id === id) >= 0);
  const rowSelected = isSelected(rowData.id);
  const handleRowClick = () => {
    if (rowSelected) {
      setSelectedRows(selectedRows.filter(ele => ele.id !== rowData.id));
    } else {
      setSelectedRows(selectedRows.concat([rowData]));
    }
  };

  return (
    <TableRow
      hover
      tabIndex={-1}
      selected={rowSelected}
      aria-checked={rowSelected}
      onClick={handleRowClick}
    >
      <TableCell padding="checkbox">
        <Checkbox color="primary" checked={rowSelected} onClick={handleRowClick} />
      </TableCell>
      <TableCell>{rowData.email}</TableCell>
      <TableCell>{rowData.name}</TableCell>
      <TableCell>{renderDepartments(rowData)}</TableCell>
      <TableCell>{Number(rowData.status) === 10 ? 'Active' : 'Inactive'}</TableCell>
    </TableRow>
  );
}
const renderRow = ({ rowData, ...props }) => <TeacherRow key={rowData.id} {...props} rowData={rowData} />;


const mapStateToProps = ({ Teachers }) => Teachers;
const MultipleTeacherSelectionDialogContent = ({
  onClose,
  onFinish,
  formOpen,
  initialValues = {},
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState([]);
  const { page, pageSize, data, totalElements, sort, form, init, submitting } = useSelector(mapStateToProps);
  const handleFinish = React.useCallback(() => onFinish(selected), [onFinish, selected]);
  const handleSearch = React.useCallback((values) => dispatch(ListActions.searchFormSubmit(values)).catch(console.log), [dispatch]);
  const handleSortChange = React.useCallback(sort => dispatch(ListActions.sortChange(sort)).catch(console.log), [dispatch]);
  const handlePageChange = React.useCallback(page => dispatch(ListActions.pageChange(page)).catch(console.log), [dispatch]);

  React.useEffect(() => {
    if (!init && !submitting) {
      dispatch(ListActions.searchFormSubmit(initialValues)).catch(console.log);
    }
  }, [dispatch, init, submitting, initialValues]);

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Collapse in={formOpen}>
          <TeacherSearchForm initialValues={form} onSubmit={handleSearch} />
        </Collapse>
        <TableContainer>
          <EnhancedTable
            totalElements={totalElements}
            pageSize={pageSize}
            page={page}
            sort={sort}
            columnData={columnData}
            data={data}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange}
            renderRow={renderRow}
            onRowsSelected={setSelected}
          />
        </TableContainer>
        <Loading loading={submitting} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFinish}
          disabled={selected?.length < 1}
        >
          Select {selected.length} Teacher{selected.length > 1 ? 's' : null}
        </Button>
      </DialogActions>
    </>
  );
};

export default MultipleTeacherSelectionDialogContent;
