import React from 'react';
import {
  CircularProgress,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  loading: {
    position: 'absolute',
    right: 8,
    top: 8,
    zIndex: 101,
  },
}));

const Loading = ({ loading = false }) => {
  const classes = useStyles();
  if (!loading) {
    return null;
  }
  return (<CircularProgress size={24} color="primary" className={classes.loading} />);
}

export default Loading;