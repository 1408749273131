import { connect } from 'react-redux';
import actions from 'public/redux/Coordinator/OnlineSurveys/actions';
import OnlineSurveyView from './OnlineSurveyView';


const mapStateToProps = ({ CoordinatorOnlineSurveyForm }) => CoordinatorOnlineSurveyForm;
const mapDispatchToProps = {
  fetchOne: actions.fetchOne,
  clearOne: actions.clearOne,
};

// connect with redux
export default connect(mapStateToProps, mapDispatchToProps)(OnlineSurveyView);
