import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import TextField from 'common/components/form/TextField';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { handle422 } from 'common/helpers/ReduxFormHelper';

import Config from 'public/constants/Config';

import OnePassLogo from 'resources/onepass-logo-ENG.jpg';
import MedLogo from 'resources/cu_medi_logo_landscape.png';
import CuhkLogo from 'resources/cuhk_logo.png';
import CadsLogo from 'resources/cads-logo.gif';

import { Tooltip } from '@material-ui/core';

const RequiredValidator = (value) => {
  if (!value) {
    return 'Required.';
  }
  return undefined;
};

const styles = theme => ({
  root: {
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
  gutterBottom: {
    marginBottom: theme.spacing(1),
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  logoWrapper: {
    textAlign: 'center',
  },
  logo: {
    maxWidth: '100%',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
    },
  },
  loginBox: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    flex: 1,
  },
  onePassLogoBox: {
  },
  spacer: {
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      flex: 0,
    },
  },
  copyright: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.main,
  },
  cadsBox: {
    marginTop: theme.spacing(2),
  },
  cadsLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
  },
  cadsLogo: {
    display: 'block',
  },
});

class LoginView extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    // reduxForm
    handleSubmit: PropTypes.func.isRequired,
    // reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    redirectTo: PropTypes.string,
    // connected
    login: PropTypes.func.isRequired,
    setRedirectTo: PropTypes.func.isRequired,
    history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  }

  static defaultProps = {
    redirectTo: null,
  }

  async handleLoginSubmit(values) {
    try {
      await this.props.login(values);
      if (this.props.redirectTo) {
        this.props.history.replace(this.props.redirectTo);
        this.props.setRedirectTo(null);
      }
    } catch (e) {
      handle422(e.response);
      // console.log(e);
    }
  }

  renderLoginForm() {
    const { handleSubmit, submitting, classes } = this.props;
    const onSubmit = handleSubmit(v => this.handleLoginSubmit(v));
    return [
      <Typography key="text">(The Login Form will be removed when OnePass is ready)</Typography>,
      <form key="form" onSubmit={onSubmit}>
        <Field
          name="username"
          fullWidth
          required
          label="Username"
          component={TextField}
          validate={[RequiredValidator]}
          className={classes.gutterBottom}
        />
        <Field
          name="password"
          fullWidth
          required
          type="password"
          label="Password"
          component={TextField}
          validate={[RequiredValidator]}
          className={classes.gutterBottom}
        />
        <div>
          <Button
            variant="contained"
            onClick={onSubmit}
            disabled={submitting}
            color="primary"
            size="large"
            type="submit"
          >
            Login
          </Button>
        </div>
      </form>,
    ];
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root} >
        <div className={classes.spacer} />
        <div className={classes.logoWrapper}>
          <img src={CuhkLogo} alt="The Chinese University of Hong Kong" className={classes.logo} />
          <img src={MedLogo} alt="CUHK Faculty of Medicine" className={classes.logo} />
        </div>
        <div className={classes.loginBox}>
          <div className={cx(classes.heading, classes.paper)}>
            <Typography variant="headline" color="inherit">Course and Teaching Evaluation System</Typography>
          </div>
          <Paper className={classes.paper}>
            <div className={classes.onePassLogoBox}>
              <a href={`${Config.baseUrl}/one-pass/login`}>
                <img src={OnePassLogo} alt="CUHK OnePass" className={classes.logo} />
              </a>
            </div>
            {Config.enableLoginForm ? this.renderLoginForm() : null}
          </Paper>
          <div className={classes.cadsBox} >
            <Tooltip title="This is a CADS-registered IT System. It passed the application procedures published at http://www.itsc.cuhk.edu.hk/en-gb/all-it/information-security/centralized-authentication-and-directory-service and was approved by ITSC.">
              <a
                href="http://www.itsc.cuhk.edu.hk/en-gb/all-it/information-security/centralized-authentication-and-directory-service"
                target="_blank"
                rel="noreferrer noopener"
                className={classes.cadsLink}
              >
                <img src={CadsLogo} alt="CADS Logo" className={classes.cadsLogo} />
                CADS reference number : 330
              </a>
            </Tooltip>
          </div>
          <div className={classes.copyright}>
            <Typography gutterBottom color="primary" align="right">
              <a className={classes.link} href="http://www.cuhk.edu.hk/english/disclaimer.html" target="_blank" rel="noopener noreferrer">Disclaimer</a>&nbsp;|&nbsp;
              <a className={classes.link} href="http://www.cuhk.edu.hk/english/privacy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            </Typography>
            <Typography variant="caption">Copyright &copy; 2018. All Rights Reserved. Faculty of Medicine, The Chinese University of Hong Kong.</Typography>

          </div>
        </div>
        <div className={classes.spacer} />
      </div>
    );
  }
}

const EMPTY_FORM = {
  username: null,
  password: null,
};
const LoginForm = reduxForm({
  form: 'LoginForm',
  enableReinitialize: true,
  initialValues: EMPTY_FORM,
});

export default withRouter(withStyles(styles)(LoginForm(LoginView)));
