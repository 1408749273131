export const SEARCH_FORM_SUBMIT = 'STAFF.ACTIVITIES.SEARCH_FORM_SUBMIT';
export const SORT_CHANGE = 'STAFF.ACTIVITIES.SORT_CHANGE';
export const PAGE_CHANGE = 'STAFF.ACTIVITIES.PAGE_CHANGE';
export const FETCH_LIST = 'STAFF.ACTIVITIES.FETCH_LIST';
export const FETCH_ONE = 'STAFF.ACTIVITY.FETCH_ONE';
export const CLEAR_ONE = 'STAFF.ACTIVITY.CLEAR_ONE';

export const STUDENTS_SEARCH_FORM_SUBMIT = 'STAFF.ACTIVITY.STUDENTS_SEARCH_FORM_SUBMIT';
export const STUDENTS_SORT_CHANGE = 'STAFF.ACTIVITY.STUDENTS_SORT_CHANGE';
export const STUDENTS_PAGE_CHANGE = 'STAFF.ACTIVITY.STUDENTS_PAGE_CHANGE';
export const STUDENTS_FETCH_LIST = 'STAFF.ACTIVITY.STUDENTS_FETCH_LIST';
