import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import TextField from 'common/components/form/TextField';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';

import { handle422 } from 'common/helpers/ReduxFormHelper';
import UserDepartmentDropdown from 'public/components/form/UserDepartmentDropdown';
import AcademicInput from 'common/components/form/AcademicInput';
import { isAcademicYear } from 'common/helpers/validation';

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  field: {
    minWidth: 250,
    margin: theme.spacing(1),
  },
});

// const parseCode = v => ((v && v.code) ? v.code : v);
// const parseCodes = v => (Array.isArray(v) ? v.map(parseCode) : []);
// const formatArray = v => (Array.isArray(v) ? v : []);

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Required';
  }
  errors.year = isAcademicYear(values?.year);
  if (!values.department_code) {
    errors.department_code = 'Required';
  }
  return errors;
};

class QuestionnaireCreateForm extends Component { // eslint-disable-line
  static propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
  }
  static defaultProps = {
    onClose: () => { },
    onSubmit: () => { },
    error: null,
  }

  onSubmit = async (values) => {
    try {
      // console.log(values);
      const resp = await this.props.onSubmit(values);
      return resp;
    } catch (e) {
      handle422(e.response);
      throw e;
    }
  }

  renderError = (error) => {
    if (error) {
      return (<div><Typography color="error" variant="h6">{error}</Typography><Divider /></div>);
    }
    return null;
  }

  render() {
    const { classes, handleSubmit, error } = this.props;
    const submit = handleSubmit(this.onSubmit);
    return (
      <div>
        <DialogTitle>Add Questionnaire</DialogTitle>
        <DialogContent style={{ minHeight: 200 }}>
          {this.renderError(error)}
          <form onSubmit={submit}>
            <div className={classes.field}>
              <InputLabel shrink>Department Code</InputLabel>
              <Field
                name="department_code"
                component={UserDepartmentDropdown}
                placeholder="Select..."
                fullWidth
              />
            </div>
            <Field
              className={classes.field}
              name="year"
              component={TextField}
              multiline
              label="Academic Year"
              fullWidth
              InputProps={{
                inputComponent: AcademicInput,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Field
              className={classes.field}
              name="name"
              component={TextField}
              label="Name"
              fullWidth
            />

          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary">Cancel</Button>
          <Button variant="contained" onClick={submit} color="primary">CREATE</Button>
        </DialogActions>
      </div>
    );
  }
}

export default reduxForm({
  form: 'QuestionnaireCreateForm',
  initialValues: {
    status: 0,
    name: '',
    department_code: null,
    intro: '<p>1=Strongly disagree &nbsp; 2=Disagree &nbsp; 3=Slightly disagree &nbsp; 4=Slightly agree &nbsp; 5=Agree &nbsp; 6=Strongly agree &nbsp; NA=Not applicable</p>',
  },
  validate,
})(withStyles(styles)(QuestionnaireCreateForm));
