import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

const ConfirmButton = ({
  DialogProps = {},
  ButtonComponent = Button,
  message,
  onConfirm,
  onClick,
  titleText = 'Confirm',
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  ...props
}, ref) => {
  const [open, setDialogOpen] = useState(false);
  const closeDialog = React.useCallback(() => setDialogOpen(false), []);
  return (
    <React.Fragment>
      <ButtonComponent
        {...props}
        ref={ref}
        onClick={(e) => {
          if (onClick && !onClick(e)) {
            return;
          }
          setDialogOpen(true);
        }}
      />
      <Dialog {...DialogProps} open={open} onClose={closeDialog}>
        <DialogTitle>{titleText}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>{cancelText}</Button>
          <Button onClick={() => { onConfirm(); closeDialog(); }} color="primary" variant="contained">{confirmText}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default React.forwardRef(ConfirmButton);