
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import actions from 'public/redux/Questions/actions';
import QuestionCreateView from './QuestionCreateView';


const selector = formValueSelector('QuestionCreateForm');

const mapStateToProps = state => ({
  type: selector(state, 'type'),
  teacher: selector(state, 'teacher'),
  teacher_id: selector(state, 'teacher_id'),
});
const mapDispatchToProps = {
  create: actions.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionCreateView);
