import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import PageLoading from 'common/components/PageLoading';
import Toolbar from 'common/components/ToolBar';
import FormCard from './ActivityFormCard';


const styles = theme => ({
  wrapper: {
    padding: theme.spacing(2),
  },
});

class ActivityView extends Component { // eslint-disable-line
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }).isRequired,
    // redux connect
    fetchOne: PropTypes.func.isRequired,
    clearOne: PropTypes.func.isRequired,
    init: PropTypes.bool.isRequired,
    model: PropTypes.shape({}).isRequired,
  };


  componentDidMount() {
    const { match, clearOne, fetchOne } = this.props;
    clearOne()
      .then(() => fetchOne(match.params.id));
  }

  render() {
    const { init, model, classes } = this.props;
    if (!init) {
      return (<PageLoading />);
    }
    return (
      <div>
        <Toolbar
          color="default"
          showBackButton
          title={`Activity: ${model.name}`}
        />
        <div className={classes.wrapper}>
          <FormCard model={model} />
        </div>
      </div >
    );
  }
}

export default withRouter(withStyles(styles)(ActivityView));
