import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Questionnaire from 'common/components/questionnaire/Questionnaire';
import PageLoading from 'common/components/PageLoading';

const styles = theme => ({
  wrapper: {
    padding: theme.spacing(2),
    flex: 1,
    display: 'flex',
    justify: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  container: {
    margin: '0 auto',
    flex: 1,
    maxWidth: 1200,
  },
  header: {
    padding: theme.spacing(2),
  },
  submitPanel: {
    padding: theme.spacing(2),
  },
});

class QuestionnairePreview extends Component { // eslint-disable-line
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }).isRequired,
    // redux-form
    handleSubmit: PropTypes.func.isRequired,
    // redux connect
    fetchOne: PropTypes.func.isRequired,
    clearOne: PropTypes.func.isRequired,
    init: PropTypes.bool.isRequired,
    model: PropTypes.shape({}).isRequired,
  };

  componentDidMount() {
    const { match, model, init } = this.props;
    // console.log(this.props);
    if (!init || (Number(match.params.id) !== Number(model.id))) {
      this.props.clearOne()
        .then(() => this.props.fetchOne(match.params.id));
    }
  }


  render() {
    // console.log(this.props);
    const { init, model, classes, handleSubmit } = this.props;
    if (!init) {
      return (
        <div className={classes.wrapper}>
          <div className={classes.container}>
            <PageLoading />
          </div>
        </div>
      );
    }
    const onSubmit = handleSubmit(async values => console.log(values));
    return (
      <div className={classes.wrapper}>
        <form className={classes.container} onSubmit={onSubmit}>
          <div className={classes.header}>
            <Typography variant="h3">{model.name}</Typography>
          </div>
          <Questionnaire model={model} />
          <div className={classes.submitPanel}>
            <Button type="submit" variant="contained" size="large" color="primary">Submit</Button>
          </div>
        </form>
      </div >
    );
  }
}

export default reduxForm({
  form: 'CoordinatorQuestionnairePreview',
  touchOnBlur: false,
})(withRouter(withStyles(styles)(QuestionnairePreview)));
