import { connect } from 'react-redux';
import StudentSurveyActions from 'public/redux/StudentSurvey/actions';

import SurveyListView from './SurveyListView';

const mapStateToProps = ({ StudentSurvey }) => StudentSurvey;
const mapDispatchToProps = StudentSurveyActions;

export default connect(mapStateToProps, mapDispatchToProps)(SurveyListView);

