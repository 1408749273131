import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  formCardSectionRow: {
    display: 'flex',
  },
});

const FormCardSectionFieldRow = (props) => {
  const { classes, children, className } = props;
  return (
    <div className={cx(classes.formCardSectionRow, className)}>
      {children}
    </div>
  );
};
FormCardSectionFieldRow.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};
FormCardSectionFieldRow.defaultProps = {
  children: null,
  className: null,
};

export default withStyles(styles)(FormCardSectionFieldRow);
