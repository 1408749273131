import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import {
  makeStyles,
  Dialog,
  Checkbox,
  IconButton,
  Paper,
  Tooltip,
  Collapse,
  TableCell,
  TableRow,
  TableContainer,
} from '@material-ui/core';

import FilterList from '@material-ui/icons/FilterList';
import Edit from '@material-ui/icons/Edit';
import AddBox from '@material-ui/icons/AddBox';
import Delete from '@material-ui/icons/Delete';
import ContentCopy from '@material-ui/icons/FileCopy';

import ConfirmButton from 'common/components/ConfirmButton';
import { EnhancedTable } from 'common/components/tables';
import ToolBar from 'common/components/ToolBar';
import Loading from 'common/components/Loading';

import ListActions from 'public/redux/Activities/actions';
import CommonActions from 'public/redux/common/actions';
import CreateForm from './ActivityCreateForm';
import ActivitySearchForm from './ActivitySearchForm';
import CopyForm from './CopyActivityForm';

const columnData = [
  { id: 'activity.id', numeric: false, disablePadding: false, label: 'ID', sortable: true },
  { id: 'activity.name', numeric: false, disablePadding: false, label: 'Name', sortable: true },
  { id: 'activity.year', numeric: false, disablePadding: false, label: 'Academic Year', sortable: true },
  { id: 'course.code', numeric: false, disablePadding: false, label: 'Course', sortable: true },
  { id: 'activity.department_code', numeric: false, disablePadding: false, label: 'Department', sortable: true },
  { id: 'teachers', numeric: false, disablePadding: false, label: 'Teachers', sortable: false },
  { id: 'activity.active', numeric: false, disablePadding: false, label: 'Status', sortable: true },
  { id: 'actions', numeric: false, disablePadding: false, label: '', sortable: false },
];

const useStyles = makeStyles(() => ({
  paper: { position: 'relative' },
}));

const renderTeacher = teachers => (teachers ? teachers.map(t => <span key={t.id}>{t.name}<br /></span>) : null);

const ActivityRow = ({
  rowData,
  selectedRows,
  setSelectedRows,
  setCopyRow,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isSelected = id => (selectedRows.findIndex(ele => ele.id === id) >= 0);
  const rowSelected = isSelected(rowData.id);
  const handleRowClick = () => {
    if (rowSelected) {
      setSelectedRows(selectedRows.filter(ele => ele.id !== rowData.id));
    } else {
      setSelectedRows(selectedRows.concat([rowData]));
    }
  };
  return (
    <TableRow
      hover
      tabIndex={-1}
      selected={rowSelected}
      aria-checked={rowSelected}>
      <TableCell padding="checkbox">
        <Checkbox color="primary" checked={rowSelected} onClick={handleRowClick} />
      </TableCell>
      <TableCell>{rowData.id}</TableCell>
      <TableCell>{rowData.name}</TableCell>
      <TableCell>{rowData.year}</TableCell>
      <TableCell>{rowData?.course?.code}</TableCell>
      <TableCell>{rowData.is_master ? <strong>Master Template</strong> : rowData.department_code}</TableCell>
      <TableCell>{renderTeacher(rowData.teachers)}</TableCell>
      <TableCell>{rowData.active ? 'Active' : 'Inactive'}</TableCell>
      <TableCell>
        <Tooltip title="Copy Activity">
          <IconButton onClick={() => setCopyRow(rowData)}><ContentCopy /></IconButton>
        </Tooltip>
        <Tooltip title="View Details">
          <IconButton component={Link} to={`/staff/activity/${rowData.id}`} aria-label="View Details"><Edit /></IconButton>
        </Tooltip>
        {
          !rowData.is_master ?
            <Tooltip title="Delete Activity">
              <ConfirmButton
                ButtonComponent={IconButton}
                disabled={Boolean(rowData.is_master)}
                onConfirm={async () => {
                  await dispatch(ListActions.delete(rowData));
                  dispatch(CommonActions.setSnackbarMessage('Activity deleted.'));
                }}
                message={`Confirm to delete the Activity "${rowData.name}"?`}
              >
                <Delete />
              </ConfirmButton>
            </Tooltip>
            : null}
      </TableCell>
    </TableRow>);
}
const renderRow = ({ rowData, ...props }) => <ActivityRow key={`${rowData.id}`}  {...props} rowData={rowData} />;

const mapStateToProps = ({ Activities }) => Activities;
const ActivityList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { page, pageSize, data, totalElements, sort, form, init, submitting } = useSelector(mapStateToProps);
  const [formOpen, setFormOpen] = React.useState(true);
  const [showCreate, setShowCreate] = React.useState(false);
  const [copyRow, setCopyRow] = React.useState(null);

  const handleToggleFormOpen = React.useCallback(() => setFormOpen(!formOpen), [formOpen]);
  const handleShowCreate = React.useCallback(() => setShowCreate(true), []);
  const handleHideCreate = React.useCallback(() => setShowCreate(false), []);
  const handleSearch = React.useCallback((values) => dispatch(ListActions.searchFormSubmit(values)).catch(console.log), [dispatch]);
  const handleSortChange = React.useCallback(sort => dispatch(ListActions.sortChange(sort)).catch(console.log), [dispatch]);
  const handlePageChange = React.useCallback(page => dispatch(ListActions.pageChange(page)).catch(console.log), [dispatch]);
  const handleCopy = React.useCallback(values => dispatch(ListActions.copy(values)), [dispatch]);

  React.useEffect(() => {
    if (!init && !submitting) {
      dispatch(ListActions.searchFormSubmit({})).catch(console.log);
    }
  }, [dispatch, init, submitting]);
  return (
    <div>
      <ToolBar
        color="default"
        title="Activities"
        rightButton={[
          { title: 'Filter', icon: (<FilterList />), onClick: handleToggleFormOpen },
          { title: 'Create new Activity', icon: (<AddBox />), onClick: handleShowCreate },
        ]}
      />
      {
        copyRow ?
          <Dialog
            open
            onClose={() => setCopyRow(null)}
            disableBackdropClick
            disableEscapeKeyDown
          >
            <CopyForm
              onSubmit={handleCopy}
              onSubmitSuccess={r => history.push(`/staff/activity/${r.id}`)}
              initialValues={{ from: copyRow?.id, name: copyRow?.name, department_code: copyRow?.department_code }}
              enableReinitialize
              onClose={() => setCopyRow(null)}
            />
          </Dialog>
          : null
      }
      <Dialog
        open={showCreate}
        onClose={handleHideCreate}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <CreateForm
          onClose={handleHideCreate}
          onSubmit={async (values) => {
            const response = await dispatch(ListActions.create(values));
            return response;
          }}
          onSubmitSuccess={() => {
            handleHideCreate();
            dispatch(CommonActions.setSnackbarMessage('Activity created.'));
          }}
        />
      </Dialog>
      <Collapse in={formOpen}>
        <ActivitySearchForm onSubmit={handleSearch} initialValues={form} />
      </Collapse>
      <Paper className={classes.paper}>
        <TableContainer>
          <EnhancedTable
            totalElements={totalElements}
            pageSize={pageSize}
            page={page}
            sort={sort}
            columnData={columnData}
            data={data}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange}
            renderRow={renderRow}
            renderRowProps={{ setCopyRow }}
          />
        </TableContainer>
        <Loading loading={submitting} />
      </Paper>
    </div>
  );
}

export default ActivityList;
