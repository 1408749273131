
const YEAR_REGEX = /^(19|20)\d\d\/\d\d$/;

function isRequired(val) {
  if (!val && val !== false) {
    return 'Required.';
  }
};

function isAcademicYear(yearStr = null, required = true) {
  if (required && !yearStr) {
    return 'Required.';
  } else if (!yearStr) {
    return undefined; // empty
  }

  if (!YEAR_REGEX.test(yearStr)) {
    return 'Invalid format.';
  }
  const year1 = Number(yearStr.substr(2, 2));
  const year2 = Number(yearStr.substr(5, 2));
  // console.log(year2, year1);
  return (year2 - year1) != 1 ? 'Invalid format.' : undefined;
}
// const yearRegex = /^[1-9]\d\d\d\/\d\d$/;
// const isAcademicYear = (val) => {
//   if (val && !yearRegex.test(val)) {
//     return 'Invalid Format.';
//   }
// }

export {
  isRequired,
  isAcademicYear,
  YEAR_REGEX,
};
