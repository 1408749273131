import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

class ConfirmButton extends Component {
  static propTypes = {
    component: PropTypes.string,
    message: PropTypes.any.isRequired, // eslint-disable-line
    onConfirm: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    DialogProps: PropTypes.shape({}),
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    ButtonComponent: PropTypes.any, //eslint-disable-line
  }
  static defaultProps = {
    component: 'span',
    DialogProps: {},
    onClick: null,
    cancelText: 'Cancel',
    confirmText: 'Confirm',
    ButtonComponent: Button,
  }

  state = {
    open: false,
  }

  render() {
    const { DialogProps, ButtonComponent, component, message, onConfirm, onClick, cancelText, confirmText, ...props } = this.props;
    const { open } = this.state;
    return React.createElement(
      component,
      props,
      (<ButtonComponent
        {...props}
        onClick={(e) => {
          if (onClick && !onClick(e)) {
            return;
          }
          this.setState({ open: true });
        }}
      />),
      (
        <Dialog {...DialogProps} open={open} onClose={() => this.setState({ open: false })}>
          <DialogTitle>Confirm</DialogTitle>
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ open: false })}>{cancelText}</Button>
            <Button onClick={() => { onConfirm(); this.setState({ open: false }); }} color="primary" variant="contained">{confirmText}</Button>
          </DialogActions>
        </Dialog>
      ),
    );
  }
}

export default ConfirmButton;
