import React, { Component } from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import fileDownload from 'js-file-download';

import {
  InputLabel,
  Button,
  CardActions,
  CardContent,
  makeStyles,
} from '@material-ui/core';

import TextField from 'common/components/form/TextField';
import buildQuery from 'common/helpers/buildQuery';
import { CURRENT_ACADEMIC_YEAR } from 'common/constants/years';
import AcademicInput from 'common/components/form/AcademicInput';
import Toolbar from 'common/components/ToolBar';
import { FormCard, FormCardSection, FormCardContent } from 'common/components/FormCard';
import { handle422 } from 'common/helpers/ReduxFormHelper';
import { isAcademicYear } from 'common/helpers/validation';

import LoadingFileDialog from 'public/components/common/LoadingFileDialog';
import axios from 'public/helpers/MyAxios';
import CommonActions from 'public/redux/common/actions';
import UserDepartmentDropdown from 'public/components/form/UserDepartmentDropdown';

const validate = (values) => {
  const errors = {};
  if (!values.department_code) {
    errors.department_code = 'Required';
  }
  errors.year = isAcademicYear(values?.year);
  return errors;
};

const useStyles = makeStyles(theme => ({
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const SPUSForm = ({
  handleSubmit,
  submitting
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(null);
  const onSubmit = React.useCallback(async (values) => {
    setLoading(true);
    try {
      const response = await axios.get(axios.addApiUrl(`/report/spus/download?${buildQuery({ form: values })}`), {
        responseType: 'blob',
        onDownloadProgress: e => setProgress({ loaded: e.loaded, total: e.total }),
      });
      fileDownload(response.data, `SPUS_${values?.department_code || ''}.xlsx`);
    } catch (e) {
      console.log(e);
      handle422(e?.response);
      dispatch(CommonActions.setSnackbarMessage(`Error: ${e}`))
    } finally {
      setProgress(null);
      setLoading(false);
    }
  }, []);

  return (
    <div>
      <Toolbar
        color="default"
        title="SPUS Report"
      />
      <LoadingFileDialog open={loading} progress={progress} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormCard>
          <CardContent>
            <FormCardSection>
              <div className={classes.field}>
                <InputLabel>Department</InputLabel>
                <Field
                  name="department_code"
                  placeholder="Select..."
                  component={UserDepartmentDropdown}
                  fullWidth
                />
              </div>
              <Field
                className={classes.field}
                label="Academic Year"
                name="year"
                component={TextField}
                fullWidth
                InputProps={{
                  inputComponent: AcademicInput,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Field
                className={classes.field}
                label="Course Type"
                name="type"
                component={TextField}
                select
                SelectProps={{ native: true }}
                fullWidth
              >
                <option value="" />
                <option value="UG">UG</option>
                <option value="PG">PG</option>
              </Field>
            </FormCardSection>
          </CardContent>
          <CardActions>
            <FormCardContent>
              <Button type="submit" disabled={submitting} variant="contained" color="primary">Download Report</Button>
            </FormCardContent>
          </CardActions>
        </FormCard>
      </form>
    </div>
  );
}

export default reduxForm({
  form: 'SPUSForm',
  validate,
  initialValues: {
    year: CURRENT_ACADEMIC_YEAR,
    // aggregate: '0',
  },
})(SPUSForm);
