import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import TextField from 'common/components/form/TextField';
import { withStyles } from '@material-ui/core/styles';
import SearchFormBase from 'common/components/SearchFormBase';

const styles = theme => ({
  field: {
    marginRight: theme.spacing(2),
  },
  numberField: {
    width: 100,
    marginRight: theme.spacing(2),
  },
});

const EMPTY_FORM = {
  type: '',
  item_no: '',
  status: null,
  content: '',
  id: '',
};

class QuestionSearchForm extends Component { // eslint-disable-line
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
  }

  static defaultProps = {
    onSubmit: () => { },
  }

  constructor(props) {
    super(props);
    this.clearForm = this.clearForm.bind(this);
  }

  async clearForm(event = {}) {
    if (event.preventDefault) {
      event.preventDefault();
    }
    const { onSubmit, reset } = this.props;
    await onSubmit(EMPTY_FORM);
    reset();
  }

  render() {
    const { classes, handleSubmit, onSubmit } = this.props;
    return (
      <SearchFormBase onSubmit={handleSubmit(onSubmit)} onClear={this.clearForm}>
        <Field
          name="id"
          className={classes.numberField}
          label="ID"
          component={TextField}
        />
        <Field
          name="item_no"
          className={classes.numberField}
          label="Item No."
          component={TextField}
        />
        <Field
          name="content"
          className={classes.field}
          label="Content"
          component={TextField}
        />
        <Field
          name="type"
          className={classes.field}
          label="Type"
          component={TextField}
          select
          SelectProps={{ native: true }}
        >
          <option value="" />
          <option value="RATE">RATE</option>
          <option value="OPEN">OPEN</option>
        </Field>
        <Field
          name="status"
          className={classes.field}
          label="Status"
          component={TextField}
          select
          SelectProps={{ native: true }}
        >
          <option value="" />
          <option value="10">Active</option>
          <option value="5">Submitted</option>
          <option value="0">Draft</option>
        </Field>
        <Field
          name="by_me"
          className={classes.field}
          label="Created By"
          component={TextField}
          select
          SelectProps={{ native: true }}
        >
          <option value="" />
          <option value="1">Me only</option>
        </Field>
      </SearchFormBase>
    );
  }
}


export default reduxForm({
  form: 'QuestionSearchForm',
  enableReinitialize: true,
})(withStyles(styles)(QuestionSearchForm));
