import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';

// import TextField from 'common/components/form/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Close from '@material-ui/icons/Close';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import FiberNew from '@material-ui/icons/FiberNew';
import BooleanCheckbox from 'common/components/form/BooleanCheckbox';
import RichTextMarkdown from 'common/components/form/RichTextMarkdown';
import RichText from 'common/components/RichText';

const Required = v => (!v ? 'Required' : null);
const styles = theme => ({
  root: {
    padding: theme.spacing(1),
  },
  topbar: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  flex1: {
    flex: 1,
  },
  buttonGroup: {
    textAlign: 'right',
  },
  questionRoot: {
    display: 'flex',
    border: '1px dotted #ccc',
  },
  itemNo: {
    padding: `${theme.spacing(2)}px ${theme.spacing(0.5)}px`,
    width: 70,
  },
  questionNum: {
    padding: `${theme.spacing(2)}px 0px`,
    width: 40,
  },
  questionType: {
    padding: `${theme.spacing(2)}px ${theme.spacing(0.5)}px`,
    width: 80,
  },
  checkbox: {
    paddingTop: theme.spacing(1)
  }
});

const ItemRow = ({
  classes,
  path,
  item,
  questionNum,
  canUpdate,
  onRemove,
  onMoveUp,
  onMoveDown,
}) =>
(
  <div className={classes.root}>
    <div className={classes.topbar}>
      <div className={classes.flex1}>
        {!item.id ? <FiberNew color="primary" /> : null}
      </div>
      {canUpdate ?
        <div className={classes.buttonGroup}>
          <IconButton onClick={onMoveUp} color="primary" title="Move Up"><ArrowDropUp /></IconButton>
          <IconButton onClick={onMoveDown} color="primary" title="Move Down"><ArrowDropDown /></IconButton>
          <Button onClick={onRemove} color="secondary"><Close />Remove</Button>
        </div>
        :
        <div className={classes.buttonGroup} />
      }
    </div>
    {
      item.type === 'T' ?
        <div>
          <Field
            name={`${path}.content`}
            component={RichTextMarkdown}
            validate={[Required]}
            fullWidth
            disabled={!canUpdate}
          />
        </div> :
        <div className={classes.questionRoot}>
          <Typography className={classes.itemNo} color={item.question.item_no ? 'primary' : 'error'} variant="body2">
            {item.question.item_no || `(${item.question_id})`}
          </Typography>
          <Typography className={classes.questionNum}>
            <strong>{questionNum}</strong>
          </Typography>
          <div className={classes.flex1}>
            <RichText
              content={item.question.content}
              disabled={!canUpdate}
            />
          </div>
          <Typography className={classes.questionType} color="primary" >
            {item.question.type}
            {item.question.type === 'RATE' ? ` - ${item.question.max_rate}` : null}
          </Typography>
          <Field
            className={classes.checkbox}
            name={`${path}.required`}
            component={BooleanCheckbox}
            label="Required"
            format={Boolean}
            parse={Boolean}
            disabled={!canUpdate}
          />
        </div>
    }
  </div>
);

ItemRow.propTypes = {
  path: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onMoveUp: PropTypes.func.isRequired,
  onMoveDown: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  item: PropTypes.shape({}).isRequired,
  questionNum: PropTypes.string.isRequired,
  canUpdate: PropTypes.bool.isRequired,
};


const selector = formValueSelector('SectionListForm');

const mapStateToProps = (state, { path }) => ({
  item: selector(state, path),
});

export default connect(mapStateToProps)(withStyles(styles)(ItemRow));
