import React from 'react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';

import ProtectedRoute from './components/ProtectedRoute';
import layouts from './layouts';
import {
  LoginView,
  GetFlashView,
  HomeView,
} from './containers';
import SurveyView from './containers/StudentSurvey/SurveyViewContainer';
import SurveyListView from './containers/StudentSurvey/SurveyListViewContainer';
import QuestionListView from './containers/Questions/QuestionList';
import QuestionCreateView from './containers/Questions/QuestionCreateViewContainer';
import QuestionViewView from './containers/Questions/QuestionViewViewContainer';
import QuestionnaireList from './containers/Questionnaires/QuestionnaireList';
import QuestionnairePreview from './containers/Questionnaires/QuestionnairePreviewContainer';
import QuestionnaireView from './containers/Questionnaires/QuestionnaireViewContainer';
import ActivityList from './containers/Activities/ActivityList';
import ActivityView from './containers/Activities/ActivityViewContainer';
import OnlineSurveyList from './containers/OnlineSurveys/OnlineSurveyList';
import OnlineSurveyCreateView from './containers/OnlineSurveys/OnlineSurveyCreateViewContainer';
import OnlineSurveyView from './containers/OnlineSurveys/OnlineSurveyViewContainer';
import OpenSurveyView from './containers/OpenSurvey/SurveyViewContainer';
import CoordinatorOnlineSurveyList from './containers/Coordinator/Surveys/OnlineSurveyListContainer';
import CoordinatorOnlineSurveyView from './containers/Coordinator/Surveys/OnlineSurveyViewContainer';
import CoordinatorQuestionnairePreview from './containers/Coordinator/Surveys/QuestionnairePreviewContainer';
import CoordinatorActivityList from './containers/Coordinator/Activities/ActivityList';
import CoordinatorActivityView from './containers/Coordinator/Activities/ActivityViewContainer';
import ResponseRateForm from './containers/Reports/ResponseRateForm';
import SPUSForm from './containers/Reports/SPUSForm';
import TeacherYearlyForm from './containers/Reports/TeacherYearlyForm';
import CoordinatorCourseFeedbacksForm from './containers/Coordinator/Reports/CourseFeedbacksForm';
import TeachingPerformanceForm from './containers/Coordinator/Reports/TeachingPerformanceForm';
import CoordinatorSPUSForm from './containers/Coordinator/Reports/SPUSForm';
import CoordinatorTeacherYearlyForm from './containers/Coordinator/Reports/TeacherYearlyForm';
import CoordinatorResponseRateForm from './containers/Coordinator/Reports/ResponseRateForm';

const NotFound = () => <div><h1>Page Not Found.</h1><p><Link to="/">Back to Home</Link></p></div>;

const Routes = () => (
  <Switch>
    <ProtectedRoute exact path="/" component={layouts.useMainLayout(HomeView)} />
    <ProtectedRoute exact path="/survey" component={layouts.useMainLayout(SurveyListView)} />
    <ProtectedRoute exact path="/survey/:id" component={layouts.useMainLayout(SurveyView)} />
    <ProtectedRoute exact path="/staff/questions" requireStaff component={layouts.useMainLayout(QuestionListView)} />
    <ProtectedRoute exact path="/staff/questions/create" requireStaff component={layouts.useMainLayout(QuestionCreateView)} />
    <ProtectedRoute exact path="/staff/question/:id" requireStaff component={layouts.useMainLayout(QuestionViewView)} />
    <ProtectedRoute exact path="/staff/questionnaires" requireStaff component={layouts.useMainLayout(QuestionnaireList)} />
    <ProtectedRoute exact path="/staff/questionnaire/:id" requireStaff component={layouts.useMainLayout(QuestionnaireView)} />
    <ProtectedRoute exact path="/staff/questionnaire/preview/:id" requireStaff component={layouts.useDefaultLayout(QuestionnairePreview)} />
    <ProtectedRoute exact path="/staff/activities" requireStaff component={layouts.useMainLayout(ActivityList)} />
    <ProtectedRoute exact path="/staff/activity/:id" requireStaff component={layouts.useMainLayout(ActivityView)} />
    <ProtectedRoute exact path="/staff/online-surveys" requireStaff component={layouts.useMainLayout(OnlineSurveyList)} />
    <ProtectedRoute exact path="/staff/online-surveys/create" requireStaff component={layouts.useMainLayout(OnlineSurveyCreateView)} />
    <ProtectedRoute exact path="/staff/online-survey/:id" requireStaff component={layouts.useMainLayout(OnlineSurveyView)} />
    <ProtectedRoute exact path="/report/response-rate" requireStaff component={layouts.useMainLayout(ResponseRateForm)} />
    <ProtectedRoute exact path="/report/spus" requireStaff component={layouts.useMainLayout(SPUSForm)} />
    <ProtectedRoute exact path="/report/teacher-yearly" requireStaff component={layouts.useMainLayout(TeacherYearlyForm)} />
    <ProtectedRoute exact path="/coordinator/surveys/" requireStaff component={layouts.useMainLayout(CoordinatorOnlineSurveyList)} />
    <ProtectedRoute exact path="/coordinator/survey/:id" requireStaff component={layouts.useMainLayout(CoordinatorOnlineSurveyView)} />
    <ProtectedRoute exact path="/coordinator/survey/preview/:id" requireStaff component={layouts.useMainLayout(CoordinatorQuestionnairePreview)} />
    <ProtectedRoute exact path="/coordinator/activities" requireStaff component={layouts.useMainLayout(CoordinatorActivityList)} />
    <ProtectedRoute exact path="/coordinator/activity/:id" requireStaff component={layouts.useMainLayout(CoordinatorActivityView)} />
    <ProtectedRoute exact path="/coordinator/report/course-feedbacks" requireStaff component={layouts.useMainLayout(CoordinatorCourseFeedbacksForm)} />
    <ProtectedRoute exact path="/coordinator/report/teaching-performance" requireStaff component={layouts.useMainLayout(TeachingPerformanceForm)} />
    <ProtectedRoute exact path="/coordinator/report/spus" requireStaff component={layouts.useMainLayout(CoordinatorSPUSForm)} />
    <ProtectedRoute exact path="/coordinator/report/teacher-yearly" requireStaff component={layouts.useMainLayout(CoordinatorTeacherYearlyForm)} />
    <ProtectedRoute exact path="/coordinator/report/response-rate" requireStaff component={layouts.useMainLayout(CoordinatorResponseRateForm)} />
    <Route exact path="/one-pass" component={layouts.useDefaultLayout(GetFlashView)} />
    <Route exact path="/open/:token" component={layouts.useDefaultLayout(OpenSurveyView)} />
    <ProtectedRoute exact path="/login" component={layouts.useDefaultLayout(LoginView)} reverseCheck redirectTo="/" />
    <Route exact path="/404" component={layouts.useDefaultLayout(NotFound)} />
    <Redirect path="/*" to="/404" />
  </Switch>
);

export default Routes;
