import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import TextField from 'common/components/form/TextField';

import RemoteDropdown from 'public/components/form/RemoteDropdown';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Bookmark from '@material-ui/icons/Bookmark';


const styles = theme => ({
  fieldRow: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-end',
  },
  flex1: {
    flex: 1,
  },
});
const parseCode = v => (v ? v.value : null);

class ReportFilterDialog extends Component { // eslint-disable-line
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    title: PropTypes.node,
    activity: PropTypes.shape({}),
    onSubmit: PropTypes.func.isRequired,
    // redux-form
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    // withStyles
    classes: PropTypes.shape({}).isRequired,
  }
  static defaultProps = {
    onClose: null,
    title: null,
    activity: {},
  }

  render() {
    const { open, onClose, title, handleSubmit, onSubmit, classes, change, activity, reset } = this.props;
    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <Typography variant="body1">Select Reference Group:</Typography>
            <div className={classes.fieldRow}>
              <div className={classes.flex1}>
                <Field
                  name="ref_department"
                  label="Department"
                  component={RemoteDropdown}
                  apiUrl="/values/departments"
                  labelProperty="label"
                  parse={parseCode}
                  fullWidth
                />
              </div>
              <IconButton onClick={() => change('ref_department', activity.department_code)} title="Load from Activity"><Bookmark /></IconButton>
            </div>
            <div className={classes.fieldRow}>
              <div className={classes.flex1}>
                <Field
                  name="ref_programme"
                  label="Programme"
                  component={RemoteDropdown}
                  apiUrl="/values/programmes"
                  labelProperty="label"
                  parse={parseCode}
                  fullWidth
                />
              </div>
              <IconButton onClick={() => change('ref_programme', activity.course.programme_code)} title="Load from Activity"><Bookmark /></IconButton>
            </div>
            <div className={classes.fieldRow}>
              <div className={classes.flex1}>
                <Field
                  name="ref_course"
                  label="Course"
                  component={RemoteDropdown}
                  apiUrl="/values/courses"
                  labelProperty="label"
                  valueProperty="id"
                  parse={parseCode}
                  fullWidth
                />
              </div>
              <IconButton onClick={() => change('ref_course', activity.course_id)} title="Load from Activity"><Bookmark /></IconButton>
            </div>
            <div className={classes.fieldRow}>
              <div className={classes.flex1}>
                <Field
                  name="ref_course_year"
                  label="Course Year"
                  component={RemoteDropdown}
                  apiUrl="/values/years"
                  labelProperty="name"
                  parse={parseCode}
                  fullWidth
                />
              </div>
              <IconButton onClick={() => change('ref_course_year', activity.course.course_year_code)} title="Load from Activity"><Bookmark /></IconButton>
            </div>
            <div className={classes.fieldRow}>
              <div className={classes.flex1}>
                <Field
                  name="ref_type"
                  label="Course Type (UG/PG)"
                  component={TextField}
                  select
                  SelectProps={{ native: true }}
                  fullWidth
                >
                  <option value="" />
                  <option value="UG">UG</option>
                  <option value="PG">PG</option>
                </Field>
              </div>
              <IconButton onClick={() => change('ref_type', activity.course ? activity.course.type : null)} title="Load from Activity"><Bookmark /></IconButton>
            </div>
            <div className={classes.fieldRow}>
              <Field
                name="ref_year"
                label="Academic Year"
                component={TextField}
                fullWidth
              />
              <IconButton onClick={() => change('ref_year', activity.year)} title="Load from Activity"><Bookmark /></IconButton>
            </div>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary" variant="contained">Download</Button>
            <Button onClick={reset}>Clear</Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default reduxForm({
  form: 'ReportFilterDialog',
  initialValues: {},
})(withStyles(styles)(ReportFilterDialog));
