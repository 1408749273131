import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import AccountActions from 'public/redux/account/actions';

class ProtectedRoute extends Component { // eslint-disable-line
  static propTypes = {
    user: PropTypes.shape({ id: PropTypes.any }),
    requireStaff: PropTypes.bool,
    redirectTo: PropTypes.string,
    reverseCheck: PropTypes.bool,
  };

  static defaultProps = {
    redirectTo: '/login',
    user: null,
    reverseCheck: false,
    requireStaff: false,
  }

  checkIsStaff = user => Boolean(user.staff) && Boolean(user.staff.status === 10);

  render() {
    const {
      user,
      redirectTo,
      reverseCheck,
      requireStaff,
      setRedirectTo,
      location,
      ...props
    } = this.props;
    // console.log(location);
    const logined = (user !== null && user.id !== null);
    if (Boolean(logined) !== Boolean(reverseCheck)) { // haveLogin XOR reverseCheck      
      if (!requireStaff || this.checkIsStaff(user)) {
        return <Route location={location} {...props} />;
      }
    }
    if (!reverseCheck) {
      // store protected URL and redirect to it after login
      setRedirectTo(location.pathname);
    }
    return <Redirect to={redirectTo} />;
  }
}

const mapStateToProps = ({ account: { user } }) => ({ user });
const mapDispatchToProps = {
  setRedirectTo: AccountActions.setRedirectTo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
