import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Checkbox,
  Paper,
  Collapse,
  TableCell,
  TableRow,
  Typography,
  Button,
  makeStyles,
} from '@material-ui/core';

import FilterList from '@material-ui/icons/FilterList';

import { EnhancedTable } from 'common/components/tables';
import ToolBar from 'common/components/ToolBar';
import Loading from 'common/components/Loading';

import ListActions from 'public/redux/Activities/actions';
import SearchForm from './ClassSearchForm';

const useStyles = makeStyles(theme => ({
  btnBar: {
    padding: theme.spacing(2),
    display: 'flex',
  },
  btnBarText: {
    flex: 1,
  },
  paper: { position: 'relative' },
}));

const columnData = [
  { id: 'id', numeric: false, disablePadding: false, label: 'ID', sortable: true },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: true },
  { id: 'year', numeric: false, disablePadding: false, label: 'Academic Year', sortable: true },
  { id: 'course.code', numeric: false, disablePadding: false, label: 'Course', sortable: true },
  { id: 'department_code', numeric: false, disablePadding: false, label: 'Department', sortable: true },
  { id: 'teachers', numeric: false, disablePadding: false, label: 'Teachers', sortable: false },
];

const renderTeacher = teachers => (teachers ? teachers.map(t => <span key={t.id}>{t.name}<br /></span>) : null);
const ActivityRow = ({
  rowData,
  selectedRows,
  setSelectedRows,
}) => {
  const isSelected = id => (selectedRows.findIndex(ele => ele.id === id) >= 0);
  const rowSelected = isSelected(rowData.id);
  const handleRowClick = () => {
    if (rowSelected) {
      setSelectedRows(selectedRows.filter(ele => ele.id !== rowData.id));
    } else {
      setSelectedRows(selectedRows.concat([rowData]));
    }
  };
  return (
    <TableRow
      hover
      tabIndex={-1}
      selected={rowSelected}
      aria-checked={rowSelected}
      onClick={handleRowClick}
    >
      <TableCell padding="checkbox">
        <Checkbox color="primary" checked={rowSelected} onClick={handleRowClick} />
      </TableCell>
      <TableCell>{rowData.id}</TableCell>
      <TableCell>{rowData.name}</TableCell>
      <TableCell>{rowData.year}</TableCell>
      <TableCell>{rowData.course ? rowData.course.code : null}</TableCell>
      <TableCell>{rowData.is_master ? <strong>Master Template</strong> : rowData.department_code}</TableCell>
      <TableCell>{renderTeacher(rowData.teachers)}</TableCell>
    </TableRow>
  );
}
const renderRow = ({ rowData, ...props }) => <ActivityRow key={rowData.id} {...props} rowData={rowData} />;

const mapStateToProps = ({ Activities }) => Activities;
const ActiviyList = ({
  onSelected,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { page, pageSize, data, totalElements, sort, form, init, submitting } = useSelector(mapStateToProps);

  const [selected, setSelected] = React.useState([]);
  const [formOpen, setFormOpen] = React.useState(true);

  const handleToggleFormOpen = React.useCallback(() => setFormOpen(!formOpen), [formOpen]);
  const handleSearch = React.useCallback((values) =>
    dispatch(ListActions.searchFormSubmit({ ...values, active: 1 })).catch(console.log) // ensure only show active class
    , [dispatch]);
  const handleSortChange = React.useCallback(sort => dispatch(ListActions.sortChange(sort)).catch(console.log), [dispatch]);
  const handlePageChange = React.useCallback(page => dispatch(ListActions.pageChange(page)).catch(console.log), [dispatch]);

  React.useEffect(() => !init && !submitting && handleSearch({}), [handleSearch, init, submitting]);
  return (
    <div>
      <ToolBar
        title="Select Activities"
        rightButton={[{ title: 'Filter', icon: (<FilterList />), onClick: handleToggleFormOpen }]}
      />
      <Collapse in={formOpen}>
        <SearchForm initialValues={form} onSubmit={handleSearch} />
      </Collapse>
      <Paper className={classes.paper}>
        <EnhancedTable
          totalElements={totalElements}
          pageSize={pageSize}
          page={page}
          sort={sort}
          columnData={columnData}
          data={data}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          renderRow={renderRow}
          onRowsSelected={setSelected}
        />
        <Loading loading={submitting} />
        <div className={classes.btnBar}>
          <Typography variant="h6" color="primary" className={classes.btnBarText}>
            {selected.length} {selected.length === 1 ? 'Activity' : 'Activities'} Selected
          </Typography>
          <Button variant="contained" color="primary" size="large" onClick={() => onSelected(selected)} disabled={selected.length === 0}>Next</Button>
        </div>
      </Paper>
    </div>
  );
}

export default ActiviyList;
