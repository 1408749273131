import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { handle422 } from 'common/helpers/ReduxFormHelper';
import DropzoneFileUpload from 'common/components/form/DropzoneFileUpload';
import axios from 'public/helpers/MyAxios';


const Required = value => (value ? null : 'Required');

class StudentXlsxUploadForm extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    model: PropTypes.shape({}).isRequired,
    // redux-form
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
  }

  onSubmit = async (values) => {
    try {
      const { model } = this.props;
      const formData = new FormData(); // eslint-disable-line
      formData.append('file', values.file[0]);
      formData.append('list_id', model.id);
      const resp = await axios.post(axios.addApiUrl('/staff/student/upload-xlsx'), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return resp.data;
    } catch (e) {
      handle422(e.response);
      throw e;
    }
  }

  render() {
    const { handleSubmit, submitting, model } = this.props;
    const submit = handleSubmit(this.onSubmit);
    return (
      <form onSubmit={submit}>
        <DialogTitle>Upload Student List to {model.name}</DialogTitle>
        <DialogContent>
          <Field
            name="file"
            component={DropzoneFileUpload}
            label="Excel file"
            validate={[Required]}
            accept=".xlsx"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary" disabled={submitting}>Cancel</Button>
          <Button variant="contained" type="submit" color="primary" disabled={submitting}>Upload</Button>
        </DialogActions>
      </form>
    );
  }
}

export default reduxForm({
  form: 'StudentXlsxUploadForm',
})(StudentXlsxUploadForm);

