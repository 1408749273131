import { connect } from 'react-redux';
import actions from 'public/redux/OnlineSurveys/actions';
import OnlineSurveyCreateView from './OnlineSurveyCreateView';

const mapStateToProps = null;
const mapDispatchToProps = {
  batchCreate: actions.batchCreate,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnlineSurveyCreateView);
