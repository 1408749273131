import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Avatar from '@material-ui/core/Avatar';
// import ButtonBase from '@material-ui/core/ButtonBase';
import { withStyles } from '@material-ui/core/styles';

import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';

import Numeric1CircleOutline from 'mdi-material-ui/Numeric1CircleOutline';
import Numeric2CircleOutline from 'mdi-material-ui/Numeric2CircleOutline';
import Numeric3CircleOutline from 'mdi-material-ui/Numeric3CircleOutline';
import Numeric4CircleOutline from 'mdi-material-ui/Numeric4CircleOutline';
import Numeric5CircleOutline from 'mdi-material-ui/Numeric5CircleOutline';
import Numeric6CircleOutline from 'mdi-material-ui/Numeric6CircleOutline';
import Numeric7CircleOutline from 'mdi-material-ui/Numeric7CircleOutline';
import Numeric8CircleOutline from 'mdi-material-ui/Numeric8CircleOutline';
import Numeric9CircleOutline from 'mdi-material-ui/Numeric9CircleOutline';

import Rating from './Rating';
import { IconButton } from '@material-ui/core';

const styles = theme => ({
  AvatarColored: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.constractText,
  },
  AvatarSmall: {
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(-0.5),
    marginBottom: theme.spacing(-1),
    marginRight: theme.spacing(-0.5),
    fontSize: '0.875rem',
    width: 32,
    height: 32,
  },
  Avatar: {
    fontSize: '1rem',
    width: 32,
    height: 32,
  },
  errorText: {
    textAlign: 'left',
    fontSize: '0.75rem',
    paddingBottom: theme.spacing(1),
  },
  inputWrapper: {
    display: 'inline-flex',
  },
  spacer: {
    flex: 1,
  },
});

const renderNumberic = (index, color = 'default') => {
  let IconClass = RadioButtonUnchecked;
  switch (index) {
    case 1:
      IconClass = Numeric1CircleOutline;
      break;
    case 2:
      IconClass = Numeric2CircleOutline;
      break;
    case 3:
      IconClass = Numeric3CircleOutline;
      break;
    case 4:
      IconClass = Numeric4CircleOutline;
      break;
    case 5:
      IconClass = Numeric5CircleOutline;
      break;
    case 6:
      IconClass = Numeric6CircleOutline;
      break;
    case 7:
      IconClass = Numeric7CircleOutline;
      break;
    case 8:
      IconClass = Numeric8CircleOutline;
      break;
    case 9:
      IconClass = Numeric9CircleOutline;
      break;
    default:
  }
  return (<IconClass color={color} />);
};



const MyAvatar = ({ classes, small, noColor, children, ...props }) =>
(
  <Avatar
    className={clsx({
      [classes.AvatarSmall]: small,
      [classes.Avatar]: !small,
      [classes.AvatarColored]: !noColor,
    })}
    {...props}
  >
    {children}
  </Avatar>
);
const ColoredAvatar = withStyles(styles)(MyAvatar);

const RATE_PROPS = {
  iconFilledRenderer: ({ index, value, disabled }) =>
  (
    index === value ?
      <ColoredAvatar small>{index}</ColoredAvatar>
      :
      <RadioButtonChecked color={disabled ? 'disabled' : 'primary'} />
  ),
  iconNormalRenderer: ({ index, disabled }) => renderNumberic(index, disabled ? 'disabled' : 'inherit'),
  iconHoveredRenderer: ({ index }) => renderNumberic(index, 'primary'),
};

class ItemRating extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    input: PropTypes.shape({}).isRequired,
    // label: PropTypes.string,
    meta: PropTypes.shape({}).isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    className: null,
    disabled: false,
  };

  renderError = (errorText, classes) =>
    (<FormHelperText error className={classes.errorText}>{errorText}</FormHelperText>);

  render() {
    const {
      classes,
      className,
      meta,
      input: { value, onChange },
      disabled,
      ...props
    } = this.props;
    return (
      <FormControl
        className={className}
        error={Boolean(meta.touched && meta.error)}
      >
        <div className={classes.inputWrapper}>
          <Rating
            {...RATE_PROPS}
            value={Number.isInteger(value) ? Number(value) : null}
            onChange={onChange}
            disabled={disabled}
            {...props}
          />
          <div className={classes.spacer} />
          <IconButton
            onClick={() => {
              onChange(0);
            }}
            disabled={disabled}
            title="N/A"
          >
            <ColoredAvatar small noColor={!(Number.isInteger(value) && Number(value) === 0)}>
              N/A
            </ColoredAvatar>
          </IconButton>
        </div>
        {(meta.touched && meta.error) ? this.renderError(meta.error, classes) : null}
      </FormControl>
    );
  }
}

export default withStyles(styles)(ItemRating);
