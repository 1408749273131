import React, { Component } from 'react';
import PropTypes from 'prop-types';
import copyToClipboard from 'copy-to-clipboard';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import ContentCopy from '@material-ui/icons/FileCopy';


import axios from 'public/helpers/MyAxios';

const styles = () => ({
  studentList: {
    display: 'inline-block',
  },
});

class StudentEmailListDialog extends Component {
  static propTypes = {
    surveyId: PropTypes.number.isRequired,
    classes: PropTypes.shape({}).isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
  }

  state = {
    data: [],
  }

  componentWillReceiveProps(props) {
    if (props.open && !this.props.open && props.surveyId) {
      this.fetchData(props.surveyId);
    }
  }

  async fetchData(id) {
    const response = await axios.get(axios.addApiUrl(`/staff/online-survey/all-student-list?id=${id}`));
    // console.log(response);
    this.setState({ data: response.data.items });
  }

  renderStudentList() {
    const { classes } = this.props;
    const { data } = this.state;
    return (
      <Typography>
        {data.filter(a => !a.completed).map(item => <span className={classes.studentList} key={item.id}>{item.student_id}@link.cuhk.edu.hk;</span>)}
      </Typography>
    );
  }
  render() {
    const { data } = this.state;
    const { onClose, open } = this.props;
    return (
      <Dialog open={open} onClose={onClose} disableBackdropClick disableEscapeKeyDown>
        <DialogTitle>Non-completed Student Email List</DialogTitle>
        <DialogContent>
          {this.renderStudentList()}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => copyToClipboard(data.filter(a => !a.completed).map(a => `${a.student_id}@link.cuhk.edu.hk`).join(';'))} color="primary"><ContentCopy />Copy</Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(StudentEmailListDialog);
