import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import TextField from 'common/components/form/TextField';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import ControlCheckbox from 'public/components/form/ControlCheckbox';

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  field: {
    marginRight: theme.spacing(2),
  },
  buttonContainer: {
    display: 'inline-flex',
  },
});


const SearchFormBase = ({
  classes, handleSubmit, onClear,
}) =>
  (
    <Paper className={classes.root}>
      <form onSubmit={handleSubmit} className={classes.formContainer}>
        <Field
          name="name"
          component={TextField}
          label="Survey / Evaluation Title"
          className={classes.field}
        />
        <Field
          name="show_closed"
          label="Show Closed Survey / Evaluation"
          component={ControlCheckbox}
          className={classes.field}
          InputProps={{
            color: 'primary',
          }}
          parse={v => (v ? 1 : 0)}
          format={Boolean}
        />
        <div className={classes.buttonContainer}>
          <Button variant="contained" type="submit" color="primary">SEARCH</Button>
          <Button variant="contained" onClick={onClear}>CLEAR</Button>
        </div>
      </form>
    </Paper>
  );


SearchFormBase.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func,
};
SearchFormBase.defaultProps = {
  onClear: () => { },
};

export default reduxForm({
  form: 'StudentSurveySearchForm',
  enableReinitialize: true,
})(withStyles(styles)(SearchFormBase));
