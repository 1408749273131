import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Sort from '@material-ui/icons/Sort';

const SortOptions = [
  { text: 'Closing Date (Earliest - Latest)', key: 'end', asc: true },
  { text: 'Closing Date (Latest - Earliest)', key: 'end', asc: false },
  { text: 'Title (A-Z)', key: 'name', asc: true },
  { text: 'Title (Z-A)', key: 'name', asc: false },
];

class SurveySortButton extends Component {
  static propTypes = {
    sortChange: PropTypes.func.isRequired,
    sort: PropTypes.shape({
      key: PropTypes.string,
      asc: PropTypes.bool,
    }).isRequired,
  }

  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (option) => {
    this.setState({ anchorEl: null });
    if (option) {
      this.props.sortChange({ key: option.key, asc: option.asc });
    }
  };

  render() {
    const { anchorEl } = this.state;
    const { sort, sortChange, ...props } = this.props;
    const currentOption = SortOptions.find(opt => opt.key === sort.key && opt.asc === sort.asc);
    return (
      <div {...props}>
        <Button
          variant="contained"
          color="primary"
          aria-owns={anchorEl ? 'survey-sort-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <Sort /> {currentOption ? currentOption.text : 'Sort'}
        </Button>
        <Menu
          id="survey-sort-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => this.handleClose()}
        >
          {SortOptions.map(option =>
            (
              <MenuItem
                selected={sort.key === option.key && sort.asc === option.asc}
                key={option.text}
                onClick={() => this.handleClose(option)}
              >
                {option.text}
              </MenuItem>
            ))}
        </Menu>
      </div>
    );
  }
}

export default SurveySortButton;
