import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DialogContent,
  DialogActions,
  Collapse,
  Button,
  TableContainer,
  TableRow,
  TableCell,
  Checkbox,
  makeStyles,
} from '@material-ui/core';
import RichText from 'common/components/RichText';
import { EnhancedTable } from 'common/components/tables';
import Loading from 'common/components/Loading';

import ListActions from 'public/redux/Questions/actions';
import QuestionSearchForm from './QuestionSelectionDialogSearchForm';

const columnData = [
  { id: 'id', numeric: false, disablePadding: false, label: 'ID', sortable: true },
  { id: 'item_no', numeric: false, disablePadding: false, label: 'Item No.', sortable: true },
  { id: 'content', numeric: false, disablePadding: false, label: 'Content', sortable: false },
  { id: 'type', numeric: false, disablePadding: false, label: 'Type', sortable: true },
];

const useStyles = makeStyles(() => ({
  dialogContent: {
    position: 'relative',
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const QuestionRow = ({
  rowData,
  selectedRows,
  setSelectedRows,
}) => {
  const isSelected = id => (selectedRows.findIndex(ele => ele.id === id) >= 0);
  const rowSelected = isSelected(rowData.id);
  const handleRowClick = () => {
    if (rowSelected) {
      setSelectedRows(selectedRows.filter(ele => ele.id !== rowData.id));
    } else {
      setSelectedRows(selectedRows.concat([rowData]));
    }
  };
  return (
    <TableRow
      hover
      tabIndex={-1}
      selected={rowSelected}
      aria-checked={rowSelected}
      onClick={handleRowClick}>
      <TableCell padding="checkbox">
        <Checkbox color="primary" checked={rowSelected} onClick={handleRowClick} />
      </TableCell>
      <TableCell>{rowData.id}</TableCell>
      <TableCell>{rowData.item_no}</TableCell>
      <TableCell><RichText content={rowData.content} /></TableCell>
      <TableCell>{rowData.type}</TableCell>
    </TableRow>
  );
};
const renderRow = ({ rowData, ...props }) => <QuestionRow key={`${rowData.id}`}  {...props} rowData={rowData} />;


const mapStateToProps = ({ Questions }) => Questions;
const QuestionSelectionDialogContent = ({
  onClose,
  onFinish,
  formOpen
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState([]);
  const { page, pageSize, data, totalElements, sort, form, init, submitting } = useSelector(mapStateToProps);
  const handleFinish = React.useCallback(() => onFinish(selected), [onFinish, selected]);
  const handleSearch = React.useCallback((values) => dispatch(ListActions.searchFormSubmit(values)).catch(console.log), [dispatch]);
  const handleSortChange = React.useCallback(sort => dispatch(ListActions.sortChange(sort)).catch(console.log), [dispatch]);
  const handlePageChange = React.useCallback(page => dispatch(ListActions.pageChange(page)).catch(console.log), [dispatch]);

  React.useEffect(() => {
    if (!init && !submitting) {
      dispatch(ListActions.searchFormSubmit({})).catch(console.log);
    }
  }, [dispatch, init, submitting]);
  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Collapse in={formOpen}>
          <QuestionSearchForm
            initialValues={form}
            onSubmit={handleSearch}
          />
        </Collapse>
        <TableContainer>
          <EnhancedTable
            totalElements={totalElements}
            pageSize={pageSize}
            page={page}
            sort={sort}
            columnData={columnData}
            data={data}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange}
            renderRow={renderRow}
            onRowsSelected={setSelected}
            disableSelectAll
          />
        </TableContainer>
        <Loading loading={submitting} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFinish}
          disabled={selected?.length === 0}
        >
          Add {selected?.length} Question{selected?.length !== 1 ? 's' : null}
        </Button>
      </DialogActions>
    </>
  )
}

export default QuestionSelectionDialogContent;
