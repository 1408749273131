import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import CommonSnackbar from 'public/components/common/Snackbar';
import AppNavBar from './AppNavBar';
import LeftDrawer from './LeftDrawer';

// const drawerWidth = 260;
const styles = theme => ({
  appFrame: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  content: {
    marginLeft: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexWrap: 'nowrap',
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: 'calc(100% - 68px)',
    padding: 0,
    marginTop: 68,
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 64px)',
      marginTop: 64,
    },
  },
  copyright: {
    padding: theme.spacing(1),
    textAlign: 'right',
  },
  flex1: {
    flex: 1,
  },
});

const useMainLayout = (Component) => {
  const MainLayout = ({ classes, open, ...props }) => (
    <div className={classes.appFrame}>
      <LeftDrawer />
      <main className={cx(classes.content)} >
        <div className={classes.flex1}>
          <Component {...props} />
        </div>
        <CommonSnackbar />
        <AppNavBar />
        <div className={classes.copyright}>
          <Typography variant="caption">Copyright &copy; 2018. All Rights Reserved. Faculty of Medicine, The Chinese University of Hong Kong.</Typography>
        </div>
      </main>
    </div >
  );

  MainLayout.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    open: PropTypes.bool.isRequired,
  };
  const mapStateToProps = ({ leftDrawer }) => ({ open: leftDrawer?.open });

  return connect(mapStateToProps)(withStyles(styles)(MainLayout));
};

export default useMainLayout;
