import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

class BaseField extends Component {
  static propTypes = {
    input: PropTypes.shape({}).isRequired,
    label: PropTypes.string,
    meta: PropTypes.shape({}).isRequired,
    className: PropTypes.string,
    required: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    helperText: PropTypes.string,
  };
  static defaultProps = {
    label: '',
    className: '',
    helperText: null,
    required: false,
    children: null,
  };
  renderLabel() {
    const {
      required, input, label, meta,
    } = this.props;
    return (
      <InputLabel
        shrink
        error={Boolean(meta.error || meta.warning)}
        htmlFor={input.name}
        required={required}
      >
        {label}
      </InputLabel>
    );
  }
  renderFormHelpText() {
    const { helperText } = this.props;
    return (<FormHelperText>{helperText}</FormHelperText>);
  }
  renderError() {
    const { meta } = this.props;
    return (<FormHelperText error>{meta.error}</FormHelperText>);
  }
  renderWarning() {
    const { meta } = this.props;
    return (<FormHelperText error>{meta.warning}</FormHelperText>);
  }

  render() {
    const {
      label,
      meta,
      children,
      helperText,
      input,
      ...props
    } = this.props;
    return (
      <FormControl
        error={Boolean(meta.touched && (meta.warning || meta.error))}
        {...props}
      >
        {label ? this.renderLabel() : null}
        {children}
        {helperText ? this.renderFormHelpText() : null}
        {meta.touched && meta.warning ? this.renderWarning() : null}
        {meta.touched && meta.error ? this.renderError() : null}
      </FormControl>
    );
  }
}

export default BaseField;
