import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import TextField from 'common/components/form/TextField';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { handle422 } from 'common/helpers/ReduxFormHelper';

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
  },
  field: {
    minWidth: 250,
    margin: theme.spacing(1),
  },
});


const Required = value => (value ? null : 'Required');

class StudentCreateForm extends Component { // eslint-disable-line
  static propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line
    onClose: PropTypes.func,
    // redux
    create: PropTypes.func.isRequired,
    // redux-form
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
  }
  static defaultProps = {
    onClose: () => { },
    error: null,
  }

  onSubmit = async (values) => {
    try {
      const { model, create } = this.props;
      const data = await create({
        ...values,
        list_id: model.id,
      });
      return data;
    } catch (e) {
      handle422(e.response);
      throw e;
    }
  }

  renderError = (error) => {
    if (error) {
      return (<div><Typography color="error" variant="h6">{error}</Typography><Divider /></div>);
    }
    return null;
  }

  render() {
    const { classes, handleSubmit, error, onClose } = this.props;
    const submit = handleSubmit(this.onSubmit);
    return (
      <form onSubmit={submit}>
        <DialogTitle>Add Student</DialogTitle>
        <DialogContent>
          {this.renderError(error)}
          <Field
            className={classes.field}
            name="student_id"
            component={TextField}
            label="Student ID"
            fullWidth
            validate={[Required]}
          />
          <Field
            className={classes.field}
            name="name"
            component={TextField}
            label="Student Name"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">Cancel</Button>
          <Button variant="contained" type="submit" color="primary">CREATE</Button>
        </DialogActions>
      </form>
    );
  }
}

export default reduxForm({
  form: 'StudentCreateForm',
})(withStyles(styles)(StudentCreateForm));
