import React from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress
} from '@material-ui/core';

const LoadingFileDialog = ({ open, progress }) => (
  <Dialog open={open} disableBackdropClick disableEscapeKeyDown>
    <DialogTitle>Loading File</DialogTitle>
    <DialogContent>
      <CircularProgress size={48} />
      {progress ? <Typography>{filesize(progress.loaded)} / {filesize(progress.total)}</Typography> : null}
    </DialogContent>
  </Dialog>
);
LoadingFileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  progress: PropTypes.shape({
    loaded: PropTypes.number,
    total: PropTypes.number,
  }),
};
LoadingFileDialog.defaultProps = {
  progress: null,
};

export default LoadingFileDialog;
