import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Field, reduxForm } from 'redux-form';
import {
  Card,
  CardContent,
  CardActions,
  IconButton,
  Button,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import Refresh from '@material-ui/icons/Refresh';
import CopyIcon from '@material-ui/icons/FileCopy';
import copy from 'copy-to-clipboard';

import TextField from 'common/components/form/TextField';
import { handle422 } from 'common/helpers/ReduxFormHelper';
import Link from 'common/components/Link';
import { FormCard, FormCardSection, FormCardSectionFieldRow, FormCardContent } from 'common/components/FormCard';
import PageLoading from 'common/components/PageLoading';
import Toolbar from 'common/components/ToolBar';
import NotificationSnackbar from 'common/components/NotificationSnackbar';
import DatetimePicker from 'common/components/form/DatetimePicker';
import UserDepartmentDropdown from 'public/components/form/UserDepartmentDropdown';
import Config from 'public/constants/Config';

import StudentTable from './Detail/StudentTable';
import TokenTable from './Detail/TokenTable';

const toMoment = (v) => {
  if (!v) {
    return null;
  }
  if (v._isAMomentObject) { // eslint-disable-line
    return v;
  }
  return moment(v);
};

// const parseCode = v => ((v && v.code) ? v.code : v);
const validate = (values) => {
  const errors = {};
  const start = toMoment(values.start);
  const end = toMoment(values.end);
  if (!values.name) {
    errors.name = 'Required';
  }
  if (!start) {
    errors.start = 'Required';
  }
  if (!end) {
    errors.end = 'Required';
  }
  if (start && end && !start.isBefore(end)) {
    errors.end = 'End must before Start';
  }
  if (!values.department_code) {
    errors.department_code = 'Required';
  }
  return errors;
};
const styles = theme => ({
  qrCard: {
    width: 320,
    margin: `${theme.spacing(1)}px auto`,
  },
  qrImg: {
    maxWidth: '100%',
  },
  surveyLinkWrap: {
    display: 'flex',
    backgroundColor: theme.palette.grey[100],
    boxShadow: theme.shadows[1],
  },
  surveyLinkText: {
    flex: 1,
    padding: theme.spacing(1),
  }
});


const SurveyLink = ({ model, classes }) => {
  const handleCopy = React.useCallback(() => {
    copy(`${Config.publicUrl}/survey/${model.id}`);
  }, [model.id]);
  return (
    <div className={classes.surveyLinkWrap}>
      <Typography className={classes.surveyLinkText} variant="body2">
        {Config.publicUrl}/survey/{model.id}
      </Typography>
      <Tooltip title="Copy to clipboard">
        <IconButton onClick={handleCopy}>
          <CopyIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}


const randomString = (m = 6) => {
  const r = 'ABCDEFGHJKLMNPQRSTUVWXY3456789'; // No 0,O,1,I,2,Z,
  let s = '';
  for (let i = 0; i < m; i += 1) {
    s += r.charAt(Math.floor(Math.random() * r.length));
  }
  return s;
};


class OnlineSurveyView extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }).isRequired,
    classes: PropTypes.shape({}).isRequired,
    // redux form
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    // redux connect
    fetchOne: PropTypes.func.isRequired,
    clearOne: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    init: PropTypes.bool.isRequired,
    model: PropTypes.shape({}).isRequired,
  }

  static defaultProps = {
  }

  state = {
    notiMessage: null,
  }

  componentDidMount() {
    const { match, model, init } = this.props;
    // console.log(this.props);
    if (!init || (Number(match.params.id) !== Number(model.id))) {
      this.props.clearOne()
        .then(this.props.fetchOne(match.params.id));
    }
  }

  onSubmit = async (values) => {
    try {
      await this.props.update({
        ...values,
        start: toMoment(values.start).format('YYYY-MM-DD HH:mm:00'),
        end: toMoment(values.end).format('YYYY-MM-DD HH:mm:00'),
      });
      this.setState({ notiMessage: 'Online CTE updated.' });
    } catch (e) {
      handle422(e.response);
      throw e;
    }
  }

  renderPublished = (row) => {
    switch (Number(row.published)) {
      case -1:
        return <span>Cancelled</span>;
      case 0:
        return <span>Draft</span>;
      case 1:
        return <strong>Published</strong>;
      case 2:
        return <strong>Completed</strong>;
      default:
        return null;
    }
  }

  render() {
    const {
      classes,
      init, model, handleSubmit, submitting, pristine, change,
      fetchOne,
    } = this.props;
    const { notiMessage } = this.state;
    if (!init) {
      return (<PageLoading />);
    }
    const onSubmit = handleSubmit(values => this.onSubmit(values));
    return (
      <div>
        <NotificationSnackbar
          open={Boolean(notiMessage)}
          message={notiMessage}
          onClose={() => this.setState({ notiMessage: null })}
        />
        <Toolbar
          showBackButton
          color="default"
          title={`Online CTE: ${model.id}`}
          rightButton={[
            { title: 'Refresh', icon: (<Refresh />), onClick: () => fetchOne(model.id) },
          ]}
        />
        <Card className={classes.qrCard}>
          <img src={`${Config.baseUrl}/staff/online-survey/qr?id=${model.id}`} alt="QR Code" className={classes.qrImg} />
          <CardContent>
            <SurveyLink model={model} classes={classes} />
          </CardContent>
        </Card>
        <form onSubmit={onSubmit} >
          <FormCard>
            <CardContent>
              <FormCardSection>
                <FormCardSectionFieldRow title="Activity">
                  <Link to={`/staff/activity/${model.class_id}`}>
                    <Typography>{model.class.name}</Typography>
                  </Link>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Course Code">
                  <Typography>{model.class.course ? model.class.course.label : null}</Typography>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Academic Year">
                  <Typography>{model.class.year}</Typography>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Questionnare">
                  <Link to={`/staff/questionnaire/${model.questionnaire_id}`}>
                    <Typography>{model.questionnaire.name}</Typography>
                  </Link>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Name">
                  <Field
                    name="name"
                    component={TextField}
                    fullWidth
                  />
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Department">
                  <Field
                    name="department_code"
                    component={UserDepartmentDropdown}
                    fullWidth
                  />
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Start">
                  <Field
                    name="start"
                    component={DatetimePicker}
                    format={toMoment}
                    parse={toMoment}
                  />
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="End">
                  <Field
                    name="end"
                    component={DatetimePicker}
                    format={toMoment}
                    parse={toMoment}
                  />
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Passcode">
                  <div>
                    <Field
                      name="passcode"
                      component={TextField}
                      disabled
                    />
                    <Button variant="contained" color="primary" onClick={() => change('passcode', randomString())}>Generate</Button>
                    <Button onClick={() => change('passcode', null)}>Remove</Button>
                  </div>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Overall Course Evaluation">
                  <Field
                    name="overall_cte"
                    component={TextField}
                    select
                    SelectProps={{ native: true }}
                    parse={v => (v ? Number(v) : null)}
                  >
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </Field>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Remarks">
                  <Field
                    name="remarks"
                    component={TextField}
                    multiline
                  />
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Status">
                  <Field
                    name="published"
                    component={TextField}
                    select
                    SelectProps={{ native: true }}
                    parse={v => (v ? Number(v) : null)}
                  >
                    <option value="0">Draft</option>
                    <option value="1">Published</option>
                    <option value="2">Completed</option>
                    <option value="-1">Cancelled</option>
                  </Field>
                </FormCardSectionFieldRow>
              </FormCardSection>
              <FormCardSection>
                <FormCardSectionFieldRow title="Created At">
                  <Typography variant="body1">{moment(model.created_at).format()} by {model.created_by}</Typography>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Last Updated At">
                  <Typography variant="body1">{moment(model.updated_at).format()} by {model.updated_by}</Typography>
                </FormCardSectionFieldRow>
              </FormCardSection>
            </CardContent>
            <CardActions>
              <FormCardContent>
                <Button variant="contained" color="primary" type="submit" disabled={pristine || submitting}>SAVE</Button>
                <Button color="primary" onClick={this.props.reset} disabled={pristine}>RESET</Button>
              </FormCardContent>
            </CardActions>
          </FormCard>
        </form>
        {
          model && model.id ?
            <StudentTable surveyId={model.id} /> : null
        }
        {
          model && model.id ?
            <TokenTable surveyId={model.id} /> : null
        }
      </div >);
  }
}

export default reduxForm({
  form: 'OnlineSurveyUpdateForm',
  enableReinitialize: true,
  validate,
})(withStyles(styles)(OnlineSurveyView));
