import { SubmissionError } from 'redux-form';

const createSubmissionError = (yiiErrors) => {
  const errors = {};
  yiiErrors.forEach((r) => {
    const field = r.field ? r.field : '_error';
    errors[field] = r.message;
  });
  console.log('submissionerror', errors);
  return new SubmissionError(errors);
};

const handle422 = (response) => {
  if (response && response.status === 422) {
    throw createSubmissionError(response.data);
  }
};

export {
  createSubmissionError,
  handle422,
};
