import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTablePaginationActions from './EnhancedTablePaginationActions';

const useSytles = makeStyles(() => ({
  sticky: { top: 0, bottom: 0, position: 'sticky', backgroundColor: '#fff' },
}));
const EnhancedTable = ({
  columnData,
  numSelected,
  sort,
  data,
  onSortChange,
  onPageChange,
  onPageSizeChange,
  totalElements,
  pageSize,
  page,
  noCheckbox,
  renderRow,
  pageSizeOptions,
  renderRowProps,
  onRowsSelected,
  initialRowsSelected = [],
  disableSelectAll = false,
  footerRow = null,
  init = true,
  noPagination = false,
  stickyFooter = false,
  ...props
}) => {
  const classes = useSytles();
  const [selectedRows, setSelectedRows] = useState(initialRowsSelected);
  const onSelectAllClick = () => {
    if (selectedRows.length < data.length) {
      setSelectedRows(data.slice());
    } else {
      setSelectedRows([]);
    }
  }

  React.useEffect(() => {
    onRowsSelected && onRowsSelected(selectedRows);
  }, [onRowsSelected, selectedRows]);
  const handleChangeSort = React.useCallback((event, property) => {
    if (onSortChange) {
      const newSort = {
        key: property,
        asc: sort.key === property ? !sort.asc : true,
      };
      onSortChange(newSort);
    }
  }, [onSortChange, sort]);
  const handleChangePage = React.useCallback((event, nextPage) => {
    if (onPageChange) {
      setSelectedRows([]); // clear selection;
      onPageChange(nextPage);
    }
  }, [onPageChange]);

  const handleChangePageSize = React.useCallback((event) => {
    if (onPageSizeChange) {
      setSelectedRows([]); // clear selection;
      onPageSizeChange(Number(event.target.value));
    }
  }, [onPageSizeChange]);

  const handleRenderRow = React.useCallback(
    (rowData, rowIndex) => renderRow({ rowData, rowIndex, selectedRows, setSelectedRows, noCheckbox, ...renderRowProps }),
    [noCheckbox, renderRow, renderRowProps, selectedRows]
  );
  return (
    <Table {...props}>
      <EnhancedTableHead
        columnData={columnData}
        numSelected={selectedRows.length}
        order={sort.asc ? 'asc' : 'desc'}
        orderBy={sort.key}
        onSelectAllClick={onSelectAllClick}
        onRequestSort={handleChangeSort}
        rowCount={data.length}
        noCheckbox={noCheckbox}
        disableSelectAll={disableSelectAll}
      />
      <TableBody>
        {data.length === 0 && init ?
          <TableRow>
            <TableCell colSpan={columnData.length + 1}>
              No records.
            </TableCell>
          </TableRow>
          : data.map(handleRenderRow)}
      </TableBody>
      <TableFooter>
        {footerRow}
        {noPagination ? null :
          <TableRow className={stickyFooter ? classes.sticky : null}>
            <TablePagination
              colSpan={columnData.length + 1}
              count={totalElements}
              labelRowsPerPage="Rows per page:"
              rowsPerPage={pageSize}
              page={page}
              rowsPerPageOptions={pageSizeOptions}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangePageSize}
              ActionsComponent={EnhancedTablePaginationActions}
            />
          </TableRow>
        }
      </TableFooter>
    </Table>
  );
};
EnhancedTable.propTypes = {
  page: PropTypes.number,
  pageSize: PropTypes.number,
  totalElements: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  columnData: PropTypes.arrayOf(PropTypes.shape({})),
  noCheckbox: PropTypes.bool,
  sort: PropTypes.shape({
    key: PropTypes.string,
    asc: PropTypes.bool,
  }),
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  renderRow: PropTypes.func.isRequired,
};
EnhancedTable.defaultProps = {
  page: 0,
  pageSize: 20,
  totalElements: 0,
  sort: {
    key: 'id',
    asc: false,
  },
  data: [],
  columnData: [],
  noCheckbox: false,
  pageSizeOptions: [],
};

export default EnhancedTable;
