import React, { Component } from 'react';
import pickBy from 'lodash/pickBy';
import { reduxForm, Field } from 'redux-form';
import TextField from 'common/components/form/TextField';
import querystring from 'query-string';
import fileDownload from 'js-file-download';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';

import { CURRENT_ACADEMIC_YEAR } from 'common/constants/years';
import AcademicInput from 'common/components/form/AcademicInput';
import Toolbar from 'common/components/ToolBar';
import { FormCard, FormCardSection, FormCardContent } from 'common/components/FormCard';
import LoadingFileDialog from 'common/components/download/LoadingFileDialog';
import { isAcademicYear } from 'common/helpers/validation';
import RemoteDropdown from 'public/components/form/RemoteDropdown';
import axios from 'public/helpers/MyAxios';


const buildQuery = (search, sendEmpty = false) => {
  // remove empty array
  let data = pickBy(
    search,
    // filter empty array as querystring have strange behaviour
    value => (Array.isArray(value) ? Boolean(value.length) : true),
  );
  if (!sendEmpty) {
    // remove empty input to shorten the params
    data = pickBy(
      data,
      // filter empty array as querystring have strange behaviour
      value => Boolean(value),
    );
  }
  return querystring.stringify(data, { arrayFormat: 'bracket' });
};


const parseCode = v => (v ? v.value : null);
const validate = (values) => {
  const errors = {};
  if (!values.course_code) {
    errors.course_code = 'Required';
  }
  errors.year = isAcademicYear(values?.year);
  return errors;
};
const styles = theme => ({
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

class TeachingPerformanceForm extends Component {

  constructor(props) {
    super(props);
    this.doSubmit = this.doSubmit.bind(this);
  }

  state = {
    progess: null,
    loading: false,
    notiMessage: null,
  };

  async doSubmit(values) {
    this.downloadFile(`/coordinator/report/teaching-performance?${buildQuery(values)}`, `Course Performance${values.course_code || ''}.xlsx`);
  }

  downloadFile(path, filename) {
    this.setState(
      { loading: true, notiMessage: null },
      async () => {
        try {
          const response = await axios.get(axios.addApiUrl(path), {
            responseType: 'blob',
            onDownloadProgress: e => this.setState({ progess: { loaded: e.loaded, total: e.total } }),
          });
          fileDownload(response.data, filename);
          this.setState({ loading: false, progess: null });
        } catch (e) {
          if (e.response.status === 422 && e.response.data.type === 'application/json') {
            this.setState({ loading: false, progess: null });
            const fr = new FileReader();
            fr.onload = () => {
              const errors = JSON.parse(fr.result);
              this.setState({ notiMessage: `Error: ${errors[0].message}` });
            };
            fr.readAsText(e.response.data);
          } else {
            this.setState({ loading: false, progess: null, notiMessage: `Error: ${e.message}` });
          }
        }
      },
    );
  }


  render() {
    const { handleSubmit, classes } = this.props;
    const { notiMessage, loading, progess } = this.state;
    return (
      <div>
        <Toolbar
          title="Teaching Performance"
          color="default"
        />
        <LoadingFileDialog open={loading} progress={progess} />
        <form onSubmit={handleSubmit(this.doSubmit)}>
          <FormCard>
            <CardContent>
              <FormCardSection>
                <Field
                  className={classes.field}
                  label="Course"
                  name="course_code"
                  apiUrl="/coordinator/report/courses"
                  labelProperty="label"
                  valueProperty="code"
                  parse={parseCode}
                  component={RemoteDropdown}
                  fullWidth
                />
                <Field
                  className={classes.field}
                  label="Academic Year"
                  name="year"
                  component={TextField}
                  fullWidth
                  InputProps={{
                    inputComponent: AcademicInput,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormCardSection>
            </CardContent>
            <CardActions>
              <FormCardContent>
                <Button type="submit" variant="contained" color="primary">Download Report</Button>
                {notiMessage ? <Typography color="error">{notiMessage}</Typography> : null}
              </FormCardContent>
            </CardActions>
          </FormCard>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'TeachingPerformanceForm',
  validate,
  initialValues: {
    year: CURRENT_ACADEMIC_YEAR,
  },
})(withStyles(styles)(TeachingPerformanceForm));
