import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import TextField from 'common/components/form/TextField';

import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloudUpload from '@material-ui/icons/CloudUpload';
import Refresh from '@material-ui/icons/Refresh';

import { handle422 } from 'common/helpers/ReduxFormHelper';
import RichTextMarkdown from 'common/components/form/RichTextMarkdown';

import { FormCard, FormCardSection, FormCardSectionFieldRow, FormCardContent } from 'common/components/FormCard';
import PageLoading from 'common/components/PageLoading';
import NotificationSnackbar from 'common/components/NotificationSnackbar';
import LocalRadioGroup from 'common/components/form/LocalRadioGroup';
import ConfirmButton from 'common/components/ConfirmButton';
import Toolbar from 'common/components/ToolBar';

import TeacherSelectionDialog from 'public/components/Selection/TeacherSelectionDialog';


const validate = (values) => {
  const errors = {};
  if (!values.type) {
    errors.type = 'Required';
  }
  if (values.type === 'RATE' && !values.max_rate) {
    errors.max_rate = 'Required';
  }
  if (!values.content) {
    errors.content = 'Required';
  }
  if (values.status === null || values.status === undefined || values.status === '') {
    errors.status = 'Required';
  }
  if (Number(values.status) === 10 && !values.item_no) {
    errors.item_no = 'Required';
  }
  return errors;
};


class QuestionView extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }).isRequired,
    // redux form
    change: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    // redux connect
    fetchOne: PropTypes.func.isRequired,
    clearOne: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    init: PropTypes.bool.isRequired,
    model: PropTypes.shape({}).isRequired,
    teacher: PropTypes.shape({}),
    type: PropTypes.string,
  }

  static defaultProps = {
    type: null,
    teacher: null,
  }

  state = {
    notiMessage: null,
    showTeachers: false,
  }

  componentDidMount() {
    const { match, model, init } = this.props;
    // console.log(this.props);
    if (!init || (Number(match.params.id) !== Number(model.id))) {
      this.props.clearOne()
        .then(this.props.fetchOne(match.params.id));
    }
  }

  onSubmit = async (values) => {
    const { update } = this.props;
    if (this.canUpdate()) { // Not Active and is created by same person
      try {
        await update(values);
        this.setState({ notiMessage: 'Question updated.' });
      } catch (e) {
        handle422(e.response);
        throw e;
      }
    }
    // else do nothing
  }


  canUpdate() {
    const { model, user } = this.props;
    return Boolean(model.status === 0 && model.created_by === user.username);
  }


  renderStatus = (status) => {
    switch (Number(status)) {
      case 10:
        return 'Active';
      case 5:
        return 'Submitted';
      case 0:
      default:
        return 'Draft';
    }
  }

  render() {
    const { init, type, model, teacher, handleSubmit, submitting, pristine, change } = this.props;
    const { notiMessage, showTeachers } = this.state;
    if (!init) {
      return (<PageLoading />);
    }
    const onSubmit = handleSubmit(values => this.onSubmit(values));
    const canUpdate = this.canUpdate();
    return (
      <div>
        <NotificationSnackbar
          open={Boolean(notiMessage)}
          message={notiMessage}
          onClose={() => this.setState({ notiMessage: null })}
        />
        <Toolbar
          color="default"
          showBackButton
          title={`Question: ${model.item_no || `Draft-${model.id}`}`}
          rightButton={[
            { title: 'Refresh', icon: (<Refresh />), onClick: () => this.props.fetchOne(this.props.match.params.id) },
          ]}
          renderExtraButton={() => [
            <Tooltip title="Submit Question for review">
              <ConfirmButton
                key="submit"
                ButtonComponent={IconButton}
                disabled={!canUpdate}
                color="primary"
                message="You cannot modify the question after Submission. Confirm to submit this question?"
                onConfirm={() => this.onSubmit({ id: model.id, status: 5 })}
              >
                <CloudUpload />
              </ConfirmButton>
            </Tooltip>,
          ]}
        />
        <TeacherSelectionDialog
          open={showTeachers}
          onClose={() => this.setState({ showTeachers: false })}
          onFinish={(t) => {
            change('teacher_id', t.id);
            change('teacher', t);
            this.setState({ showTeachers: false });
          }}
        />
        <form onSubmit={onSubmit}>
          <FormCard>
            <CardContent>
              <FormCardSection>
                <FormCardSectionFieldRow title="Item No.">
                  <Typography color={model.item_no ? 'default' : 'primary'}>{model.item_no || '(To be assigned by admin)'}</Typography>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Type">
                  <Field
                    name="type"
                    component={LocalRadioGroup}
                    fullWidth
                    disabled={!canUpdate}
                    row
                    options={[
                      { label: 'RATE', value: 'RATE' },
                      { label: 'OPEN', value: 'OPEN' },
                    ]}
                  />
                  {
                    type === 'RATE' ?
                      <Field
                        name="max_rate"
                        label="Max Rating"
                        component={TextField}
                        disabled={!canUpdate}
                        select
                        SelectProps={{ native: true }}
                        style={{ minWidth: 100 }}
                      >
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                      </Field>
                      : null
                  }
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Subtype">
                  <Field
                    name="subtype"
                    component={TextField}
                    disabled={!canUpdate}
                    select
                    SelectProps={{ native: true }}
                    style={{ minWidth: 100 }}
                    helperText="(Optional)"
                  >
                    <option value="">&nbsp;</option>
                    <option value="U">U</option>
                    <option value="C">C</option>
                    <option value="O">O</option>
                  </Field>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Content">
                  <Field
                    name="content"
                    component={RichTextMarkdown}
                    disabled={!canUpdate}
                    fullWidth
                  />
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Associated Teacher">
                  {teacher ? <Typography variant="body1" gutterBottom>{teacher.name} ({teacher.email})</Typography> : <Typography variant="body1" gutterBottom >No Associated Teacher</Typography>}
                  {canUpdate ?
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => this.setState({ showTeachers: true })}
                      >
                        Select
                      </Button>
                      <Button
                        color="primary"
                        size="small"
                        onClick={() => {
                          change('teacher_id', null);
                          change('teacher', null);
                        }}
                      >
                        Remove
                      </Button>
                    </div> : null
                  }
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Status">
                  <Typography>{this.renderStatus(model.status)}</Typography>
                </FormCardSectionFieldRow>
              </FormCardSection>
              <FormCardSection>
                <FormCardSectionFieldRow title="Created At">
                  <Typography variant="body1">{moment(model.created_at).format()} by {model.created_by}</Typography>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Submitted At">
                  <Typography variant="body1">{model.submitted_at ? moment(model.submitted_at).format() : null} by {model.submitted_by}</Typography>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Last Updated At">
                  <Typography variant="body1">{moment(model.updated_at).format()} by {model.updated_by}</Typography>
                </FormCardSectionFieldRow>
              </FormCardSection>
            </CardContent>
            {canUpdate ?
              <CardActions>
                <FormCardContent>
                  <Button variant="contained" color="primary" type="submit" disabled={pristine || submitting}>SAVE</Button>
                  <Button color="primary" onClick={this.props.reset} disabled={pristine}>RESET</Button>
                </FormCardContent>
              </CardActions>
              : null
            }
          </FormCard>
        </form>
      </div >);
  }
}

export default withRouter(reduxForm({
  form: 'QuestionUpdateForm',
  enableReinitialize: true,
  validate,
})(QuestionView));
