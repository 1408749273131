import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import TextField from 'common/components/form/TextField';
import { withStyles } from '@material-ui/core/styles';
import SearchFormBase from 'common/components/SearchFormBase';

const styles = theme => ({
  field: {
    marginRight: theme.spacing(2),
    minWidth: 150,
  },
});

const EMPTY_FORM = {
  id: '',
  name: '',
  is_master: null,
  department_code: '',
  çourse_code: '',
  year: '',
  teacherName: '',
  active: 1,
};

class ClassSearchForm extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    onSubmit: PropTypes.func.isRequired,
    // redux-form
    handleSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.clearForm = this.clearForm.bind(this);
  }

  componentDidMount() {
    const { onSubmit } = this.props;
    onSubmit(EMPTY_FORM)
      .catch(err => console.log('error', err));
  }

  clearForm(event = {}) {
    if (event.preventDefault) {
      event.preventDefault();
    }
    const { onSubmit } = this.props;
    onSubmit(EMPTY_FORM);
  }

  render() {
    const { classes, handleSubmit, onSubmit } = this.props;
    return (
      <SearchFormBase onSubmit={handleSubmit(onSubmit)} onClear={this.clearForm}>
        <Field
          name="id"
          className={classes.field}
          label="ID"
          component={TextField}
        />
        <Field
          name="name"
          className={classes.field}
          label="Name"
          component={TextField}
        />
        <Field
          name="year"
          className={classes.field}
          label="Academic Year"
          component={TextField}
        />
        <Field
          name="course_code"
          className={classes.field}
          label="Course Code"
          component={TextField}
        />
        <Field
          name="is_master"
          className={classes.field}
          label="Master Template"
          component={TextField}
          select
          SelectProps={{ native: true }}
        >
          <option value="" />
          <option value="1">Yes</option>
          <option value="0">No</option>
        </Field>
        <Field
          name="department_code"
          className={classes.field}
          label="Deparment Code"
          component={TextField}
        />
        <Field
          name="teacherName"
          className={classes.field}
          label="Teacher Name"
          component={TextField}
        />
        <Field
          name="by_me"
          className={classes.field}
          label="Created By"
          component={TextField}
          select
          SelectProps={{ native: true }}
        >
          <option value="" />
          <option value="1">Me only</option>
        </Field>
      </SearchFormBase>
    );
  }
}

export default reduxForm({
  form: 'OnlineSurveyClassSearchForm',
  enableReinitialize: true,
})(withStyles(styles)(ClassSearchForm));
