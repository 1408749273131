import React from 'react';
import {
  Dialog,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';

import FilterListIcon from '@material-ui/icons/FilterList';
import ToolBar from 'common/components/ToolBar';
import QuestionSelectionDialogContent from './QuestionSelectionDialogContent';


const useStyles = makeStyles(() => ({
  dialogTitle: {
    paddingBottom: 0,
  },
}));

const QuestionSelectionDialog = ({
  onClose,
  onFinish,
  open = false,
  ...props
}) => {
  const classes = useStyles();
  const [formOpen, setFormOpen] = React.useState(true);
  const handleToggleFormOpen = React.useCallback(() => setFormOpen(!formOpen), [formOpen]);
  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullScreen
      disableBackdropClick
      disableEscapeKeyDown
      {...props}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <ToolBar
          title="Select Questions"
          rightButton={[
            { title: 'Filter list', icon: (<FilterListIcon />), onClick: handleToggleFormOpen },
          ]}
        />
      </DialogTitle>
      <QuestionSelectionDialogContent onClose={onClose} onFinish={onFinish} formOpen={formOpen} />
    </Dialog>
  );
}

export default QuestionSelectionDialog;