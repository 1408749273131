import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  formCardSection: {
    borderBottom: '1px solid #e0e0e0',
  },
});

const FormCardSection = (props) => {
  const { classes, children } = props;
  return (
    <div className={classes.formCardSection}>
      {children}
    </div>
  );
};
FormCardSection.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.node,
};
FormCardSection.defaultProps = {
  children: null,
};

export default withStyles(styles)(FormCardSection);
