import axios from 'axios';
import { LOGOUT_USER } from 'public/redux/account/types';
import Config from 'public/constants/Config';
import store from 'public/redux/store';

const defaultRequestConfig = {
};

const handleException = (e) => {
  if (e.response && e.response.status === 403) {
    store.dispatch({ type: LOGOUT_USER });
  }
};

const getAuthorization = () => {
  const state = store.getState();
  if (state.account && state.account.user && state.account.user.auth_key) {
    return {
      Authorization: `Bearer ${state.account.user.auth_key}`,
    };
  }
  return null;
};

const buildConfig = (config = {}) => {
  const { headers, ...rest } = config;
  return {
    ...defaultRequestConfig,
    ...rest,
    headers: {
      ...getAuthorization(),
      ...headers,
    },
  };
};


export default {
  addApiUrl: relativePath => (`${Config.baseUrl}${relativePath}`),
  get: async (url, config = {}) => {
    try {
      const response = await axios.get(url, buildConfig(config));
      return response;
    } catch (e) {
      handleException(e);
      throw e;
    }
  },
  post: async (url, data, config = {}) => {
    try {
      // console.log(data, buildConfig(config));
      const response = await axios.post(url, data, buildConfig(config));
      return response;
    } catch (e) {
      handleException(e);
      throw e;
    }
  },
  patch: async (url, data, config = {}) => {
    try {
      const response = await axios.patch(url, data, buildConfig(config));
      return response;
    } catch (e) {
      handleException(e);
      throw e;
    }
  },
  put: async (url, data, config = {}) => {
    try {
      return await axios.put(url, data, buildConfig(config));
    } catch (e) {
      handleException(e);
      throw e;
    }
  },
  delete: async (url, config = {}) => {
    try {
      const response = await axios.delete(url, buildConfig(config));
      return response;
    } catch (e) {
      handleException(e);
      throw e;
    }
  },
};
