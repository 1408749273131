import { createStore, applyMiddleware, compose } from 'redux';
// import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import persistState, { mergePersistedState } from 'redux-localstorage';
import adapter from 'redux-localstorage/lib/adapters/localStorage';
import filter from 'redux-localstorage-filter';
import debounce from 'redux-localstorage-debounce';

import { DEV } from 'public/constants/Config';

import rootReducer from './reducers';

const middlewares = [thunk];

if (DEV) {
  // const logger = createLogger({});
  // middlewares.push(logger);
}

const presistedReducer = compose(mergePersistedState())(rootReducer);

const storage = compose(
  debounce(300),
  filter(['account']),
)(adapter(window.localStorage)); // eslint-disable-line

const createStoreWithMiddleware = compose(
  applyMiddleware(...middlewares),
  persistState(storage, 'cte_public'),
)(createStore);

const store = createStoreWithMiddleware(presistedReducer);

export default store;
