import React from 'react';
import clsx from 'clsx';
import filesize from 'filesize';
import { useDropzone } from 'react-dropzone';

import {
  Typography,
  IconButton,
  makeStyles,
  FormControl,
  InputLabel,
  FormHelperText
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


export const MIN_UPLOAD_FILESIZE = 1;
export const MAX_UPLOAD_FILESIZE = 52428800; // 50MiB
export const ACCEPT_UPLOAD_FILE = [
  'image/*',
  '.pdf',
  '.xlsx',
  '.xls',
  '.doc',
  '.docx',
];

const useStyles = makeStyles(theme => ({
  zoneContainer: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(3),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[900],
    borderRadius: theme.spacing(1),
  },
  error: {
    borderColor: theme.palette.error.dark,
  },
  zone: {
    flex: '1 1 auto',
    height: 'auto',
    border: 0,
    padding: theme.spacing(2),
  },
}));

const hasFile = value => (Array.isArray(value) && value.length > 0);
const renderFileNames = files => files.map(f => `${f.name} (${filesize(f.size)})`).join(', ');

const DropzoneFileUpload = ({
  meta: { error, invalid },
  input: {
    onChange, value, ...inputRest
  },
  helperText,
  label = '',
  multiple = false,
  minSize = MIN_UPLOAD_FILESIZE,
  maxSize = MAX_UPLOAD_FILESIZE,
  accept = ACCEPT_UPLOAD_FILE,
  noFileMessage = 'Drag and drop file here, or click to select file.',
  filesText = renderFileNames,
  ...props
}) => {
  const classes = useStyles();
  const onDropAccepted = React.useCallback((files) => {
    onChange(files);
  }, [onChange]);
  const onDropRejected = React.useCallback((files) => {
    console.log(files);
    // TODO: send event
  }, []);
  const onClearFiles = React.useCallback((e) => {
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    onChange([]);
  }, [onChange]);

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple,
    minSize,
    maxSize,
    onDropAccepted,
    onDropRejected,
  });

  return (
    <FormControl {...props} error={invalid}>
      <InputLabel shrink >
        {label}
      </InputLabel>
      <div className={clsx(classes.zoneContainer, invalid ? classes.error : null)}>
        <div className={classes.zone}
          {...getRootProps()}>
          <input {...inputRest} {...getInputProps()} />
          <Typography variant="body1" color="inherit">
            {hasFile(value) ? filesText(value) : noFileMessage}
            {hasFile(value) ?
              <IconButton
                title="Remove"
                onClick={onClearFiles}
              >
                <CloseIcon />
              </IconButton>
              : null}
          </Typography>
        </div>
      </div>
      {error || helperText ? <FormHelperText error={invalid}>{error || helperText}</FormHelperText> : null}
    </FormControl>
  );
};

export default DropzoneFileUpload;
