import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';


const BooleanCheckbox = React.forwardRef(({
  input: { value, onChange },
  label,
  meta: { touched, error },
  ...props
}, ref) => {
  return (
    <FormControl
      component="fieldset"
      error={Boolean(touched && error)}
      {...props}
    >
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            ref={ref}
            color="primary"
            value="true"
            checked={Boolean(value)}
            onChange={(e, checked) => {
              if (checked) {
                onChange(true);
              } else {
                onChange(false);
              }
            }}
          />
        }
      />
      {(touched && error) && <FormHelperText>{error}</FormHelperText>}
    </FormControl >
  );
});

export default BooleanCheckbox;
