import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import actions from 'public/redux/Questions/actions';
import QuestionView from './QuestionView';

const selector = formValueSelector('QuestionUpdateForm');

const mapStateToProps = state => ({
  ...state.QuestionForm,
  type: selector(state, 'type'),
  teacher: selector(state, 'teacher'),
  teacher_id: selector(state, 'teacher_id'),
  initialValues: {
    ...state.QuestionForm.model,
  },
  user: state.account.user,
});
const mapDispatchToProps = {
  fetchOne: actions.fetchOne,
  clearOne: actions.clearOne,
  update: actions.update,
};

// connect with redux
export default connect(mapStateToProps, mapDispatchToProps)(QuestionView);
