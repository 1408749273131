import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import TextField from 'common/components/form/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const ValidateCount = [
  (value) => {
    if (!value) {
      return 'Required';
    }
    const numValue = Number(value);
    // console.log(value, !Number.isInteger(numValue), (numValue <= 0), (numValue > 100));
    if (!Number.isInteger(numValue) || (numValue <= 0) || (numValue > 100)) {
      return 'Please input an integer between 1 and 100';
    }
    return null;
  },
];
class GenerateNewLinksDialog extends Component {
  static propTypes = {
    DialogProps: PropTypes.shape(Dialog.propTypes),
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    DialogProps: {},
    open: false,
  };

  render() {
    const { open, onClose, DialogProps, handleSubmit } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        {...DialogProps}
      >
        <DialogTitle>Generate Invitation Links</DialogTitle>
        <DialogContent>
          <Field
            name="count"
            label="# of links"
            component={TextField}
            validate={ValidateCount}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSubmit}>Create</Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default reduxForm({
  form: 'GenerateNewLinksDialog',
  enableReinitialize: true,
})(GenerateNewLinksDialog);
