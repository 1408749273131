import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import {
  Checkbox,
  TableContainer,
  TableCell,
  TableRow,
  Tooltip
} from '@material-ui/core';

import Done from '@material-ui/icons/Done';
import Remove from '@material-ui/icons/Remove';
import Chat from '@material-ui/icons/Chat';
import GetApp from '@material-ui/icons/GetApp';

import { FormCard } from 'common/components/FormCard';
import { EnhancedTable } from 'common/components/tables';
import ToolBar from 'common/components/ToolBar';

import axios from 'public/helpers/MyAxios';
import ListActions from 'public/redux/OnlineSurveys/students/actions';
import CommonActions from 'public/redux/common/actions';

import StudentEmailListDialog from './StudentEmailListDialog';
import LoadingXlsxDialog from './LoadingXlsxDialog';

const columnData = [
  { id: 'student_id', numeric: false, disablePadding: false, label: 'Student ID', sortable: true },
  { id: 'name', numeric: false, disablePadding: false, label: 'Student Name', sortable: true },
  { id: '', numeric: false, disablePadding: false, label: '', sortable: false },
];

const StudentRow = ({
  rowData,
  selectedRows = [],
  setSelectedRows,
}) => {
  const rowSelected = (selectedRows.findIndex(ele => ele.id === rowData.id) >= 0);
  const handleRowClick = () => {
    if (rowSelected) {
      setSelectedRows(selectedRows.filter(ele => ele.id !== rowData.id));
    } else {
      setSelectedRows(selectedRows.concat([rowData]));
    }
  };
  return (
    <TableRow
      hover
      tabIndex={-1}
      selected={rowSelected}
      aria-checked={rowSelected}>
      <TableCell padding="checkbox">
        <Checkbox color="primary" checked={rowSelected} onClick={handleRowClick} />
      </TableCell>
      <TableCell>{rowData.student_id}</TableCell>
      <TableCell>{rowData.name}</TableCell>
      <TableCell>
        {rowData.completed ? <Tooltip title="Done."><Done /></Tooltip> : <Tooltip title="Not yet done."><Remove /></Tooltip>}
      </TableCell>
    </TableRow>);
}
const renderRow = ({ rowData, ...props }) => <StudentRow key={`${rowData.id}`} rowData={rowData} {...props} />;

const mapStateToProps = ({ OnlineSurveyStudents }) => OnlineSurveyStudents;
const StudentTable = ({ surveyId }) => {
  const dispatch = useDispatch();
  const { page, pageSize, data, totalElements, sort } = useSelector(mapStateToProps);
  const [loadingXlsx, setLoadingXlsx] = React.useState(false);
  const [xlsxProgess, setXlsxProgess] = React.useState(null);
  const [showEmailList, setShowEmailList] = React.useState(false);

  const handleSortChange = React.useCallback(sort => dispatch(ListActions.sortChange(sort)).catch(console.log), [dispatch]);
  const handlePageChange = React.useCallback(page => dispatch(ListActions.pageChange(page)).catch(console.log), [dispatch]);
  const downloadXlsx = React.useCallback(async () => {
    setLoadingXlsx(true);
    try {
      const response = await axios.get(axios.addApiUrl(`/staff/online-survey/download-student-list?id=${surveyId}`), {
        responseType: 'blob',
        onDownloadProgress: e => setXlsxProgess({ loaded: e.loaded, total: e.total }),
      });
      fileDownload(response.data, `Online_Survey_${surveyId}_StudentList.xlsx`);
    } catch (e) {
      dispatch(CommonActions.setSnackbarMessage(`Error: ${e.message}`))
    } finally {
      setLoadingXlsx(false);
      setXlsxProgess(null);
    }
  }, [dispatch, surveyId]);
  const handleShowEmailList = React.useCallback(() => setShowEmailList(true), []);
  const handleHideEmailList = React.useCallback(() => setShowEmailList(false), []);

  React.useEffect(() => {
    dispatch(ListActions.searchFormSubmit()).catch(console.log);
  }, [dispatch]);

  return (
    <FormCard>
      <ToolBar
        title="Students"
        rightButton={[
          { title: 'Generate Non-completed Student Email List', icon: (<Chat />), onClick: handleShowEmailList },
          { title: 'Download Student List', icon: (<GetApp />), onClick: downloadXlsx },
        ]}
      />
      <LoadingXlsxDialog open={loadingXlsx} progress={xlsxProgess} />
      <StudentEmailListDialog open={showEmailList} onClose={handleHideEmailList} surveyId={surveyId} />
      <TableContainer>
        <EnhancedTable
          totalElements={totalElements}
          pageSize={pageSize}
          page={page}
          sort={sort}
          columnData={columnData}
          data={data}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          renderRow={renderRow}
        />
      </TableContainer>
    </FormCard>
  )
}

export default StudentTable;
