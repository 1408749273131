import range from 'lodash/range';
import cx from 'clsx'
import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LastPage from '@material-ui/icons/LastPage';
import FirstPage from '@material-ui/icons/FirstPage';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));

const TablePaginationActions = ({
  count, page, rowsPerPage,
  onChangePage,
  className,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const lastPage = Math.max(0, Math.ceil(count / rowsPerPage) - 1);
  const handleFirstPageButtonClick = event => onChangePage(event, 0);
  const handleBackButtonClick = event => onChangePage(event, page - 1);
  const handleNextButtonClick = event => onChangePage(event, page + 1);
  const handleLastPageButtonClick = event => onChangePage(event, lastPage);
  const handlePageSelect = event => onChangePage(event, Number(event.target.value));
  const lowestPage = Math.max(page - 50, 0);
  const highestPage = Math.min(page + 50, lastPage);
  return (
    <div className={cx(classes.root, className)}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <TextField
        select
        SelectProps={{ native: true }}
        onChange={handlePageSelect}
        value={page}
        variant="outlined"
        margin="dense"
      >
        {
          range(lowestPage, highestPage + 1).map(i => <option key={`p${i}`} value={i}>{i + 1}</option>)
        }
      </TextField>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
export default TablePaginationActions;
