import React from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';

import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingXlsxDialog = ({ open, progress }) => (
  <Dialog open={open} disableBackdropClick disableEscapeKeyDown>
    <DialogTitle>Loading XLSX</DialogTitle>
    <DialogContent>
      <CircularProgress size={48} />
      {progress ? <Typography>{filesize(progress.loaded)} / {filesize(progress.total)}</Typography> : null}
    </DialogContent>
  </Dialog>
);
LoadingXlsxDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  progress: PropTypes.shape({
    loaded: PropTypes.number,
    total: PropTypes.number,
  }),
};
LoadingXlsxDialog.defaultProps = {
  progress: null,
};

export default LoadingXlsxDialog;
