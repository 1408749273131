import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import TextField from 'common/components/form/TextField';

import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { handle422 } from 'common/helpers/ReduxFormHelper';
import RichTextMarkdown from 'common/components/form/RichTextMarkdown';

import { FormCard, FormCardSection, FormCardSectionFieldRow, FormCardContent } from 'common/components/FormCard';
import LocalRadioGroup from 'common/components/form/LocalRadioGroup';
import Toolbar from 'common/components/ToolBar';

import TeacherSelectionDialog from 'public/components/Selection/TeacherSelectionDialog';

const validate = (values) => {
  const errors = {};
  if (!values.type) {
    errors.type = 'Required';
  }
  if (values.type === 'RATE' && !values.max_rate) {
    errors.max_rate = 'Required';
  }
  if (!values.content) {
    errors.content = 'Required';
  }
  if (values.status === null || values.status === undefined || values.status === '') {
    errors.status = 'Required';
  }
  if (Number(values.status) === 10 && !values.item_no) {
    errors.item_no = 'Required';
  }
  return errors;
};

class QuestionCreateView extends Component {
  static propTypes = {
    // withRouter
    history: PropTypes.shape({
      replace: PropTypes.func,
    }).isRequired,
    // redux form
    change: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    // redux connect   
    create: PropTypes.func.isRequired,
    type: PropTypes.string,
    teacher: PropTypes.shape({}),
  }

  static defaultProps = {
    type: null,
    teacher: null,
  }

  state = {
    showTeachers: false,
  }

  async onSubmit(values) {
    const { create, history } = this.props;
    try {
      const data = await create(values);
      history.replace(`/staff/question/${data.id}`);
    } catch (e) {
      handle422(e.response);
      throw e;
    }
  }


  render() {
    const { type, teacher, handleSubmit, submitting, pristine, change } = this.props;
    const { showTeachers } = this.state;
    const onSubmit = handleSubmit(values => this.onSubmit(values));
    return (
      <div>
        <Toolbar
          showBackButton
          color="default"
          title="New Question"
        />
        <TeacherSelectionDialog
          open={showTeachers}
          onClose={() => this.setState({ showTeachers: false })}
          onFinish={(t) => {
            change('teacher_id', t.id);
            change('teacher', t);
            this.setState({ showTeachers: false });
          }}
        />
        <form onSubmit={onSubmit}>
          <FormCard>
            <CardContent>
              <FormCardSection>
                <FormCardSectionFieldRow title="Item No.">
                  <Typography color="primary">(To be assigned by admin)</Typography>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Type">
                  <Field
                    name="type"
                    component={LocalRadioGroup}
                    fullWidth
                    row
                    options={[
                      { label: 'RATE', value: 'RATE' },
                      { label: 'OPEN', value: 'OPEN' },
                    ]}
                  />
                  {
                    type === 'RATE' ?
                      <Field
                        name="max_rate"
                        label="Max Rating"
                        component={TextField}
                        select
                        SelectProps={{ native: true }}
                        style={{ minWidth: 100 }}
                      >
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                      </Field>
                      : null
                  }
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Subtype">
                  <Field
                    name="subtype"
                    component={TextField}
                    select
                    SelectProps={{ native: true }}
                    style={{ minWidth: 100 }}
                    helperText="(Optional)"
                  >
                    <option value="">&nbsp;</option>
                    <option value="U">U</option>
                    <option value="C">C</option>
                    <option value="O">O</option>
                  </Field>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Content">
                  <Field
                    name="content"
                    component={RichTextMarkdown}
                    fullWidth
                  />
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Associated Teacher">
                  {teacher ? <Typography variant="body1" gutterBottom>{teacher.name} ({teacher.email})</Typography> : <Typography variant="body1" gutterBottom >No Associated Teacher</Typography>}
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => this.setState({ showTeachers: true })}
                  >
                    Select
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => {
                      change('teacher_id', null);
                      change('teacher', null);
                    }}
                  >
                    Remove
                  </Button>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Status">
                  <Typography>Draft</Typography>
                </FormCardSectionFieldRow>
              </FormCardSection>
            </CardContent>
            <CardActions>
              <FormCardContent>
                <Button variant="contained" color="primary" type="submit" disabled={submitting}>SAVE</Button>
                <Button color="primary" onClick={this.props.reset} disabled={pristine}>RESET</Button>
              </FormCardContent>
            </CardActions>
          </FormCard>
        </form>
      </div >);
  }
}


export default withRouter(reduxForm({
  form: 'QuestionCreateForm',
  initialValues: {
    item_no: null,
    content: '',
    type: 'RATE',
    subtype: null,
    max_rate: 6,
    status: 0,
  },
  validate,
})(QuestionCreateView));
