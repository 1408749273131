import 'typeface-roboto';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import App from './public/App';
// import App from './admin/App';

ReactDOM.render(
  <App />,
  document.getElementById('root'), //eslint-disable-line
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
