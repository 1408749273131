
import { connect } from 'react-redux';
// import { formValueSelector } from 'redux-form';
import Actions from 'public/redux/Questionnaires/Sections/actions';
import SectionListing from './SectionListing';

// const selector = formValueSelector('SectionListForm');
// const mapStateToProps = ({ QuestionnaireForm, QSections, ...state }) => ({
const mapStateToProps = ({ QuestionnaireForm, QSections }) => ({
  QSections,
  model: QuestionnaireForm.model,
  initialValues: {
    sections: QSections.data,
  },
  // sections: selector(state, 'sections'),
});

const mapDispatchToProps = {
  searchFormSubmit: Actions.searchFormSubmit,
  batchUpdate: Actions.batchUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionListing);
