import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';
import Refresh from '@material-ui/icons/Refresh';

import Link from 'common/components/Link';
import { FormCard, FormCardSection, FormCardSectionFieldRow } from 'common/components/FormCard';
import PageLoading from 'common/components/PageLoading';
import Toolbar from 'common/components/ToolBar';
import Config from 'public/constants/Config';

import ReportButtonsPanel from './ReportButtonsPanel';

const toMoment = (v) => {
  if (!v) {
    return null;
  }
  if (v._isAMomentObject) { // eslint-disable-line
    return v;
  }
  return moment(v);
};

const styles = theme => ({
  qrCard: {
    width: 320,
    margin: `${theme.spacing(1)}px auto`,
  },
  qrImg: {
    maxWidth: '100%',
  },
  formCard: {
    marginBottom: theme.spacing(2),
  },
});

class OnlineSurveyView extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }).isRequired,
    classes: PropTypes.shape({}).isRequired,
    // redux connect
    fetchOne: PropTypes.func.isRequired,
    clearOne: PropTypes.func.isRequired,
    init: PropTypes.bool.isRequired,
    model: PropTypes.shape({}).isRequired,
  }

  static defaultProps = {
  }

  componentDidMount() {
    const { match, model, init } = this.props;
    // console.log(this.props);
    if (!init || (Number(match.params.id) !== Number(model.id))) {
      this.props.clearOne()
        .then(this.props.fetchOne(match.params.id));
    }
  }

  renderPublished = (row) => {
    switch (Number(row.published)) {
      case -1:
        return <span>Cancelled</span>;
      case 0:
        return <span>Draft</span>;
      case 1:
        return <strong>Published</strong>;
      case 2:
        return <strong>Completed</strong>;
      default:
        return null;
    }
  }

  render() {
    const {
      classes,
      init, model,
      fetchOne,
    } = this.props;
    if (!init) {
      return (<PageLoading />);
    }
    return (
      <div>
        <Toolbar
          showBackButton
          color="default"
          title={`Course Evaluation: ${model.id}`}
          rightButton={[
            { title: 'Refresh', icon: (<Refresh />), onClick: () => fetchOne(model.id) },
          ]}
        />
        <Card className={classes.qrCard}>
          <img src={`${Config.baseUrl}/staff/online-survey/qr?id=${model.id}`} alt="QR Code" className={classes.qrImg} />
        </Card>
        <div className={classes.formCard}>
          <FormCard>
            <CardContent>
              <FormCardSection>
                <FormCardSectionFieldRow title="Activity">
                  <Typography>
                    <Link to={`/coordinator/activity/${model.class_id}`}>
                      {model.class ? model.class.name : model.class_id}
                    </Link>
                  </Typography>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Course Code">
                  <Typography>{model.class.course ? model.class.course.label : null}</Typography>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Academic Year">
                  <Typography>{model.class.year}</Typography>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Questionnare">
                  <Link to={`/coordinator/survey/preview/${model.id}`} target="_blank" rel="noreferer noopener">
                    {model.questionnaire.name}
                  </Link>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Name">
                  <Typography>{model.name}</Typography>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Department">
                  <Typography>{model.deparmtent ? model.deparmtent.label : null}</Typography>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Start">
                  <Typography>{toMoment(model.start).format('YYYY-MM-DD HH:mm:00')}</Typography>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="End">
                  <Typography>{toMoment(model.end).format('YYYY-MM-DD HH:mm:00')}</Typography>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Passcode">
                  <Typography>{model.passcode}</Typography>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Overall Course Evalation">
                  <Typography>{model.overall_cte ? 'Yes' : 'No'}</Typography>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Remarks">
                  <Typography>{model.remarks}</Typography>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Status">
                  <Typography>{this.renderPublished(model)}</Typography>
                </FormCardSectionFieldRow>
              </FormCardSection>
              <FormCardSection>
                <FormCardSectionFieldRow title="Created At">
                  <Typography variant="body1">{moment(model.created_at).format()} by {model.created_by}</Typography>
                </FormCardSectionFieldRow>
                <FormCardSectionFieldRow title="Last Updated At">
                  <Typography variant="body1">{moment(model.updated_at).format()} by {model.updated_by}</Typography>
                </FormCardSectionFieldRow>
              </FormCardSection>
            </CardContent>
          </FormCard>
        </div>
        <div className={classes.formCard}>
          <FormCard>
            <CardContent>
              <Typography variant="h6">Download Report</Typography>
              {
                (Number(model.published) === 2) ? // completed
                  <ReportButtonsPanel activity={model.class} surveyId={model.id} />
                  : <Typography>This CTE is not completed yet.</Typography>
              }
            </CardContent>
          </FormCard>
        </div>
      </div >);
  }
}

export default withStyles(styles)(OnlineSurveyView);
