import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

class ControlCheckbox extends Component { // eslint-disable-line
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.any,
    }).isRequired,
    meta: PropTypes.shape({
      error: PropTypes.any,
    }).isRequired,
    label: PropTypes.string,
    InputProps: PropTypes.shape({}),
  };

  static defaultProps = {
    label: null,
    InputProps: null,
  };

  render() {
    const {
      input,
      meta,
      label,
      InputProps,
      ...props
    } = this.props;
    return (
      <FormControl error={Boolean(meta.error)} {...props}>
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              {...InputProps}
              value={String(input.value)}
              checked={Boolean(input.value)}
              onChange={(event, isInputChecked) => {
                input.onChange(isInputChecked);
              }}
            />}
        />
        {meta.error ? <FormHelperText error>{meta.error}</FormHelperText> : null}
      </FormControl>
    );
  }
}

export default ControlCheckbox;
