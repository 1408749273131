import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  TableContainer,
  IconButton,
  Paper,
  Tooltip,
  Collapse,
  TableCell,
  TableRow,
  Checkbox,
  makeStyles,
} from '@material-ui/core';

import FilterList from '@material-ui/icons/FilterList';
import Edit from '@material-ui/icons/Edit';
import AddBox from '@material-ui/icons/AddBox';
import Delete from '@material-ui/icons/Delete';
import Done from '@material-ui/icons/Done';
import Flag from '@material-ui/icons/Flag';

import ConfirmButton from 'common/components/ConfirmButton';
import { EnhancedTable } from 'common/components/tables';
import ToolBar from 'common/components/ToolBar';
import Loading from 'common/components/Loading';

import ListActions from 'public/redux/OnlineSurveys/actions';
import CommonActions from 'public/redux/common/actions';

import OnlineSurveySearchForm from './OnlineSurveySearchForm';

const columnData = [
  { id: 'id', numeric: false, disablePadding: false, padding: 'checkbox', label: 'ID', sortable: true },
  { id: 'name', numeric: false, disablePadding: false, padding: 'checkbox', label: 'Name', sortable: true },
  { id: 'c.name', numeric: false, disablePadding: false, padding: 'checkbox', label: 'Activity', sortable: true },
  { id: 'course.code', numeric: false, disablePadding: false, padding: 'checkbox', label: 'Course Code', sortable: true },
  { id: 'c.year', numeric: false, disablePadding: false, padding: 'checkbox', label: 'Academic Year', sortable: true },
  { id: 'q.name', numeric: false, disablePadding: false, padding: 'checkbox', label: 'Questionnaire', sortable: true },
  { id: 'department_code', numeric: false, disablePadding: false, padding: 'checkbox', label: 'Department Code', sortable: true },
  { id: 'overall_cte', numeric: false, disablePadding: false, padding: 'checkbox', label: 'Overall Course Evaluation', sortable: true },
  { id: 'published', numeric: false, disablePadding: false, padding: 'checkbox', label: 'Status', sortable: true },
  { id: 'start', numeric: true, disablePadding: false, padding: 'checkbox', label: 'Start', sortable: true },
  { id: 'end', numeric: true, disablePadding: false, padding: 'checkbox', label: 'End', sortable: true },
  { id: 'actions', numeric: false, disablePadding: false, padding: 'checkbox', label: '', sortable: false },
];

const useStyles = makeStyles(() => ({
  paper: { position: 'relative' },
}));

const formatDatetime = datetime => (datetime || moment(datetime).format());
const renderPublished = (row) => {
  switch (Number(row.published)) {
    case -1:
      return <span>Cancelled</span>;
    case 0:
      return <span>Draft</span>;
    case 1:
      return <strong>Published</strong>;
    case 2:
      return <strong>Completed</strong>;
    default:
      return null;
  }
};

const SuervyRow = ({
  rowData,
  selectedRows,
  setSelectedRows,
}) => {
  const dispatch = useDispatch();
  const isSelected = id => (selectedRows.findIndex(ele => ele.id === id) >= 0);
  const rowSelected = isSelected(rowData.id);
  const handleRowClick = () => {
    if (rowSelected) {
      setSelectedRows(selectedRows.filter(ele => ele.id !== rowData.id));
    } else {
      setSelectedRows(selectedRows.concat([rowData]));
    }
  };
  return (
    <TableRow
      hover
      tabIndex={-1}
      selected={rowSelected}
      aria-checked={rowSelected}>
      <TableCell padding="checkbox">
        <Checkbox color="primary" checked={rowSelected} onClick={handleRowClick} />
      </TableCell>
      <TableCell padding="checkbox">{rowData.id}</TableCell>
      <TableCell padding="checkbox">{rowData.name}</TableCell>
      <TableCell padding="checkbox">
        <Link to={`/staff/activity/${rowData.class_id}`}>
          {rowData.class?.name}
        </Link>
      </TableCell>
      <TableCell padding="checkbox">
        {rowData.class?.course?.code}
      </TableCell>
      <TableCell padding="checkbox">
        {rowData.class?.year}
      </TableCell>
      <TableCell padding="checkbox">
        <Link to={`/staff/questionnaire/${rowData.questionnaire_id}`}>
          {rowData.questionnaire.name}
        </Link>
      </TableCell>
      <TableCell padding="checkbox">{rowData.department_code}</TableCell>
      <TableCell padding="checkbox">{rowData.overall_cte ? 'Yes' : 'No'}</TableCell>
      <TableCell padding="checkbox">{renderPublished(rowData)}</TableCell>
      <TableCell align="right" padding="checkbox">{formatDatetime(rowData.start)}</TableCell>
      <TableCell align="right" padding="checkbox">{formatDatetime(rowData.end)}</TableCell>
      <TableCell padding="checkbox">
        <Tooltip title="View Details">
          <IconButton component={Link} to={`/staff/online-survey/${rowData.id}`} aria-label="View Details"><Edit /></IconButton>
        </Tooltip>
        <Tooltip title="Publish">
          <span>
            <ConfirmButton
              ButtonComponent={IconButton}
              disabled={Boolean(rowData.published)}
              onConfirm={async () => {
                try {
                  await dispatch(ListActions.update({ id: rowData.id, published: 1 }));
                  dispatch(CommonActions.setSnackbarMessage(`${rowData.id} published.`));
                } catch (e) {
                  dispatch(CommonActions.setSnackbarMessage(`Failed: ${(e.response?.data?.message || e)}`));
                }
              }}
              message={`Confirm to Publish Survey "${rowData.id}"?`}
            >
              <Flag />
            </ConfirmButton>
          </span>
        </Tooltip>
        <Tooltip title="Mark Completed">
          <ConfirmButton
            ButtonComponent={IconButton}
            disabled={Number(rowData.published) !== 1 || moment(rowData.end).isAfter(moment())}
            onConfirm={async () => {
              try {
                await dispatch(ListActions.update({ id: rowData.id, published: 2 }));
                dispatch(CommonActions.setSnackbarMessage(`${rowData.id} mark Completed.`));
              } catch (e) {
                dispatch(CommonActions.setSnackbarMessage(`Failed: ${(e.response?.data?.message || e)}`));
              }
            }}
            message={`Confirm to mark Survey "${rowData.id}" as Completed?`}
          >
            <Done />
          </ConfirmButton>
        </Tooltip>
        <Tooltip title="Delete">
          <span>
            <ConfirmButton
              ButtonComponent={IconButton}
              disabled={Boolean(rowData.published)}
              onConfirm={async () => {
                try {
                  await dispatch(ListActions.delete(rowData));
                  dispatch(CommonActions.setSnackbarMessage(`${rowData.id} deleted.`));
                } catch (e) {
                  dispatch(CommonActions.setSnackbarMessage(`Failed: ${(e.response?.data?.message || e)}`));
                }
              }}
              message={`Confirm to delete Survey "${rowData.id}"?`}
            >
              <Delete />
            </ConfirmButton>
          </span>
        </Tooltip>
      </TableCell>
    </TableRow>);
};
const renderRow = ({ rowData, ...props }) => <SuervyRow key={`${rowData.id}`}  {...props} rowData={rowData} />;

const mapStateToProps = ({ OnlineSurveys }) => OnlineSurveys;
const OnlineSurveyList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { init, submitting, page, pageSize, form, data, totalElements, sort, } = useSelector(mapStateToProps);
  const [formOpen, setFormOpen] = React.useState(true);
  const handleSearch = React.useCallback(values => dispatch(ListActions.searchFormSubmit(values)).catch(console.log), [dispatch]);
  const handleSortChange = React.useCallback(sort => dispatch(ListActions.sortChange(sort)).catch(console.log), [dispatch]);
  const handlePageChange = React.useCallback(page => dispatch(ListActions.pageChange(page)).catch(console.log), [dispatch]);
  React.useEffect(() => {
    if (!init && !submitting) {
      dispatch(ListActions.searchFormSubmit({})).catch(console.log);
    }
  }, [dispatch, init, submitting]);
  return (
    <div>
      <ToolBar
        color="default"
        title="Online CTE"
        rightButton={[
          { title: 'Filter list', icon: (<FilterList />), onClick: () => setFormOpen(!formOpen) },
          { title: 'Create New', icon: (<AddBox />), ButtonProps: { component: Link, to: '/staff/online-surveys/create' } },
        ]}
      />
      <Collapse in={formOpen}>
        <OnlineSurveySearchForm
          initialValues={form}
          onSubmit={handleSearch}
        />
      </Collapse>
      <Paper className={classes.paper}>
        <TableContainer>
          <EnhancedTable
            totalElements={totalElements}
            pageSize={pageSize}
            page={page}
            sort={sort}
            columnData={columnData}
            data={data}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange}
            renderRow={renderRow}
          />
        </TableContainer>
        <Loading loading={submitting} />
      </Paper>
    </div>
  );
};

export default OnlineSurveyList;
