import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { makeStyles } from '@material-ui/core';

import TextField from 'common/components/form/TextField';
import SearchFormBase from 'common/components/SearchFormBase';

const useStyles = makeStyles  (theme => ({
  field: {
    marginRight: theme.spacing(2),
    minWidth: 150,
  },
}));

const EMPTY_FORM = {
  status: '',
  name: '',
  email: '',
  is_external: '',
  department_code: '',
};

const TeacherSelectionDialogSearchForm = ({
  handleSubmit,
  onSubmit,
}) => {
  const classes = useStyles();
  const handleClear = React.useCallback(() => onSubmit(EMPTY_FORM), [onSubmit]);
  return (
    <SearchFormBase onSubmit={handleSubmit} onClear={handleClear}>
      <Field
        component={TextField}
        className={classes.field}
        label="Email"
        name="email"
      />
      <Field
        component={TextField}
        className={classes.field}
        label="Name"
        name="name"
      />
      <Field
        component={TextField}
        className={classes.field}
        label="External Teacher"
        name="is_external"
        select
        SelectProps={{ native: true }}
      >
        <option value="" />
        <option value="1">Yes</option>
        <option value="0">No</option>
      </Field>
      <Field
        component={TextField}
        className={classes.field}
        label="Deparmtent Code"
        name="department_code"
      />
      <Field
        component={TextField}
        className={classes.field}
        label="Status"
        name="status"
        select
        SelectProps={{ native: true }}
      >
        <option value="" />
        <option value="10">ACTIVE</option>
        <option value="0">INACTIVE</option>
      </Field>
    </SearchFormBase>
  );
}

export default reduxForm({
  form: 'TeacherSelectionDialogSearchForm',
  enableReinitialize: true,
})(TeacherSelectionDialogSearchForm);
