import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { reduxForm, SubmissionError } from 'redux-form';
import scrollToComponent from 'react-scroll-to-component';


import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

// import ConfirmButton from 'common/components/ConfirmButton';
import Questionnaire from 'common/components/questionnaire/Questionnaire';
import Alert from 'common/components/Alert';
import axios from 'public/helpers/MyAxios';
import GA from 'public/helpers/MyGA';

const styles = theme => ({
  wrapper: {
    padding: theme.spacing(2),
    flex: 1,
    display: 'flex',
    justify: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  container: {
    margin: '0 auto',
    flex: 1,
    maxWidth: 1200,
  },
  header: {
    padding: theme.spacing(2),
  },
  submitPanel: {
    padding: theme.spacing(2),
  },
  cancelBtn: {
    marginRight: 10,
  },
  notice: {
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(2),
  },
});

const isEmpty = val => Boolean(!val && !Number.isInteger(val));

class SurveyViewForm extends Component { // eslint-disable-line
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }).isRequired,
    // redux-form
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
    // redux connect
    fetchOne: PropTypes.func.isRequired,
    clearOne: PropTypes.func.isRequired,
    init: PropTypes.bool.isRequired,
    model: PropTypes.shape({}).isRequired,
  };

  static defaultProps = {
    error: null,
  }

  constructor(props) {
    super(props);
    this.submitDraftSurvey = this.submitDraftSurvey.bind(this);
    this.submitSurvey = this.submitSurvey.bind(this);
  }

  state = {
    passcode: null,
    alertMsg: '',
    draft: false,
    // forceRedo: false,
  }

  componentDidMount() {
    const { match, fetchOne } = this.props;
    fetchOne(match.params.id)
      .catch(() => this.setState({
        alertMsg: 'Survey not found or is closed.',
      }));
  }

  componentWillUnmount() {
    this.props.clearOne();
  }

  handleCancel = () => {
    // console.log('clicking cancel button', this.props);
    this.setState({ draft: false });
    scrollToComponent(this.surveycontainer, { align: 'top', offset: -56, duration: 500, ease: 'linear' });
  };

  async submitDraftSurvey(values) {
    const { model = {} } = this.props;
    const { questionnaire: { sections } } = model;
    const errors = {};
    let hasErrors = false;
    sections.forEach(sect => sect.items.forEach((item) => {
      if (item.required && isEmpty(values[`item${item.id}`])) {
        errors[`item${item.id}`] = 'You must answer the above question.';
        hasErrors = true;
      }
    }));
    if (hasErrors) {
      throw new SubmissionError({
        _error: 'There is some unanswered questions',
        ...errors,
      });
    }
    // const items = [];
    // Object.entries(values).forEach((o) => {
    //   const key = o[0];
    //   const value = o[1];
    //   if (key.startsWith('item')) {
    //     const itemId = Number(key.substring(4));
    //     items.push({
    //       item_id: itemId,
    //       rate: Number.isInteger(value) ? value : null,
    //       text: String(value),
    //     });
    //   }
    // });
    // const params = {
    //   survey_id: model.id,
    //   items,
    // };
    this.setState({ draft: true });
    scrollToComponent(this.surveycontainer, { align: 'top', offset: -56, duration: 500, ease: 'linear' });
    // console.log('draftparam', params);
  }

  async submitSurvey(values) {
    const { model = {}, history } = this.props;
    const { questionnaire: { sections } } = model;
    const errors = {};
    let hasErrors = false;
    sections.forEach(sect => sect.items.forEach((item) => {
      if (item.required && isEmpty(values[`item${item.id}`])) {
        errors[`item${item.id}`] = 'You must answer the above question.';
        hasErrors = true;
      }
    }));
    if (hasErrors) {
      throw new SubmissionError({
        _error: 'There is some unanswered questions',
        ...errors,
      });
    }

    const items = [];
    Object.entries(values).forEach((o) => {
      const key = o[0];
      const value = o[1];
      if (key.startsWith('item')) {
        const itemId = Number(key.substring(4));
        items.push({
          item_id: itemId,
          rate: Number.isInteger(value) ? value : null,
          text: String(value),
        });
      }
    });
    const params = {
      survey_id: model.id,
      items,
    };

    await axios.post(axios.addApiUrl('/survey/submit'), params);
    GA.event({ category: 'Student Survey', action: 'Submit Survey', label: `Completed Survey ${model.name}` });
    history.push('/survey');
    // this.setState({ draft: false });
    // console.log('submitparam', params);
  }

  renderAlert() {
    return (
      <Alert
        open={Boolean(this.state.alertMsg)}
        message={this.state.alertMsg}
        onClose={() => this.props.history.replace('/survey')}
      />
    );
  }

  render() {
    // console.log(this.props);
    const { init, model, classes, handleSubmit, error } = this.props;
    const { passcode, draft } = this.state;
    // console.log(init, model);
    if (!init) {
      return (
        <div className={classes.wrapper}>
          {this.renderAlert()}
          <div className={classes.container}>
            <CircularProgress size={72} /><Typography variant="body1">Loading</Typography>
          </div>
        </div>
      );
    }
    if (model.passcode && model.passcode !== passcode) {
      return (
        <div className={classes.wrapper}>
          {this.renderAlert()}
          <div className={classes.container}>
            <Card>
              <CardHeader title="This survey / evaluation required a passcode." />
              <CardContent>
                <TextField
                  name="passcode"
                  onChange={e => this.setState({ passcode: e.target.value })}
                />
              </CardContent>
            </Card>
          </div>
        </div>
      );
    }
    if (model.answered) {
      return (
        <div className={classes.wrapper}>
          {this.renderAlert()}
          <div className={classes.container}>
            <div className={classes.header}>
            <Typography variant="h4" component="h1" color="primary">{model.name}</Typography>
              <Typography variant="h5" component="h2">Activity: {model.class?.name}</Typography>
            </div>
            <Card className={classes.notice} raised>
              <CardHeader title={<Typography color="primary" variant="h6">You have already completed this survey. </Typography>} />
              {/* <CardActions><Button variant="contained" color="primary" onClick={() => this.setState({ forceRedo: true })}>Start the survey over</Button></CardActions> */}
            </Card>
          </div>
        </div>
      );
    }

    const onDraft = handleSubmit(this.submitDraftSurvey);
    const onSubmit = handleSubmit(this.submitSurvey);
    return (
      <div className={classes.wrapper} ref={(surveycontainer) => { this.surveycontainer = surveycontainer; }}>
        {this.renderAlert()}
        <form className={classes.container}>
          <div className={classes.header}>
            <Typography variant="h4" component="h1" color="primary">{model.name}</Typography>
            <Typography variant="h5" component="h2">Activity: {model.class?.name}</Typography>
          </div>
          {
            draft ?
              (
                <Card className={classes.notice} raised>
                  <CardHeader title={<Typography color="primary" variant="headline">Please check your answers again before submission.</Typography>} />
                </Card>
              ) : null
          }
          <Questionnaire model={model.questionnaire} readOnly={draft} />
          <div className={classes.submitPanel}>
            {error ? <Typography color="error">{error}</Typography> : null}
            {draft ?
              <div>
                <Button
                  variant="contained"
                  size="large"
                  color="default"
                  className={classes.cancelBtn}
                  onClick={() => this.handleCancel()}
                >
                  Cancel
                </Button>
                <Button
                  onClick={onSubmit}
                  // onConfirm={onSubmit}
                  // message="Confirm to submit?"
                  variant="contained"
                  size="large"
                  color="primary"
                // DialogProps={{ maxWidth: 'md', fullWidth: true }}
                >
                  Submit
                </Button>
              </div>
              :
              <Button
                onClick={onDraft}
                variant="contained"
                size="large"
                color="primary"
              >
                Submit
              </Button>
            }
          </div>
        </form>
      </div >
    );
  }
}

export default reduxForm({
  form: 'SurveyViewForm',
})(withRouter(withStyles(styles)(SurveyViewForm)));
