import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const PaginationText = ({
  page,
  numberOfElements,
  totalElements,
  pageSize,
  ...rest
}) => {
  const start = (totalElements > 0) ? ((page * pageSize) + 1) : 0;
  const end = (totalElements > 0) ? ((page * pageSize) + numberOfElements) : 0;
  return (<Typography variant="caption" {...rest} >Showing {start} - {end} of {totalElements} items.</Typography>);
};

PaginationText.propTypes = {
  page: PropTypes.number.isRequired,
  numberOfElements: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default PaginationText;
