import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import ToolBar from 'common/components/ToolBar';

import ActiviyList from './Wizard/ClassList';
import QuestionnaireList from './Wizard/QuestionnaireList';
import SurveyForm from './Wizard/SurveyForm';

class OnlineSurveyCreateView extends Component {
  static propTypes = {
    history: PropTypes.shape({}).isRequired,
    batchCreate: PropTypes.func.isRequired,
  }

  state = {
    activeStep: 0,
    // TODO: use redux form?
    classes: [],
    questionnaire: null,
  }

  goToStep(step) {
    this.setState({ activeStep: step });
  }

  renderPeriodForm() {
    const { classes, questionnaire } = this.state;
    const { history, batchCreate } = this.props;
    return (
      <SurveyForm
        selectedClasses={classes}
        selectedQuestionnaire={questionnaire}
        onSubmit={batchCreate}
        onSubmitSuccess={() => { history.replace('/staff/online-surveys'); }}
      />
    );
  }
  renderQuestionnaires() {
    return (
      <QuestionnaireList
        onSelected={(selected) => {
          this.setState({
            activeStep: 2,
            questionnaire: selected,
          });
        }}
      />
    );
  }

  renderClasses() {
    return (
      <ActiviyList
        onSelected={(selected) => {
          this.setState({
            activeStep: 1,
            classes: selected,
          });
        }}
      />
    );
  }

  renderStepsContent(step) {
    switch (step) {
      case 2:
        return this.renderPeriodForm();
      case 1:
        return this.renderQuestionnaires();
      case 0:
      default:
        return this.renderClasses();
    }
  }
  renderSteps() { // eslint-disable-line
    const steps = [];
    const { classes, questionnaire } = this.state;
    steps.push(
      <Step key="0" completed={Boolean(classes.length)}>
        <StepButton onClick={() => this.goToStep(0)} >
          <StepLabel>Select Activities</StepLabel>
        </StepButton>
      </Step>
    );
    steps.push(
      <Step key="1" completed={Boolean(questionnaire)}>
        <StepButton onClick={() => this.goToStep(1)}>
          <StepLabel>Select Questionnaire</StepLabel>
        </StepButton>
      </Step>
    );
    steps.push(
      <Step key="2" onClick={() => this.goToStep(2)}>
        <StepButton>
          <StepLabel>Select Evaluation Period</StepLabel>
        </StepButton>
      </Step>
    );
    return steps;
  }

  render() {
    const { activeStep } = this.state;
    return (
      <div>
        <ToolBar
          color="default"
          title="Create Online CTE"
          showBackButton
        />
        <Paper>
          <Stepper nonLinear activeStep={activeStep}>
            {this.renderSteps()}
          </Stepper>
          {this.renderStepsContent(activeStep)}
        </Paper>
      </div>
    );
  }
}

export default OnlineSurveyCreateView;
