import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';

import RichText from 'common/components/RichText';
import ItemRating from 'common/components/form/ItemRating';
import TextField from 'common/components/form/TextField';

const useStyles = makeStyles(theme => ({
  questionnireContainer: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  section: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  sectionHeader: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  textItemWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  openItemWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  openItemTextarea: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  inputMultiline: {
    border: `1px solid ${theme.palette.grey[700]}`,
    padding: theme.spacing(1),
  },
  rateItemWrapper: {
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  questionNum: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    marginRight: theme.spacing(1),
    minWidth: 32,
  },
  openItemText: {
    display: 'inline-flex',
  },
  rateItemText: {
    flex: 1,
    display: 'inline-flex',
  },
  rateItemGroup: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 340,
    },
  },
}));

const QItem = ({
  item,
  questionNum,
  readOnly,
}) => {
  const classes = useStyles();

  if (item.type === 'T') {
    return (
      <div className={classes.textItemWrapper}>
        <RichText content={item.content} />
      </div>
    );
  }

  if (item.question.type === 'OPEN') {
    return (
      <div className={classes.openItemWrapper}>
        <div className={classes.openItemText}>
          <p className={classes.questionNum}><strong>{questionNum}{item.required ? '*' : '\u00a0'}</strong></p>
          <RichText content={item.question.content} />
        </div>
        <Field
          className={classes.openItemTextarea}
          name={`item${item.id}`}
          component={TextField}
          InputProps={{
            classes: {
              multiline: classes.inputMultiline,
            },
          }}
          multiline
          fullWidth
          rows={5}
          rowsMax={10}
          disabled={readOnly}
        // validate={item.required ? Required : null}
        />
      </div>);
  }

  if (item.question.type === 'RATE') {
    return (
      <div className={classes.rateItemWrapper}>
        <div className={classes.rateItemText}>
          <p className={classes.questionNum}><strong>{questionNum}{item.required ? '*' : '\u00a0'}</strong></p>
          <RichText
            content={item.question.content}
          />
        </div>
        <Field
          className={classes.rateItemGroup}
          name={`item${item.id}`}
          component={ItemRating}
          fullWidth
          max={item.question.max_rate || 1}
          disabled={readOnly}
        />
      </div>);
  }

  return null;
}

const renderItems = (items, sectionNum, readOnly) => {
  const rendered = [];
  let questionNum = 0;
  items.forEach((item) => {
    if (item.type === 'Q') {
      questionNum += 1;
    }
    rendered.push(<div key={`${item.id}`}><QItem item={item} questionNum={`${sectionNum}.${questionNum}`} readOnly={readOnly} /></div>);
  });
  return rendered;
}

const Questionnire = ({
  model = { sections: [] },
  readOnly = false,
}) => {
  const classes = useStyles();
  const { sections = [] } = model;
  return (
    <div className={classes.questionnireContainer}>
      {model.intro ? <RichText content={model.intro} /> : null}
      {
        sections.map((section, index) => (
          <Paper key={`${section.id}`} className={classes.section}>
            <div className={classes.sectionHeader}>
              <Typography variant="h5" color="inherit">{index + 1}. {section.title}</Typography>
            </div>
            {renderItems(section.items, index + 1, readOnly)}
          </Paper>
        ))
      }
    </div>
  );
}

export default Questionnire;
