import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { FormCard, FormCardSection, FormCardSectionFieldRow } from 'common/components/FormCard';
import Toolbar from 'common/components/ToolBar';

class ActivityListFormCard extends Component {
  static propTypes = {
    model: PropTypes.shape({}).isRequired,
  }

  renderStatus = (row) => {
    switch (Number(row.active)) {
      case 1:
        return <strong>Active</strong>;
      default:
        return <span>Inactive</span>;
    }
  }


  render() {
    const { model } = this.props;
    return (
      <div>
        <FormCard>
          <Toolbar
            title="Details"
          />
          <CardContent>
            <FormCardSection>
              <FormCardSectionFieldRow title="ID">
                <Typography variant="body1">{model.id}</Typography>
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Name">
                <Typography variant="body1">{model.name}</Typography>
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Academic Year">
                <Typography variant="body1">{model.year}</Typography>
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Course">
                <Typography variant="body1">{model.course ? model.course.label : model.course_code}</Typography>
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Department">
                {
                  model.is_master ?
                    <Typography variant="body1">Master Template</Typography> :
                    <Typography variant="body1">{model.department ? model.department.label : model.deparment_code}</Typography>
                }
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Teachers">
                {model.teachers && model.teachers.length > 0 ?
                  model.teachers.map(teacher => (
                    <Typography variant="body1" key={teacher.id}>
                      {teacher.name} ({teacher.email})
                    </Typography>
                  ))
                  : <Typography variant="body1" gutterBottom >No Associated Teachers</Typography>
                }
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Protocol">
                <Typography variant="body1">{model.protocol ? model.protocol.name : model.protocol_code}</Typography>
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Remarks">
                <Typography variant="body1">{model.remarks}</Typography>
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Status">
                <Typography variant="body1">{this.renderStatus(model)}</Typography>
              </FormCardSectionFieldRow>
            </FormCardSection>
            <FormCardSection>
              <FormCardSectionFieldRow title="Created At">
                <Typography variant="body1">{moment(model.created_at).format()} by {model.created_by}</Typography>
              </FormCardSectionFieldRow>
              <FormCardSectionFieldRow title="Last Updated At">
                <Typography variant="body1">{moment(model.updated_at).format()} by {model.updated_by}</Typography>
              </FormCardSectionFieldRow>
            </FormCardSection>
          </CardContent>
        </FormCard>
      </div>
    );
  }
}

export default ActivityListFormCard;
