import GA from 'public/helpers/MyGA';
import * as actions from './types';
import service from './service';


const creators = {

  fetchOne: token =>
    async (dispatch) => {
      const response = await service.fetchOne(token);
      dispatch({ type: actions.FETCH_ONE, model: response.data });
      GA.event({ category: 'Student Survey', action: 'Load Survey', label: `Load Survey ${response.data.name}` });
      return response.data;
    },

  clearOne: () =>
    async dispatch => dispatch({ type: actions.CLEAR_ONE }),
};

export default creators;
