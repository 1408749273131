import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import TextField from 'common/components/form/TextField';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';

import RemoteDropdown from 'public/components/form/RemoteDropdown';
import UserDepartmentDropdown from 'public/components/form/UserDepartmentDropdown';
import AcademicInput from 'common/components/form/AcademicInput';
import { handle422 } from 'common/helpers/ReduxFormHelper';
import { isAcademicYear } from 'common/helpers/validation';

const styles = theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 400,
    },
  },
  field: {
    minWidth: 250,
    margin: theme.spacing(1),
  },
});

const parseCode = v => (v ? v.value : null);
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Required';
  }
  errors.year = isAcademicYear(values?.year);
  if (!values.department_code) {
    errors.department_code = 'Required';
  }
  return errors;
};

class ActivityCreateForm extends Component { // eslint-disable-line
  static propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
  }
  static defaultProps = {
    onClose: () => { },
    onSubmit: () => { },
    error: null,
  }

  onSubmit = async (values) => {
    try {
      const resp = await this.props.onSubmit(values);
      return resp;
    } catch (e) {
      handle422(e.response);
      throw e;
    }
  }

  renderError = (error) => {
    if (error) {
      return (<div><Typography color="error" variant="h6">{error}</Typography><Divider /></div>);
    }
    return null;
  }

  render() {
    const { classes, handleSubmit, error } = this.props;
    return (
      <div className={classes.root}>
        <DialogTitle>Create New Activity</DialogTitle>
        <DialogContent>
          {this.renderError(error)}
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              className={classes.field}
              name="name"
              component={TextField}
              label="Name"
              fullWidth
            />
            <Field
              className={classes.field}
              name="year"
              component={TextField}
              multiline
              label="Academic Year"
              fullWidth
              InputProps={{
                inputComponent: AcademicInput,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.field}>
              <InputLabel>Course</InputLabel>
              <Field
                name="course_id"
                component={RemoteDropdown}
                apiUrl="/staff/values/courses"
                labelProperty="label"
                valueProperty="id"
                parse={parseCode}
                fullWidth
              />
            </div>
            <div className={classes.field}>
              <InputLabel>Department</InputLabel>
              <Field
                name="department_code"
                placeholder="Select..."
                component={UserDepartmentDropdown}
                fullWidth
              />
            </div>
            <div className={classes.field}>
              <InputLabel>Protocol</InputLabel>
              <Field
                name="protocol_code"
                component={RemoteDropdown}
                apiUrl="/staff/values/protocols"
                parse={parseCode}
                fullWidth
              />
            </div>
            <Field
              className={classes.field}
              name="remarks"
              component={TextField}
              multiline
              label="Remarks"
              fullWidth
            />
            <Field
              className={classes.field}
              name="active"
              component={TextField}
              label="Status"
              select
              SelectProps={{ native: true }}
            >
              <option value="1">ACTIVE</option>
              <option value="0">INACTIVE</option>
            </Field>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary">Cancel</Button>
          <Button variant="contained" onClick={handleSubmit(this.onSubmit)} color="primary">CREATE</Button>
        </DialogActions>
      </div>
    );
  }
}

export default reduxForm({
  form: 'ActivityCreateForm',
  initialValues: {
    name: '',
    remarks: '',
    is_master: false,
    department_code: null,
    course_code: '',
    active: 1,
  },
  validate,
})(withStyles(styles)(ActivityCreateForm));
