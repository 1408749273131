import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const PageLoading = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <CircularProgress size={72} /><Typography variant="subtitle1">Loading</Typography>
    </Paper>
  );
};

export default PageLoading;
