import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import TextField from 'common/components/form/TextField';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';

import { handle422 } from 'common/helpers/ReduxFormHelper';
import UserDepartmentDropdown from 'public/components/form/UserDepartmentDropdown';

const styles = theme => ({
  root: {
    // padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      minWidth: 400,
    },
  },
  field: {
    minWidth: 250,
    margin: theme.spacing(1),
  },
});

const validate = (values) => {
  const errors = {};
  if (!values.department_code) {
    errors.department_code = 'Required';
  }
  return errors;
};

class CopyQuestionnaireForm extends Component { // eslint-disable-line
  static propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    error: PropTypes.string,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
  }
  static defaultProps = {
    onClose: () => { },
    onSubmit: () => { },
    error: null,
  }

  onSubmit = async (values) => {
    try {
      const resp = await this.props.onSubmit(values);
      return resp;
    } catch (e) {
      handle422(e.response);
      throw e;
    }
  }

  renderError = (error) => {
    if (error) {
      return (<div><Typography color="error" variant="h6">{error}</Typography><Divider /></div>);
    }
    return null;
  }

  render() {
    const { classes, handleSubmit, error, submitting } = this.props;
    return (
      <div className={classes.root}>
        <DialogTitle>Copy Questionnaire</DialogTitle>
        <DialogContent>
          {this.renderError(error)}
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              className={classes.field}
              name="name"
              component={TextField}
              label="From Questionnaire"
              fullWidth
              disabled
            />
            <div className={classes.field}>
              <InputLabel>Department</InputLabel>
              <Field
                name="department_code"
                component={UserDepartmentDropdown}
                fullWidth
                placeholder="Select..."
              />
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary">Cancel</Button>
          <Button variant="contained" onClick={handleSubmit(this.onSubmit)} disabled={submitting} color="primary">CREATE</Button>
        </DialogActions>
      </div>
    );
  }
}

export default reduxForm({
  form: 'CopyQuestionnaireForm',
  validate,
})(withStyles(styles)(CopyQuestionnaireForm));
