import React from 'react';
import PropTypes from 'prop-types';
import { DateTimePicker } from '@material-ui/pickers';
import FormHelperText from '@material-ui/core/FormHelperText';
import DateRange from '@material-ui/icons/DateRange';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
// import Event from '@material-ui/icons/Event';
import AccessTime from '@material-ui/icons/AccessTime';

const DEFAULT_PROPS = {
  format: 'YYYY-MM-DD HH:mm',
  leftArrowIcon: <KeyboardArrowLeft />,
  rightArrowIcon: <KeyboardArrowRight />,
  // keyboardIcon: <Event />,
  dateRangeIcon: <DateRange />,
  timeIcon: <AccessTime />,
  clearable: true,
  autoOk: false,
  ampm: false,
};

const MyDatePicker = (props) => {
  const {
    input: { name, value, onChange },
    meta: { touched, error },
    ...rest
  } = props;
  return (
    <div>
      <DateTimePicker
        {...DEFAULT_PROPS}
        {...rest}
        name={name}
        value={value || null}
        onChange={onChange}
      />
      {(touched && error) ? <FormHelperText error>{error}</FormHelperText> : null}
    </div>
  );
};
MyDatePicker.propTypes = {
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({ error: PropTypes.string }).isRequired,
};

export default MyDatePicker;
