import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  makeStyles,
  Checkbox,
  IconButton,
  Paper,
  Tooltip,
  Collapse,
  TableCell,
  TableRow,
  TableContainer,
} from '@material-ui/core';

import FilterList from '@material-ui/icons/FilterList';
import Edit from '@material-ui/icons/Edit';

import { EnhancedTable } from 'common/components/tables';
import ToolBar from 'common/components/ToolBar';
import Loading from 'common/components/Loading';

import ListActions from 'public/redux/Coordinator/Activities/actions';
import ActivitySearchForm from './ActivitySearchForm';

const columnData = [
  { id: 'activity.id', numeric: false, disablePadding: false, label: 'ID', sortable: true },
  { id: 'activity.name', numeric: false, disablePadding: false, label: 'Name', sortable: true },
  { id: 'activity.year', numeric: false, disablePadding: false, label: 'Academic Year', sortable: true },
  { id: 'course.code', numeric: false, disablePadding: false, label: 'Course', sortable: true },
  { id: 'activity.department_code', numeric: false, disablePadding: false, label: 'Department', sortable: true },
  { id: 'teachers', numeric: false, disablePadding: false, label: 'Teachers', sortable: false },
  { id: 'activity.active', numeric: false, disablePadding: false, label: 'Status', sortable: true },
  { id: 'actions', numeric: false, disablePadding: false, label: '', sortable: false },
];

const useStyles = makeStyles(() => ({
  paper: { position: 'relative' },
}));

const renderTeacher = teachers => (teachers ? teachers.map(t => <span key={t.id}>{t.name}<br /></span>) : null);

const ActivityRow = ({
  rowData,
  selectedRows,
  setSelectedRows,
}) => {
  const isSelected = id => (selectedRows.findIndex(ele => ele.id === id) >= 0);
  const rowSelected = isSelected(rowData.id);
  const handleRowClick = () => {
    if (rowSelected) {
      setSelectedRows(selectedRows.filter(ele => ele.id !== rowData.id));
    } else {
      setSelectedRows(selectedRows.concat([rowData]));
    }
  };
  return (
    <TableRow
      hover
      tabIndex={-1}
      selected={rowSelected}
      aria-checked={rowSelected}>
      <TableCell padding="checkbox">
        <Checkbox color="primary" checked={rowSelected} onClick={handleRowClick} />
      </TableCell>
      <TableCell>{rowData.id}</TableCell>
      <TableCell>{rowData.name}</TableCell>
      <TableCell>{rowData.year}</TableCell>
      <TableCell>{rowData?.course?.code}</TableCell>
      <TableCell>{rowData.is_master ? <strong>Master Template</strong> : rowData.department_code}</TableCell>
      <TableCell>{renderTeacher(rowData.teachers)}</TableCell>
      <TableCell>{rowData.active ? 'Active' : 'Inactive'}</TableCell>
      <TableCell>
        <Tooltip title="View Details">
          <IconButton component={Link} to={`/coordinator/activity/${rowData.id}`} aria-label="View Details"><Edit /></IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>);
}
const renderRow = ({ rowData, ...props }) => <ActivityRow key={`${rowData.id}`}  {...props} rowData={rowData} />;

const mapStateToProps = ({ CoordinatorActivities }) => CoordinatorActivities;
const ActivityList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { page, pageSize, data, totalElements, sort, form, init, submitting } = useSelector(mapStateToProps);
  const [formOpen, setFormOpen] = React.useState(true);

  const handleToggleFormOpen = React.useCallback(() => setFormOpen(!formOpen), [formOpen]);
  const handleSearch = React.useCallback((values) => dispatch(ListActions.searchFormSubmit(values)).catch(console.log), [dispatch]);
  const handleSortChange = React.useCallback(sort => dispatch(ListActions.sortChange(sort)).catch(console.log), [dispatch]);
  const handlePageChange = React.useCallback(page => dispatch(ListActions.pageChange(page)).catch(console.log), [dispatch]);

  React.useEffect(() => {
    if (!init && !submitting) {
      dispatch(ListActions.searchFormSubmit({})).catch(console.log);
    }
  }, [dispatch, init, submitting]);
  return (
    <div>
      <ToolBar
        color="default"
        title="Activities"
        rightButton={[
          { title: 'Filter', icon: (<FilterList />), onClick: handleToggleFormOpen },
        ]}
      />
      <Collapse in={formOpen}>
        <ActivitySearchForm onSubmit={handleSearch} initialValues={form} />
      </Collapse>
      <Paper className={classes.paper}>
        <TableContainer>
          <EnhancedTable
            totalElements={totalElements}
            pageSize={pageSize}
            page={page}
            sort={sort}
            columnData={columnData}
            data={data}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange}
            renderRow={renderRow}
          />
        </TableContainer>
        <Loading loading={submitting} />
      </Paper>
    </div>
  );
}

export default ActivityList;
