import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Dialog,
  Checkbox,
  IconButton,
  Paper,
  Tooltip,
  Collapse,
  TableContainer,
  TableCell,
  TableRow,
  makeStyles,
} from '@material-ui/core';


import FilterList from '@material-ui/icons/FilterList';
import Edit from '@material-ui/icons/Edit';
import AddBox from '@material-ui/icons/AddBox';
import Delete from '@material-ui/icons/Delete';
import Search from '@material-ui/icons/Search';
import ContentCopy from '@material-ui/icons/FileCopy';

import ConfirmButton from 'common/components/ConfirmButton';
import { EnhancedTable } from 'common/components/tables';
import ToolBar from 'common/components/ToolBar';
import Loading from 'common/components/Loading';

import ListActions from 'public/redux/Questionnaires/actions';
import CommonActions from 'public/redux/common/actions';

import QuestionnaireCreateForm from './QuestionnaireCreateForm';
import QuestionnaireSearchForm from './QuestionnaireSearchForm';
import CopyQuestionnaireForm from './CopyQuestionnaireForm';

const columnData = [
  { id: 'id', numeric: false, disablePadding: false, label: 'ID', sortable: true },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: true },
  { id: 'year', numeric: false, disablePadding: false, label: 'Academic Year', sortable: true },
  { id: 'department_code', numeric: false, disablePadding: false, label: 'Department Code', sortable: true },
  { id: 'active', numeric: false, disablePadding: false, label: 'Status', sortable: true },
  { id: 'actions', numeric: false, disablePadding: false, label: '', sortable: false },
];

const useStyles = makeStyles(() => ({
  paper: { position: 'relative' }
}));

const renderStatus = (status) => {
  switch (Number(status)) {
    case 10:
      return 'Active';
    case 5:
      return 'Submitted';
    case 0:
    default:
      return 'Draft';
  }
};

const QuestionnaireRow = ({
  rowData,
  selectedRows,
  setSelectedRows,
  setCopyRow
}) => {
  const canUpdate = Boolean(Number(rowData.status) === 0 && rowData.department_code);
  const dispatch = useDispatch();
  const isSelected = id => (selectedRows.findIndex(ele => ele.id === id) >= 0);
  const rowSelected = isSelected(rowData.id);
  const handleRowClick = () => {
    if (rowSelected) {
      setSelectedRows(selectedRows.filter(ele => ele.id !== rowData.id));
    } else {
      setSelectedRows(selectedRows.concat([rowData]));
    }
  };
  const handleDelete = React.useCallback(async () => {
    try {
      await dispatch(ListActions.delete(rowData));
      dispatch(CommonActions.setSnackbarMessage(`${rowData.id} is deleted..`));
    } catch (e) {
      dispatch(CommonActions.setSnackbarMessage(`Error: ${(e.response?.data?.message || e)}`));
    }
  }, [rowData, dispatch]);
  return (
    <TableRow
      hover
      tabIndex={-1}
      selected={rowSelected}
      aria-checked={rowSelected}>
      <TableCell padding="checkbox">
        <Checkbox color="primary" checked={rowSelected} onClick={handleRowClick} />
      </TableCell>
      <TableCell>{rowData.id}</TableCell>
      <TableCell>{rowData.name}</TableCell>
      <TableCell>{rowData.year}</TableCell>
      <TableCell>{rowData.department_code == null ? <strong>Master Template</strong> : rowData.department_code}</TableCell>
      <TableCell>{renderStatus(rowData.status)}</TableCell>
      <TableCell>
        <Tooltip title="View Details">
          <IconButton component={Link} to={`/staff/questionnaire/${rowData.id}`} aria-label="View Details"><Edit /></IconButton>
        </Tooltip>
        <Tooltip title="Preview">
          <IconButton component={Link} to={`/staff/questionnaire/preview/${rowData.id}`} aria-label="Preview" target="_blank"><Search /></IconButton>
        </Tooltip>
        <Tooltip title="Copy Questionnaire">
          <IconButton onClick={() => setCopyRow(rowData)}><ContentCopy /></IconButton>
        </Tooltip>
        <Tooltip title="Delete Questionnaire">
          <ConfirmButton
            disabled={!canUpdate}
            ButtonComponent={IconButton}
            onConfirm={handleDelete}
            message={`Confirm to delete questionnaire "${rowData.id}"?`}
          >
            <Delete />
          </ConfirmButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};
const renderRow = ({ rowData, ...props }) => <QuestionnaireRow key={`${rowData.id}`}  {...props} rowData={rowData} />;

const mapStateToProps = ({ Questionnaires }) => Questionnaires;
const QuestionnaireList = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { page, pageSize, data, totalElements, sort, form, init, submitting } = useSelector(mapStateToProps);
  const [formOpen, setFormOpen] = React.useState(true);
  const [showCreate, setShowCreate] = React.useState(false);
  const [copyRow, setCopyRow] = React.useState(null);

  const handleToggleFormOpen = React.useCallback(() => setFormOpen(!formOpen), [formOpen]);
  const handleShowCreate = React.useCallback(() => setShowCreate(true), []);
  const handleHideCreate = React.useCallback(() => setShowCreate(false), []);
  const handleSearch = React.useCallback((values) => dispatch(ListActions.searchFormSubmit(values)).catch(console.log), [dispatch]);
  const handleSortChange = React.useCallback(sort => dispatch(ListActions.sortChange(sort)).catch(console.log), [dispatch]);
  const handlePageChange = React.useCallback(page => dispatch(ListActions.pageChange(page)).catch(console.log), [dispatch]);
  const handleCopy = React.useCallback(values => dispatch(ListActions.copy(values)), [dispatch]);

  React.useEffect(() => {
    if (!init && !submitting) {
      dispatch(ListActions.searchFormSubmit({})).catch(console.log);
    }
  }, [dispatch, init, submitting]);
  return (
    <div>
      <ToolBar
        color="default"
        title="Questionnaires"
        rightButton={[
          { title: 'Filter list', icon: (<FilterList />), onClick: handleToggleFormOpen },
          { title: 'Add Questionnaire', icon: (<AddBox />), onClick: handleShowCreate },
        ]}
      />
      {
        copyRow ?
          <Dialog
            open
            onClose={() => setCopyRow(null)}
            disableBackdropClick
            disableEscapeKeyDown
          >
            <CopyQuestionnaireForm
              onSubmit={handleCopy}
              onSubmitSuccess={r => history.push(`/staff/questionnaire/${r.id}`)}
              initialValues={{ from: copyRow.id, name: copyRow.name, department_code: copyRow.department_code }}
              onClose={() => setCopyRow(null)}
            />
          </Dialog>
          : null
      }
      <Dialog maxWidth="md" open={showCreate} onClose={handleHideCreate} disableBackdropClick disableEscapeKeyDown>
        <QuestionnaireCreateForm
          onClose={handleHideCreate}
          onSubmit={async (values) => {
            return await dispatch(ListActions.create(values));
          }}
          onSubmitSuccess={v => history.push(`/staff/questionnaire/${v.id}`)}
        />
      </Dialog>
      <Collapse in={formOpen}>
        <QuestionnaireSearchForm
          initialValues={form}
          onSubmit={handleSearch}
        />
      </Collapse>
      <Paper className={classes.paper}>
        <TableContainer>
          <EnhancedTable
            totalElements={totalElements}
            pageSize={pageSize}
            page={page}
            sort={sort}
            columnData={columnData}
            data={data}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange}
            renderRow={renderRow}
            renderRowProps={{ setCopyRow }}
          />
        </TableContainer>
        <Loading loading={submitting} />
      </Paper>
    </div>
  );
}

export default QuestionnaireList;
