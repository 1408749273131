import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Spacer from 'common/components/Spacer';
import BackButton from './BackButton';

const useStyles = makeStyles(theme => ({
  colorPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  colorDefault: {
    // backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}));

const HeadToolbar = ({
  title = '',
  rightButton = [],
  showBackButton = false,
  renderRightButton = null,
  renderExtraButton = null,
  color = 'primary',
}) => {
  const classes = useStyles();
  return (
    <Toolbar classes={{ root: color === 'primary' ? classes.colorPrimary : classes.colorDefault }}>
      {showBackButton ? <BackButton /> : null}
      {title ? (<Typography variant="h6" color="inherit">{title}</Typography>) : null}
      <Spacer />
      {
        renderRightButton ? renderRightButton(rightButton) :
          rightButton.map(button => (
            <Tooltip key={button.title} title={button.title} >
              <span>
                <IconButton
                  {...button.ButtonProps}
                  onClick={button.onClick}
                  aria-label={button.title}
                  color="inherit"
                >
                  {button.icon}
                </IconButton>
              </span>
            </Tooltip>
          ))
      }
      {
        renderExtraButton ? renderExtraButton() : null
      }
    </Toolbar>
  );
};

HeadToolbar.propTypes = {
  title: PropTypes.string,
  showBackButton: PropTypes.bool,
  rightButton: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    icon: PropTypes.element.isRequired,
  })),
  renderRightButton: PropTypes.func,
  renderExtraButton: PropTypes.func,
  color: PropTypes.oneOf(['primary', 'default']),
};

export default HeadToolbar;
