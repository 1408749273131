import { connect } from 'react-redux';
import actions from 'public/redux/Coordinator/Questionnaires/actions';
import Questionnaire from './QuestionnairePreview';

const mapStateToProps = ({ CoordinatorQuestionnairePreview }) => CoordinatorQuestionnairePreview;

const mapDispatchToProps = {
  fetchOne: actions.fetchPreview,
  clearOne: actions.clearPreview,
};

// connect with redux
export default connect(mapStateToProps, mapDispatchToProps)(Questionnaire);
