import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Person from '@material-ui/icons/Person';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';

import AccountActions from 'public/redux/account/actions';
import Config from 'public/constants/Config';

const styles = theme => ({
  avator: {
    color: theme.palette.primary.dark,
    backgroundColor: '#fff',
  },
});

class LetterAvatar extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
  }

  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleLogout() {
    const { logout, setRedirectTo } = this.props;
    this.handleRequestClose();
    logout().then(() => {
      setRedirectTo(null);
      setTimeout(() => { window.location = Config.logoutUrl; }, 300);
    });
    // TODO: redirect to OnePass Logout URL?
  }

  render() {
    return (
      <div >
        <IconButton
          aria-owns={this.state.open ? 'right-top-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <Avatar style={styles.avator}><Person /></Avatar>
        </IconButton>
        <Menu
          id="right-top-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
        >
          <MenuItem onClick={this.handleLogout}>
            <ListItemIcon><PowerSettingsNew /></ListItemIcon> Logout
          </MenuItem>
        </Menu>
      </div>
    );
  }
}
const mapStateToProps = () => ({});
const mapDispatchToProps = AccountActions;
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(LetterAvatar)));
