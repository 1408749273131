import { connect } from 'react-redux';
import actions from 'public/redux/OpenSurvey/actions';
import SurveyView from './SurveyView';

const mapStateToProps = ({ OpenSurveyForm }) => OpenSurveyForm;

const mapDispatchToProps = {
  fetchOne: actions.fetchOne,
  clearOne: actions.clearOne,
};

// connect with redux
export default connect(mapStateToProps, mapDispatchToProps)(SurveyView);
