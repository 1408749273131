import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const Spacer = () => {
  const classes = useStyles();
  return (<div className={classes.root} />);
}

export default Spacer;
