import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import actions from 'public/redux/Activities/actions';
import ActivityListFormCard from './ActivityListFormCard';


const selector = formValueSelector('ActivityForm');
const mapStateToProps = state => ({
  ...state.ActivityForm,
  isMaster: Boolean(selector(state, 'is_master')),
  teachers: selector(state, 'teachers'),
  teachers_id: selector(state, 'teachers_id'),
  initialValues: {
    ...state.ActivityForm.model,
    teachers_id: state.ActivityForm.model.teachers.map(t => t.id),
  },
});
const mapDispatchToProps = {
  update: actions.update,
  fetchOne: actions.fetchOne,
};

// connect with redux
export default connect(mapStateToProps, mapDispatchToProps)(ActivityListFormCard);
