import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TableContainer,
  Checkbox,
  Paper,
  Collapse,
  TableCell,
  TableRow,
  Button,
  makeStyles,
} from '@material-ui/core';
import FilterList from '@material-ui/icons/FilterList';

import { EnhancedTable } from 'common/components/tables';
import ToolBar from 'common/components/ToolBar';
import Loading from 'common/components/Loading';

import ListActions from 'public/redux/Questionnaires/actions';
import SearchForm from './QuestionnaireSearchForm';

const columnData = [
  { id: 'id', numeric: false, disablePadding: false, label: 'ID', sortable: true },
  { id: 'name', numeric: false, disablePadding: false, label: 'name', sortable: true },
  { id: 'year', numeric: false, disablePadding: false, label: 'Academic Year', sortable: true },
  { id: 'department_code', numeric: false, disablePadding: false, label: 'Department Code', sortable: true },
];

const useStyles = makeStyles(theme => ({
  btnBar: {
    padding: theme.spacing(2),
    display: 'flex',
  },
  btnBarText: {
    flex: 1,
  },
  paper: { position: 'relative' },
}));

const QuestionnaireRow = ({
  rowData,
  selectedRows,
  setSelectedRows,
}) => {
  const isSelected = id => (selectedRows.findIndex(ele => ele.id === id) >= 0);
  const rowSelected = isSelected(rowData.id);
  const handleRowClick = () => {
    if (rowSelected) {
      setSelectedRows([]);
    } else {
      setSelectedRows([rowData]);
    }
  };
  return (
    <TableRow
      hover
      tabIndex={-1}
      selected={rowSelected}
      aria-checked={rowSelected}
      onClick={handleRowClick}
    >
      <TableCell padding="checkbox">
        <Checkbox color="primary" checked={rowSelected} onClick={handleRowClick} />
      </TableCell>
      <TableCell>{rowData.id}</TableCell>
      <TableCell>{rowData.name}</TableCell>
      <TableCell>{rowData.year}</TableCell>
      <TableCell>{rowData.department_code}</TableCell>
    </TableRow>
  );
}
const renderRow = ({ rowData, ...props }) => <QuestionnaireRow key={rowData.id} {...props} rowData={rowData} />;

const mapStateToProps = ({ Questionnaires }) => Questionnaires;
const QuestionnaireList = ({
  onSelected
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { page, pageSize, data, totalElements, sort, form, init, submitting } = useSelector(mapStateToProps);

  const [selected, setSelected] = React.useState(null);
  const [formOpen, setFormOpen] = React.useState(true);

  const handleToggleFormOpen = React.useCallback(() => setFormOpen(!formOpen), [formOpen]);
  const handleSearch = React.useCallback((values) =>
    dispatch(ListActions.searchFormSubmit({ ...values, active: 10 })).catch(console.log) // ensure only show active questionnaires
    , [dispatch]);
  const handleSortChange = React.useCallback(sort => dispatch(ListActions.sortChange(sort)).catch(console.log), [dispatch]);
  const handlePageChange = React.useCallback(page => dispatch(ListActions.pageChange(page)).catch(console.log), [dispatch]);

  React.useEffect(() => !init && !submitting && handleSearch({}), [handleSearch, init, submitting]);

  return (
    <div>
      <ToolBar
        title="Select Questionnaire"
        rightButton={[{ title: 'Filter', icon: (<FilterList />), onClick: handleToggleFormOpen },]}
      />
      <Collapse in={formOpen}>
        <SearchForm
          initialValues={form}
          onSubmit={handleSearch}
        />
      </Collapse>
      <Paper className={classes.paper}>
        <TableContainer>
          <EnhancedTable
            totalElements={totalElements}
            pageSize={pageSize}
            page={page}
            sort={sort}
            columnData={columnData}
            data={data}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange}
            renderRow={renderRow}
            onRowsSelected={(rows) => setSelected(rows?.[0] || null)}
            disableSelectAll
          />
        </TableContainer>
        <Loading loading={submitting} />
        <div className={classes.btnBar}>
          <div className={classes.btnBarText} />
          <Button variant="contained" color="primary" size="large" onClick={() => onSelected(selected)} disabled={selected === null}>Next</Button>
        </div>
      </Paper>
    </div>
  );
}

export default QuestionnaireList;
