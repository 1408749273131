import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import leftDrawer from './leftDrawer/reducer';
import account from './account/reducer';
import common from './common/reducer';
import HomeSurvey from './HomeSurvey/reducer';
import StudentSurvey from './StudentSurvey/reducer';
import StudentSurveyForm from './StudentSurvey/formReducer';
import Questions from './Questions/reducer';
import QuestionForm from './Questions/formReducer';
import Teachers from './Teachers/reducer';
import Questionnaires from './Questionnaires/reducer';
import QuestionnaireForm from './Questionnaires/formReducer';
import QuestionnairePreview from './Questionnaires/previewReducer';
import QSections from './Questionnaires/Sections/reducer';
import Activities from './Activities/reducer';
import ActivityForm from './Activities/formReducer';
import ActivityStudents from './Activities/studentReducer';
import OnlineSurveys from './OnlineSurveys/reducer';
import OnlineSurveyForm from './OnlineSurveys/formReducer';
import OnlineSurveyStudents from './OnlineSurveys/students/reducer';
import OnlineSurveyTokens from './OnlineSurveys/tokens/reducer';
import OpenSurveyForm from './OpenSurvey/reducer';
import CoordinatorOnlineSurveys from './Coordinator/OnlineSurveys/reducer';
import CoordinatorOnlineSurveyForm from './Coordinator/OnlineSurveys/formReducer';
import CoordinatorQuestionnairePreview from './Coordinator/Questionnaires/previewReducer';
import CoordinatorActivities from './Coordinator/Activities/reducer';
import CoordinatorActivityForm from './Coordinator/Activities/formReducer';

export default combineReducers({
  form,
  account,
  common,
  leftDrawer,
  HomeSurvey,
  StudentSurvey,
  StudentSurveyForm,
  Questions,
  QuestionForm,
  Teachers,
  Questionnaires,
  QuestionnaireForm,
  QuestionnairePreview,
  QSections,
  Activities,
  ActivityForm,
  ActivityStudents,
  OnlineSurveys,
  OnlineSurveyForm,
  OnlineSurveyStudents,
  OnlineSurveyTokens,
  OpenSurveyForm,
  CoordinatorOnlineSurveys,
  CoordinatorOnlineSurveyForm,
  CoordinatorQuestionnairePreview,
  CoordinatorActivities,
  CoordinatorActivityForm,
});
