import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

class LocalRadioGroup extends Component { // eslint-disable-line
  static propTypes = {
    input: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    label: PropTypes.string,
    meta: PropTypes.shape({}).isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    row: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    label: '',
    row: false,
    disabled: false,
  };

  render() {
    const { input: { name, value, onChange }, label, meta: { touched, error }, options, row, disabled, ...props } = this.props;
    // console.log(value);
    return (
      <FormControl component="fieldset" error={Boolean(touched && error)} {...props}>
        {label && <FormLabel>{label}</FormLabel>}
        <RadioGroup
          name={name}
          onChange={onChange}
          value={value}
          row={row}
        >
          {options.map(opt =>
            (
              <FormControlLabel
                key={opt.value}
                label={opt.label}
                value={opt.value}
                control={
                  <Radio
                    color="primary"
                    disabled={disabled}
                  />
                }
              />
            ))}
        </RadioGroup>
        {(touched && error) ? <FormHelperText error>{error}</FormHelperText> : null}
      </FormControl >
    );
  }
}
export default LocalRadioGroup;
