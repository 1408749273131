import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import RichTextEditor from 'react-rte';
import { withStyles } from '@material-ui/core/styles';

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
  ],
};
const styles = theme => ({
  rte: {
    fontFamily: theme.typography.fontFamily,
  },
  editor: {
    '& .public-DraftEditor-content': {
      minHeight: 100,
    },
  },
  hasError: {
    '& .public-DraftEditor-content': {
      border: '1px solid red',
    },
  },
});
const EMPTY_VALUE = RichTextEditor.createEmptyValue().toString('html');

class RichTextMarkdown extends Component {
  static propTypes = {
    input: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
    }).isRequired,
    meta: PropTypes.shape({
      error: PropTypes.string,
    }),
    classes: PropTypes.shape({}).isRequired,
  }

  static defaultProps = {
    meta: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.input.value === '' ?
        RichTextEditor.createEmptyValue() :
        RichTextEditor.createValueFromString(this.props.input.value, 'html'),
    };
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.input.value !== this.state.value.toString('html')) {
      this.setState({
        value: nextProps.input.value ?
          RichTextEditor.createValueFromString(nextProps.input.value, 'html') :
          RichTextEditor.createEmptyValue(),
      });
    }
  }

  onChange(value) {
    const oldValue = this.state.value.toString('html');
    let newValue = value.toString('html');
    if (newValue === EMPTY_VALUE) {
      newValue = '';
    }
    const isTextChanged = oldValue !== newValue;
    this.setState(
      { value },
      () => isTextChanged && this.props.input.onChange(newValue),
    );
  }

  render() {
    const { classes, input, meta, ...props } = this.props;
    return (
      <RichTextEditor
        value={this.state.value}
        className={classes.rte}
        editorClassName={cx(
          classes.editor,
          (meta && meta.error) ? classes.hasError : null,
        )}
        onChange={this.onChange}
        toolbarConfig={toolbarConfig}
        {...props}
      />
    );
  }
}

export default withStyles(styles)(RichTextMarkdown);
