import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  field: {
    marginRight: theme.spacing(2),
  },
  formContainer: {
    display: 'flex',
  },
  buttonContainer: {
    flex: 0.5,
    minWidth: 180,
    alignSelf: 'flex-end',
  },
}));

const DUMMY = () => { };
const SearchFormBase = ({
  children,
  onSubmit = DUMMY,
  onClear = DUMMY,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Paper className={classes.root}>
        <form onSubmit={onSubmit}>
          <div className={classes.formContainer}>
            {children}
            <div className={classes.buttonContainer}>
              <Button variant="contained" type="submit" color="primary">Search</Button>
              <Button variant="contained" onClick={onClear}>Clear</Button>
            </div>
          </div>
        </form>
      </Paper>
      <Divider />
    </div >
  );
}

export default SearchFormBase;
