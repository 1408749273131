import React from 'react';
import MuiTextField from '@material-ui/core/TextField';

const TextField = React.forwardRef(({
  label,
  input,
  placeholder,
  meta: { touched, invalid, error },
  ...props
}, ref) => (
  <MuiTextField
    innerRef={ref}
    label={label}
    placeholder={placeholder}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...props}
  />
));

export default TextField;