import * as Actions from './types';

const initState = {
  init: false,
  model: {
    id: null,
    department_code: '',
    name: '',
    created_at: null,
    updated_at: null,
  },
};

const QuestionnairePreview = (state = initState, action) => {
  switch (action.type) {
    case Actions.FETCH_PREVIEW:
      return {
        ...state,
        init: true,
        model: action.model,
      };
    case Actions.CLEAR_PREVIEW:
      return {
        ...initState,
      };
    default:
      return state;
  }
};
export default QuestionnairePreview;
