import merge from 'lodash/merge';
import * as actions from './types';
import service from './service';

const fetchList = (search, dispatch) =>
  service
    .fetchList(search)
    .then(response => dispatch({ type: actions.FETCH_LIST, data: response.data }));

const getNewSearch = (search, getState) => {
  const { form, sort, page } = getState().QSections;
  const { model } = getState().QuestionnaireForm;
  return merge({}, { form, sort, page }, search, { form: { questionnaire_id: model.id } });
};


const creators = {

  searchFormSubmit: form =>
    async (dispatch, getState) => {
      // console.log('searchFormSubmit', form, dispatch);
      dispatch({ type: actions.SEARCH_FORM_SUBMIT, form });
      return fetchList(getNewSearch({ form, page: 0 }, getState), dispatch);
    },

  batchUpdate: sections =>
    async (dispatch, getState) => {
      const response = await service.batchUpdate(sections);
      fetchList(getNewSearch({}, getState), dispatch);
      return response.data;
    },
};

export default creators;
