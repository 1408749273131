import React from 'react';
import MaskedInput from 'react-text-mask';

import { CURRENT_ACADEMIC_YEAR } from 'common/constants/years';

const AcademicYearMask = [/[1-9]/, /\d/, /\d/, /\d/, '/', /\d/, /\d/];
const AcademicInput = React.forwardRef(({
  placeholder,
  ...props
}, ref) =>
  (<MaskedInput placeholder={placeholder || CURRENT_ACADEMIC_YEAR} {...props} ref={ref} mask={AcademicYearMask} />)
);

export default AcademicInput;
