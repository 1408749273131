import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  formCardSectionContent: {
    margin: theme.spacing(2),
    flex: '1 1 auto',
  },
});

const FormCardContent = (props) => {
  const { classes, children } = props;
  return (
    <div className={classes.formCard}>
      {children}
    </div>
  );
};
FormCardContent.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.node,
};
FormCardContent.defaultProps = {
  children: null,
};

export default withStyles(styles)(FormCardContent);
