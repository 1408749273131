import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';

import SurveyCard from 'public/components/SurveyCard';
import Link from 'common/components/Link';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    maxWidth: 1280,
  },
  surveyRow: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  surveyName: {
    flex: 1,
  },
  surveyDate: {
    display: 'flex',
    alignItems: 'center',
  },
  surveyDone: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(0.5),
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  cardTitle: {
    color: 'inherit',
  },
});

class HomeView extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    searchFormSubmit: PropTypes.func.isRequired,
    HomeSurvey: PropTypes.shape({}).isRequired,
  }

  componentDidMount() {
    const { searchFormSubmit } = this.props;
    searchFormSubmit({})
      .catch(console.log);
  }

  renderSurvey() {
    const { HomeSurvey } = this.props;
    if (!HomeSurvey.init) {
      return (<CircularProgress size={72} />);
    }
    if (HomeSurvey.data && HomeSurvey.data.length) {
      return HomeSurvey.data.map(survey => <SurveyCard key={survey.id} survey={survey} />);
    }
    return (<Typography variant="body2">No Survey / Evaluation are available now.</Typography>);
  }

  render() {
    const { classes, HomeSurvey } = this.props;
    // console.log(StudentSurvey.totalElements, StudentSurvey.numberOfElements);
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Typography variant="h4" component="h1" gutterBottom>Welcome to the Course and Teaching Evaluation System</Typography>
          <Card>
            <CardHeader classes={{ root: classes.cardHeader, title: classes.cardTitle }} title="Available Survey / Evaluation" />
            <CardContent>
              {this.renderSurvey()}
            </CardContent>
            {
              HomeSurvey.totalElements > HomeSurvey.numberOfElements ?
                <CardActions>
                  <Button component={Link} to="survey">More ...</Button>
                </CardActions>
                : null
            }
          </Card>
        </div >
      </div >
    );
  }
}

export default withStyles(styles)(HomeView);
