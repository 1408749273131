import buildQuery from 'common/helpers/buildQuery';
import axios from 'public/helpers/MyAxios';
import Config from 'public/constants/Config';

const service = {
  fetchList: (search) => {
    const qs = buildQuery(search);
    return axios.get(`${Config.baseUrl}/staff/students?${qs}`);
  },
  create: data => axios.post(`${Config.baseUrl}/staff/students`, data),
  deleteOne: id => axios.delete(`${Config.baseUrl}/staff/students/${id}`),
  deleteAll: id => axios.delete(`${Config.baseUrl}/staff/student/batch-delete?id=${id}`),
};

export default service;
