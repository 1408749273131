import React from 'react';
import { reduxForm, Field } from 'redux-form';
import fileDownload from 'js-file-download';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  withStyles,
} from '@material-ui/core';
import LoadingFileDialog from 'common/components/download/LoadingFileDialog';

import buildQuery from 'common/helpers/buildQuery';
import BooleanCheckbox from 'common/components/form/BooleanCheckbox';
import axios from 'public/helpers/MyAxios';
import GetApp from '@material-ui/icons/GetApp';

const styles = () => ({
  button: {
    textTransform: 'none',
  }
});

const useDownloader = () => {
  const [progress, setProgress] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const onFileDownload = React.useCallback(async (path, filename) => {
    try {
      setLoading(true);
      const response = await axios.get(axios.addApiUrl(path), {
        responseType: 'blob',
        onDownloadProgress: e => setProgress({ loaded: e.loaded, total: e.total }),
      });
      fileDownload(response.data, filename);
      setLoading(false);
      setProgress(null);
      this.setState({ loading: false, progess: null });
    } catch (e) {
      setLoading(false);
      setProgress(null);
    }
  }, []);

  return {
    progress,
    loading,
    onFileDownload,
  };
}

const BatchDownloadForm = reduxForm({
  form: 'BatchDownloadReportForm',
  initialValues: {
    raw: false,
    comments: false,
    overall: false,
    teachers: false,
  },
  validate: (values) => {
    const { raw, comments, overall, teachers } = values;
    const errors = {};
    if (!raw && !comments && !overall && !teachers) {
      errors._error = 'Please select at least 1 report to download';
    }
    return errors;
  },
})(({ handleSubmit, error, invalid }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field name="raw" component={BooleanCheckbox} label="All raw responses" />
      </div>
      <div>
        <Field name="comments" component={BooleanCheckbox} label="Written Comments" />
      </div>
      <div>
        <Field name="overall" component={BooleanCheckbox} label="Overall Reports" />
      </div>
      <div>
        <Field name="teachers" component={BooleanCheckbox} label="Teacher Performance" />
      </div>
      {error && <Typography color="error">{error}</Typography>}
      <div>
        <Button type="submit" variant="contained" startIcon={<GetApp />} color="primary" disabled={invalid}>Download</Button>
      </div>
    </form>
  );
})


const BatchDownloadReportDialog = ({
  rows,
  classes,
  ...props
}) => {
  const { progress, loading, onFileDownload } = useDownloader();
  // const query = React.useMemo(() => buildQuery({ form: { ids: rows?.map(r => r.id) } }), [rows]);

  const handleSubmit = React.useCallback((values) => {
    const query = buildQuery({ form: { ids: rows?.map(r => r.id), ...values } });
    onFileDownload(`/coordinator/batch-report/all?${query}`, 'Reports.zip');
  }, [rows, onFileDownload]);
  return (
    <>
      <Dialog maxWidth="sm" fullWidth {...props}>
        <DialogTitle>Batch Download Reports</DialogTitle>
        <DialogContent>
          <Typography>{rows?.length} CTE selected.</Typography>
          <BatchDownloadForm
            onSubmit={handleSubmit}
          />
        </DialogContent>
      </Dialog>
      <LoadingFileDialog open={loading} progress={progress} />
    </>
  );
}

export default withStyles(styles)(BatchDownloadReportDialog);
