import { connect } from 'react-redux';
import actions from 'public/redux/Questionnaires/actions';
import QuestionnaireView from './QuestionnaireView';

const mapStateToProps = ({ QuestionnaireForm }) => ({
  ...QuestionnaireForm,
});

const mapDispatchToProps = {
  fetchOne: actions.fetchOne,
  clearOne: actions.clearOne,
};

// connect with redux
export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireView);
