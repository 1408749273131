import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';

import TextField from 'common/components/form/TextField';
import SearchFormBase from 'common/components/SearchFormBase';

const useStyles = makeStyles(theme => ({
  field: {
    marginRight: theme.spacing(2),
  },
}));

const EMPTY_FORM = {
  type: '',
  item_no: '',
  status: null,
  content: '',
  id: '',
};

const QuestionSelectionDialogSearchForm = ({
  onSubmit,
  handleSubmit,
}) => {
  const classes = useStyles();
  const handleClear = React.useCallback(() => onSubmit(EMPTY_FORM), [onSubmit]);
  return (
    <SearchFormBase onSubmit={handleSubmit} onClear={handleClear}>
      <Field
        component={TextField}
        className={classes.field}
        label="ID"
        name="id"
      />
      <Field
        component={TextField}
        className={classes.field}
        label="Item No."
        name="item_no"
      />
      <Field
        component={TextField}
        className={classes.field}
        label="Content"
        name="content"
      />
      <Field
        component={TextField}
        className={classes.field}
        label="Type"
        name="type"
        select
        SelectProps={{ native: true }}
      >
        <option value="" />
        <option value="RATE">RATE</option>
        <option value="OPEN">OPEN</option>
      </Field>
      <Field
        component={TextField}
        className={classes.field}
        label="Status"
        select
        SelectProps={{ native: true }}
        name="status"
      >
        <option value="" />
        <option value="10">Active</option>
        <option value="5">Submitted</option>
        <option value="0">Draft</option>
      </Field>
    </SearchFormBase>
  );
}


export default reduxForm({
  form: 'QuestionSelectionDialogSearchForm',
  enableReinitialize: true,
})(QuestionSelectionDialogSearchForm);
