import moment from 'moment';

const academicYearStart = moment()
  .month(6)// month is 0 based. Jan is 0
  .date(1)
  .hour(0)
  .minute(0)
  .second(0)
  .millisecond(0);

const currentDate = moment();
let lowYear;
let highYear;
if (currentDate.isBefore(academicYearStart)) {
  // console.log('what?', currentDate, academicYearStart, currentDate.isBefore(academicYearStart));
  highYear = currentDate.format('YY');
  lowYear = currentDate.clone();
  lowYear.subtract(1, 'years');
  lowYear = lowYear.format('YYYY');
} else {
  lowYear = currentDate.format('YYYY');
  highYear = currentDate.clone();
  highYear.add(1, 'years');
  highYear = highYear.format('YY');
}

export const CURRENT_ACADEMIC_YEAR = `${lowYear}/${highYear}`;
